.wrapper {
  display: flex;
  flex-direction: column;
}

.root {
  display: flex;
  width: 100%;
  background-color: #fafafa;
}

.left {
  flex: 1 1 73%;
  width: 73%;
  display: flex;
  flex-direction: column;
  margin-right: 30px;
}

.right {
  flex: 1 1 27%;
  width: 27%;
  display: flex;
  flex-direction: column;
}

.right_bottom {
  margin-top: 20px;
  overflow-y: auto;
}

.title {
  font-weight: 500;
  font-size: 24px;
  color: #2c2c2c;
  margin-bottom: 30px;
}

.subheading {
  font-weight: 400;
  font-size: 18px;
  color: #7a7a7a;
  padding: 16px 0 16px 0;
  border-top: 1px solid #eaeaea;
  border-bottom: 1px solid #eaeaea;
  cursor: pointer;
}

.heading {
  display: inline-block;
}

.page_end_text {
  font-weight: 400;
  font-size: 18px;
  color: #7a7a7a;
  margin-top: 24px;
  margin-bottom: 30px;
  cursor: pointer;
}

.filter_wrapper {
  display: inline-block;
}
.sort_wrapper {
  display: inline-block;
}
.dropdown_wrapper {
  border-top: 1px solid #eaeaea;
  border-bottom: 1px solid #eaeaea;
  padding: 12px 0;
}
.dropdown {
  position: relative;
  margin-right: 40px;
}

/* For Heading */
.heading__root {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
}

.heading__heading {
  display: inherit;
  font-weight: 500;
  font-size: 24px;
  color: #2c2c2c;
  margin-right: 10px;
}

/* For Related Folders */
.related_folder__root {
  width: 100%;
  height: 400px;
  max-height: 880px;
  margin-top: 40px;
  overflow-y: auto;
  background-color: #fafafa;
  scrollbar-width: 0px;
  overflow-x: hidden;
}
.related_folder__root::-webkit-scrollbar {
  width: 0px;
}

.related_folder__folder {
  display: flex;
  width: 100%;
  height: 72px;
  margin-bottom: 10px;
  align-items: center;
  background-color: white;
  padding: 20px 10px;
  border-radius: 6px;
  cursor: pointer;
}

.related_folder__name {
  max-width: 70%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 400;
  font-size: 18px;
  color: #2c2c2c;
}

.related_folder__icon {
  margin: 0 40px 0 20px;
}

/* For Keywords */
div[class|='keyword'] {
  display: inline-block;
  cursor: pointer;
  margin: 2px;
  color: #9a9a9a;
  /* background: red; */
  /* padding: 8px;
  border-radius: 20px;
  border:2px solid #eaeaea; */
}

div[class*='keyword-0'] {
  color: #1ba94c !important;
  /* background: red; */
  /* border:2px solid #eaeaea;
  padding: 8px;
  border-radius: 20px; */
}

.keyword__root {
  flex-grow: 0.2;
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  font-size: 18px;
}

/* For Sort */
.sort_root {
  position: absolute;
  width: 240px;
  height: 72px;
  background-color: white;
  padding: 15px;
}
.wrapper1 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 15px;
}
.sort_text {
  margin-right: auto;
  color: #9a9a9a;
}
.sort_tag {
  margin-right: 15px;
  color: #11256d;
}
.icon_container {
  cursor: pointer;
}
