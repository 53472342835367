.doc_count_card_container {
    width: 22%;
}

.doc_count_card_body {
    text-align: left;
    padding: 1.5rem 0px;
}
.circle {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    line-height: 2.7;
    text-align: center;
    background: #f5f7fd;
    color: #144192;
    float: left;
    margin-left: 28px;
    margin-right: 24px;
}
.doc_count {
    font-size: 1.375em;
    font-weight: 500;
}
.doc_title {
font-size: 12px;
    color: rgba(55, 59, 83, 1);
}
.doc_count_card_body img{
    vertical-align: initial;
    margin: 0px 24px;
}
