.team_management_icons img {
    margin-left: 8px;
    margin-right: 8px;
    cursor: pointer;
}
.team_card_body {
    padding: 0.8em;
    min-height: 200px;
}
.team_management_content_wrapper {
    margin-top: 30px;
}
.team_management_tab_container {
    position: relative;
}
.team_management_icons_container {
    position: absolute;
    right: 0;
    top: 0;
}
