.similar_para_container {
  padding: 5px 15px;
}
.title {
  font-size: 11px;
  color: #bbbcc4bf;
  font-weight: 500;
}
.two_column_row {
  display: flex;
  justify-content: space-between;
}
.file_name {
  font-size: 12.5px;
  color: #3b4856;
  width: 82%;
}
.file_action {
  font-size: 12px;
  color: #144192;
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;
}
.page_content {
  padding-top: 20px;
  overflow-y: auto;

  /* height: calc(42vh - 65px); */

  height: 48vh;
}
.page_para {
  font-size: 12px;
  color: #3b4856;
  text-align: justify;
}
.page_confidence_score {
  color: #13ce66;
  font-weight: 500;
  font-size: 12px;
  margin-top: 8px;
}
.navigation_container {
  display: flex;
  justify-content: space-between;
  padding: 0px 20px;
}
.page_count {
  font-size: 12px;
  color: #3b4856;
}
.feedback_container i {
  margin-right: 12px;
  cursor: pointer;
}
.feedback_container span {
  font-size: 12px;
  color: #3b4856;
}
.btnPrev i {
  cursor: pointer;
}
.btnNext i {
  cursor: pointer;
}
.showmore {
  margin-left: 8px;
  color: #144192 !important;
  font-weight: bold;
  text-decoration: underline !important;
}
.showmore:hover {
  cursor: pointer;
}







.top_actions_strip{
  background-color:#D3DBF8;
   height:60px;
   margin-bottom: 40px;

   /* position: fixed;
   width: 82.5%;
   z-index: 5000000; */
}
.actions_strip_section{
  display:flex;
   justify-content:space-between;
    line-height:4;
    padding: 0px 12px;
}
.file_title{
  font-size: 12px;
  color:#144192;
  font-weight: bold;
  line-height: 5;
}
.icon_style{
  padding-right:0.5em; 
  padding-left:0.5em; 
  cursor:pointer;
  font-size: larger;
  margin-top: 2px;
  color:#144192;
}


.cut_icon{
  font-size: 24px;
  margin-left: 12px;
  cursor: pointer;
  color:#144192;
}
