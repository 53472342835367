.root {
  /* height: 100vh; */
  padding-left: 160px;
  padding-top: 145px;
  background-color: #fafafa;
  /* line-height: 1.2; */
}

.upper_part {
}
.relative {
  position: relative;
}
.back_icon {
  position: absolute;
  left: -50px;
  top: 0px;
  cursor: pointer;
}
.header_wrapper {
  border-bottom: 1px solid #eaeaea;
}
.header {
  width: fit-content;
  color: #c4c4c4;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.2;
  padding-bottom: 30px;
  cursor: pointer;
}
.heading {
  color: #1ba94c;
}

.recent_folders_heading {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 24px;
  margin: 30px 0;
}
.recent_folders_heading > div {
  margin-right: 15px;
}
.buttons {
  display: flex;
  margin-bottom: 30px;
}
.button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 270px;
  height: 72px;
  color: #fff;
  margin-right: 15px;
  /* padding: 0 20px 0 27px; */
  border-radius: 6px;
  font-weight: 500;
  font-size: 18px;
  cursor: pointer;
}
.button > div {
  margin-left: 30px;
}
.source_and_issues {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  padding: 15px 0;
  font-size: 12px;
  font-weight: 700;
  border-top: 1px solid #eaeaea;
  border-bottom: 1px solid #eaeaea;
}
.source_and_issues > div {
  width: 33%;
}
.chart_section {
  padding-bottom: 30px;
}
.chart_section > img {
  width: 100%;
}
