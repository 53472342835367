.edit_tag_container {
}
.title {
    font-size: 24px;
    color: #11256d;
    font-weight: bold;
}
.text_field_column {
    padding-right: 50px;
}
.suggestion_section {
    padding-left: 50px;
    padding-right: 30px;
}
.tagsStyling {
    border: 1px solid #dfe1e5;
    padding: 4px 4px;
    border-radius: 4px;
    margin: 6px 6px 6px 6px;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 13px;
    display: inline-block;
    font-size: 12px;
    color: #3b4856;
    font-weight: 500;
    cursor: pointer;
}
.edit_tag_container hr {
    margin: 30px 0px;
}
.feedback_text {
    font-size: 16px;
    color: #11256d;
    font-weight: bold;
}
.feedback_subtext {
    font-size: 11px;
    color: #bbbcc4bf;
    margin: 10px 10px 0px 0px;
    font-weight: bold;
    margin-bottom: 8px;
}
.feedback_text_field textarea {
    width: 100%;
    /* height: 100px; */
    border: 1px solid #dfe1e5;
    border-radius: 4px;
    padding: 8px;
}
.buttons_wrapper {
    text-align: right;
    margin-top: 48px;
}
.btn_gap {
    margin-right: 16px;
}
.addAnotherSubtag {
    float: right;
    font-size: 12px;
    font-weight: bold;
    color: #144192;
    margin-top: 8px;
    cursor: pointer;
}
