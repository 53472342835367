.root {
  position: absolute;
  display: flex;
  right: 0px;
  z-index: 2;
}

.share_wrapper {
  display: flex;
  flex-direction: column;
  width: 180px;
  /* height: 96px; */
  background-color: white;
}

.email_container {
  flex: 1 1 50%;
  height: 48px;
  display: flex;
  border-bottom: 1px solid #eaeaea;
  cursor: pointer;
  padding: 10px;
}
.icon_container {
  margin-right: 15px;
}
.text {
  font-weight: 400;
  font-size: 15px;
  color: #9a9a9a;
}
.add_member_wrapper {
  flex: 1 1 50%;
  height: 48px;
  padding: 12px;
  cursor: pointer;
}
.private_folder_wrapper {
  flex: 1 1 50%;
  height: 48px;
  padding: 12px;
  border-radius: 0 0 8px 8px;
  cursor: pointer;
}
.add_member_container {
  display: flex;
  cursor: pointer;
}
.private_folder_container {
  
}
