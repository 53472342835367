.heading_row {
  display: flex;
  justify-content: space-between;
}
.all_documents_card_body {
  padding: 0.8em;
  min-height: 200px;
}
.all_documents_content_wrapper {
  margin-top: 30px;
}
.all_documents_tab_container {
  position: relative;
}

.player {
  margin-top: 50px;
  display: block;
  text-align: center;
}

.heading {
  margin-top: 42px;
  text-align: center;
  font-family: "Roboto", sans-serif;
  letter-spacing: 0px;
  color: #3b4856;
  opacity: 1;
  font-size: 12px;
  font-weight: 600;
}

.info {
  color: #3b4856;
  font-size: 12px;
  text-align: center;
  opacity: 1;
  letter-spacing: 0px;
  margin-bottom: 33px;
}

.question {
  margin-left: 23px;
  margin-top: 23px;
  letter-spacing: 0px;
  color: #3b4856;
  opacity: 1;
  top: 239px;
  left: 136px;
  width: 605px;
  height: 16px;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 600;
}

.answer {
  margin-left: 23px;
  margin-top: 9px;
  margin-bottom: 70px;
  letter-spacing: 0px;
  color: #3b4856;
  opacity: 1;
  top: 239px;
  left: 136px;
  width: 605px;
  height: 16px;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  text-align: left;
}

.address_block {
  margin-left: 45px;
  padding: 0 16px;
}

.address_space {
  margin-right: 100px;
}

ul {
  display: flex;
  align-items: flex-start;
}

ul li {
  width: 30%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.flag_img {
  margin-top: 30px;
  width: 48px;
}

.logo_img {
  margin-top: 12px;
  width: 100%;
  margin-bottom: 4px;
}

.country_name {
  margin-left: 11px;
  margin-top: 46px;
  color: #3b4856;
  font-size: 1.125em;
  font-weight: 500;
  margin-bottom: 8px;
  font-family: "Roboto", sans-serif;
}

.location {
  color: #3b4856;
  line-height: 24px;
  font-size: 18px;
}
.address {
  margin-left: 60px;
  display: block;
  color: #3b4856;
  line-height: 24px;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
}
