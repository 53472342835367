.success_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.success_section {
  width: 390px;
  height: 190px;
  border: 1.5px solid rgb(19, 206, 102);
  background-color: rgba(19, 206, 102, 0.039);
  margin-bottom: 90px;
  margin-right: 120px;
  border-radius: 4px;
}
.success_tick_container {
  text-align: center;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.inner_circle {
  height: 100%;
  width: 100%;
  background: #fff;
  border: 1.5px solid rgba(19, 206, 102, 1);
  border-radius: 50%;
  padding: 5px;
  box-sizing: border-box;
}
.override_circle_container {
  border: none;
  background-color: #c1f3d7;
}
.circle_container {
  width: 60px;
  height: 60px;
  margin: 0px;
  background-color: rgba(19, 206, 102, 0.039);
}
.tick_icon {
  line-height: 1.3;
  font-size: 30px;
  color: rgba(19, 206, 102, 1);
}
.success_text {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: rgba(19, 206, 102, 1);
  margin-top: 20px;
  margin-bottom: 4px;
}
.success_subtext {
  font-size: 10px;
  text-align: center;
  font-weight: 500;
}
