.recent_folders_content {
  display: flex;
  padding: 9px 0;
  align-items: center;
}
.folder_and_title {
  display: flex;
  align-items: center;
  width: 60%;
}
.recent_folders_icon {
  justify-self: start;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 54px;
  height: 54px;
  border-radius: 50%;
  background-color: #f7f7f7;
}
.recent_folders_title {
  width: 50%;
  flex-grow: 1;
  font-size: 18px;
  font-weight: 500;
  color: #2c2c2c;
  margin-left: 21px;
  margin-right: auto;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.recent_folders_action_icons {
  width: 40%;
}
.recent_folders_action_icons > div {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.recent_folders_action_icons img {
  cursor: pointer;
}

.action_popup {
  position: absolute;
  width: 240px;
  box-shadow: 0px 0px 12px rgba(122, 122, 122, 0.15);
  border-radius: 6px;
  right: 5px;
  background: #ffffff;
  top: 60px;
  padding: 12px;
  z-index: 100;
}
.icon_container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 54px;
  height: 54px;
  cursor: pointer;
}
.info_icon_wrapper {
}
.share_icon {
  width: 28px;
  height: 28px;
}
.cloud_icon {
  width: 25px;
  height: 25px;
}
.action_popup_content {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
}
.action_popup_text {
  color: #9a9a9a;
  font-size: 15px;
}
.action_popup_value {
  color: #2c2c2c;
  font-size: 12px;
}

.popup_bottom_content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
