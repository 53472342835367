.uploaded_doc_content_wrapper {
    display: flex;
    justify-content: space-between;
  }
  .uploaded_doc_section {
    width: 74%;
    background-color: #f5f7fd;
  }

  .strip_logo {
    font-size: 1em;
    color: #ffffff;
    background-color: #144192;
    height: 65px;
    width:65px;
    text-align:center;
    line-height:4;
    cursor: pointer;
  }

  
  .cross_btn {
    text-align: right;
  }
  
  .doc_preview_section {
    height: 100vh;
    position: fixed;
    right: 0;
    width: 27%;
    filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.239));
    margin-top: 62.6px;
    z-index: 5;
  }

  .response_field_strip{
    padding: 10px 16px;
    background: #D3DBF8;
    font-size: 11px;
    color: #144192;
    font-weight: 600;
  }
  
 .minHeight{
     min-height: 200px;
 }
 .minWidth{
     min-width: 200px;
 }

 .response_body {
    font-family: "Roboto", sans-serif;
    padding: 15px;
    width: 100%;
    /* height: -webkit-fill-available; */
    color: #144192;
    opacity: 1;
    /* border: 1px solid #BDC3C7 !important; */
    border: none !important
  }
  .response_content{
    white-space: break-spaces;
    padding: 20px;

  }

  .response_content:focus{
      border: none;
  }

  .action_links{
      color: #144192;
      font-size: 12px;
      text-decoration: underline;
      font-weight: bold;
      padding: 20px;
  }
  .action_links span{
      margin-right: 20px;
      cursor: pointer;
  }

  .response_save{
      width: 90%;
      margin-bottom: 20px;
  }
  .response_clear{
      width: 90%;
      background-color: #ffffff !important;
      color: #144192 !important;
      border: 1px solid #144192;
  }

    .response_clear:hover {
    background-color: #ffffff !important;
    color: #144192 !important;
    border: 1px solid #144192;
  }