.root {
  display: flex;
}
.left_part {
  margin-right: 118px;
}
.edit_photo {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}
.edit_photo > div > span {
  margin-right: 15px;
  font-size: 18px;
}
.edit_photo > img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin-bottom: 20px;
}
.right_part {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.label_and_input_container {
  height: 100px;
  font-size: 18px;
  line-height: 1.2;
}
.new_password {
  height: 105px;
}
.label_and_input_container > .label {
  margin-bottom: 10px;
}
.label_and_input_container input {
  outline: 0px;
  border: 0px;
  background-color: #fafafa;
}
.label_and_input_container:nth-child(2) > .input_container,
.label_and_input_container:nth-child(3) > .input_container {
  color: #c4c4c4;
}
.input_container {
  position: relative;
  display: flex;
  padding: 12px 22px;
  background-color: #fafafa;
  border-radius: 4px;
}
.input_container > input {
  flex-grow: 1;
}
.change_password_header {
  display: flex;
  align-items: center;
  font-size: 18px;
  margin-bottom: 30px;
}
.change_password_header > span {
  margin-right: 15px;
}
.arrow_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #1ba94c;
  cursor: pointer;
}
.password_container {
  height: 330px;
  animation: open 0.8s ease-in-out;
}
@keyframes open {
  from {
    height: 0px;
  }
  to {
    height: 330px;
  }
}

.new_password_label_container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.new_password_label_container > span {
  margin-right: 15px;
}
.info_icon_container {
  position: relative;
}
.info_icon_panel {
  position: absolute;
  bottom: 20px;
  left: 0;
  width: 180px;
  padding: 15px;
  font-size: 12px;
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  z-index: 2;
}
.info_panel_heading {
  font-weight: 500;
  color: #7a7a7a;
  margin-bottom: 8px;
}
.info_panel_content {
  color: #c4c4c4;
}
.info_icon {
  cursor: pointer;
}
.info_panel_content > div {
  margin-bottom: 8px;
}
.info_panel_content > div > span:first-child {
  display: inline-block;
  margin-right: 5px;
}
.eye_icon {
  cursor: pointer;
}
.password_strength_bar {
  width: 0%;
  height: 8px;
  margin-top: 5px;
  background: transparent;
  transition: all 300ms ease-in-out;
  background-color: red;
}
.password_strength_bar[data-score='0'] {
  width: 0;
  background: red;
}

.password_strength_bar[data-score='0'] {
  width: 5%;
  background: red;
}
.password_strength_bar[data-score='10'] {
  width: 15%;
  background: red;
}
.password_strength_bar[data-score='20'] {
  width: 30%;
  background: red;
}
.password_strength_bar[data-score='30'] {
  width: 30%;
  background: red;
}
.password_strength_bar[data-score='40'] {
  width: 40%;
  background: #ffeb3b;
}
.password_strength_bar[data-score='50'] {
  width: 50%;
  background: #ffeb3b;
}
.password_strength_bar[data-score='60'] {
  width: 60%;
  background: #ffeb3b;
}
.password_strength_bar[data-score='80'] {
  width: 75%;
  background: #4caf50;
}
.password_strength_bar[data-score='90'] {
  width: 75%;
  background: #4caf50;
}
.password_strength_bar[data-score='100'] {
  width: 100%;
  background: #4caf50;
}
.label_container {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.label_container > div {
  margin-right: 15px;
  cursor: pointer;
}
.file_input {
  display: none;
}
.picture_change_text {
  font-size: 18px;
  cursor: pointer;
}

.save_changes_btn {
  width: fit-content;
  padding: 15px 24px;
  line-height: 1.2;
  color: #fff;
  background-color: #11256d;
  border-radius: 6px;
  z-index: 1;
  cursor: pointer;
}
