.doc_wrapper{
    margin-top: 62px;
}
.doc_control_container {
    margin-bottom: 3em;
    padding: 1.2em 1em;
    background-color: #d3dbf8;
    color: #708bc3;
    font-weight: 500;
    font-size: 13px;
    position: fixed;
    height: 51px;
    width: 74%;
    z-index: 4;
}
.doc_control_section{
    display: flex; 
    justify-content: space-between;
}
.icon_style{
    padding-right:0.5em; 
    padding-left:0.5em; 
    cursor:pointer;
    font-size: larger;
    margin-top: 2px;
}
.doc_render_wrapper{
    text-align: center;
    /* overflow:auto; */
}
.resize_icons{
    margin-right: 40px;
}