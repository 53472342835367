.root {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 480px;
    height: 400px;
    background-color: white;
    z-index: 1
  }
  .top {
    width: 100%;
    height: 50px;
  }
  
  /* Search Bar */
  .search_bar_container {
    border-bottom: 1px solid #eaeaea;
    padding: 12px 15px;
  }
  .search_bar_background {
    display: flex;
    justify-content: flex-start;
    background-color: #fafafa;
    border-radius: 24px;
  }
  .search_bar_wrapper {
    display: flex;
    align-items: center;
    /* margin-left: 10px; */
    width: 100%;
    height: 26px;
    padding: 0 10px;
    background-color: inherit;
    border-radius: 24px;
  }
  /* Search Bar End */
  
  .filters {
    display: flex;
    flex-direction: column;
    height: 300px;
  }
  
  .bottom {
    display: flex;
    height: 300px;
  }
  
  /* Bottom Left Part */
  .bottom_left {
    flex: 0 1 40%;
    display: flex;
    flex-direction: column;
    border-right: 1px solid #eaeaea;
  }
  .bottom_right {
    flex: 0 1 60%;
    overflow-y: auto;
    margin-left: 18px;
    margin-top: 10px;
  }
  .bottom_right::-webkit-scrollbar-thumb {
    background-color: #11256d;
  }
  
  .bottom_left_top {
    flex: 0 1 85%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }
  .type_container {
    display: flex;
    align-items: center;
    padding: 5px;
    color: #9a9a9a;
    cursor: pointer;
  }
  .heading {
    background-color: #11256d;
    color: white;
  }
  
  .tag {
    font-weight: 400;
    font-size: 15px;
    padding-left: 15px;
  }
  .icon_container {
    margin-left: auto;
  }
  
  .checkbox_container {
    display: flex;
    align-items: center;
    padding: 5px;
    overflow-y: auto;
  }
  
  .checkbox_title {
    margin-left: 10px;
    margin-bottom: 5px;
    font-weight: 400;
    font-size: 15px;
    color: #7a7a7a;
  }
  
  .bottom_left_bottom {
    display: flex;
    margin-top: 18px;
    margin-left: 15px;
  }
  .switch_wrapper {
    display: flex;
    align-items: center;
    margin-right: auto;
  }
  .switch_container {
    display: flex;
    align-items: flex-end;
    /* margin-right: 12px; */
  }
  .bottom_text {
    font-weight: 400;
    font-size: 15px;
    color: #c4c4c4;
  }
  .button_wrapper {
    display: flex;
  }
  .filter_options {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 15px;
  }
  
  .clear_all_btn {
    height: fit-content;
    background-color: #fafafa;
    border-radius: 4px;
    font-weight: 400;
    font-size: 15px;
    color: #c4c4c4;
    padding: 6px 15px;
    margin-right: 9px;
    cursor: pointer;
  }
  .advanced_search_btn {
    height: fit-content;
    padding: 6px 15px;
    background-color: #11256d;
    border-radius: 4px;
    font-weight: 400;
    font-size: 15px;
    color: #ffffff;
    cursor: pointer;
  }
  
  /* ******************** Round Checkboxes ********************/
  .round {
    position: relative;
    margin-right: 15px;
  }
  
  .round label {
    position: absolute;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
    width: 18px;
    height: 18px;
    left: 0;
    top: 0;
  }
  
  .round label:after {
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    content: '';
    width: 8px;
    height: 4px;
    left: 5px;
    top: 6px;
    opacity: 0;
    position: absolute;
    transform: rotate(-45deg);
  }
  
  .round input[type='checkbox'] {
    visibility: hidden;
  }
  
  .round input[type='checkbox']:checked + label {
    background-color: #11256d;
    border-color: #11256d;
  }
  
  .round input[type='checkbox']:checked + label:after {
    opacity: 1;
  }
  
  /****************** Toggle Switch *********************/
  /* The switch - the box around the slider */
  .switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 19px;
    top: 5px;
  }
  
  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #eaeaea;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  
  .slider:before {
    position: absolute;
    content: '';
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 2px;
    background-color: #9a9a9a;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  
  input:checked + .slider {
    background-color: #11256d;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px#11256D;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
    background-color: #fff;
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
  