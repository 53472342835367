.users_list_table {
    width: 100%;
}
.users_list_row_label th label {
    font-size: 13px;
    color: #bdbec5;
}
.users_avatar {
    margin-left: 0px;
    width: 50px;
    height: 50px;
    margin-right: 30px;
    border-radius: 50%;
}
.users_list_container {
    margin-left: 24px;
    overflow: auto;
    height: calc(100vh - 300px);
}
.users_list_container table {
    border-collapse: separate;
    border-spacing: 0 1.2em;
}
.users_list_row_content td i {
    cursor: pointer;
}
.users_list_row_content td span {
    font-size: 14px;
    color:#3B4856
}
.member{
    font-weight: 500;
}