.checkbox_container {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.checkbox_title {
  font-weight: 400;
  font-size: 15px;
  color: #7a7a7a;
  text-align: left;
  cursor: default;
}

/* Custom Checkbox */
.round {
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 15px;
}

.round label {
  position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  width: 18px;
  height: 18px;
  left: 0;
  top: 0;
}

.round label:after {
  border: 0 solid #fff;
  border-top: none;
  border-right: none;
  content: '';
  width: 0;
  height: 0;
  left: 5px;
  top: 0px;
  opacity: 0;
  position: absolute;
  transform: rotate(-45deg);
}

.round input[type='checkbox'] {
  visibility: hidden;
}

.round input[type='checkbox']:checked + label {
  border: 3px solid #11256d;
}

.round input[type='checkbox']:checked + label:after {
  opacity: 1;
}
