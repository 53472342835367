.buttons_wrapper{
    text-align: right;
     margin-top: 35px
}
.btn_gap{
    margin-right: 16px;
}
.response_message textarea {
    width: 100%;
    border: 1px solid #dfe1e5;
    border-radius: 4px;
    padding: 8px;
}