.recent_search_container {
  background: #ffffff;
  padding: 15px 12px;
  border-radius: 6px;
  margin-bottom: 6px;
  cursor: pointer;
}

.recent_search_content {
  display: flex;
  align-items: center;
}
.recent_search_icon {
  padding: 0px 13px 0 5px;
}
.recent_search_text {
  width: 75%;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #2c2c2c;
  font-weight: 400;
  font-size: 15px;
}
.recent_search_date {
  width: fit-content;
  margin-left: auto;
  color: #9a9a9a;
  font-size: 9px;
}
