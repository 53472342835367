.all_source_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.single_source_container {
  min-width: 180px;
  height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed #c4c4c4;
  margin: 5px 5px;
  cursor: pointer;
  border-radius: 6px;
  max-width: 180px;
}

.modal_header_container {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  margin-left: 10px;
}

.modal_header_heading {
  color: #2c2c2c;
}

.drive_icon {
  margin-left: 10px;
}

.source_icon {
  margin-right: 10px;
  width: 25px;
}
.source_icon_small {
  margin-right: 10px;
  width: 40px;
}

.source_name {
  margin: 0;
  padding: 0;
  color: #11256d;
  font-weight: normal;
  font-size: 24px;
}

.confirm_source_container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.confirm_source_active_btn {
  width: 400px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #11256d;
  color: #ffffff;
  border-radius: 6px;
  cursor: pointer;
  margin: 50px 0 20px 0;
}

.confirm_source_disabled_btn {
  width: 400px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f7f7f7;
  color: #9a9a9a;
  border-radius: 6px;
  cursor: pointer;
}

.progress_modal_container {
  padding: 0;
  /* padding-top: 50px; */
}

.horizontal_line {
  width: calc(100% - 70px);
}

.progress_modal_heading_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 48px 40px 0 40px;
}

.progress_modal_minimize_icon {
  width: 54px;
  height: 54px;
  cursor: pointer;
  /* border: 1px solid #7a7a7a; */
  background: #eaeaea;
  border-radius: 50%;
  padding: 16px;
}

.progress_modal_heading {
  color: #2c2c2c;
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 0;
}

.progress_modal_time_info {
  color: #9a9a9a;
  font-size: 12px;
  font-weight: normal;
  line-height: 14px;
}

.progress_modal_section_container {
  width: 100%;
  display: flex;
  padding: 0 30px;
  min-height: 350px;
}
.folder_view_modal_section_container {
  width: 100%;
  display: flex;
  padding: 0 30px;
  min-height: 300px;
}

.progress_modal_section_wrapper {
  width: calc(33% - 15px);
  margin: 0 10px;
  /* background: #fafafa; */
}

.progress_modal_section_heading_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}
.source_modal_section_heading_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 10px 0 10px;
  border-bottom: 1px solid #7a7a7a;
}

.progress_modal_section_heading_left {
  color: #7a7a7a;
  font-size: 18px;
  line-height: 21px;
  margin: 0;
  padding: 10px 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.progress_modal_section_heading_right {
  color: #9a9a9a;
  font-size: 12px;
  line-height: 14px;
  margin: 0;
  padding: 10px 0;
}

.file_list_container {
  width: calc(100% - 20px);
  /* height: 100%; */
  overflow-y: scroll;
  max-height: 350px;
}

.file_list_container::-webkit-scrollbar-track {
  background: #fafafa;
}

.file_list_container::-webkit-scrollbar-thumb {
  background: #11256d;
}

.file_info_container {
  display: flex;
  align-items: center;
  background: #ffffff;
  border-radius: 6px;
  padding: 20px;
  height: 40px;
  border-radius: 6px;
}

.file_info_container img {
  width: 16px;
}

.file_info_wrapper {
  margin: 15px 0;
}

.file_name {
  padding: 0;
  margin: 0;
  margin-left: 10px;
  font-size: 12px;
  line-height: 14px;
  color: #2c2c2c;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.progress_container {
  width: 100%;
  height: 3px;
}

.progress_percent {
  width: 100%;
  height: 100%;
  background: #11256d;
}

.progress_percent_green {
  width: 100%;
  height: 100%;
  background: #1ba94c;
}
.progress_percent_grey {
  width: 100%;
  height: 100%;
  background: #eaeaea;
}

.progress_modal_bottom_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 60px;
  margin-top: 20px;
  padding: 0 40px;
  box-shadow: 0px 0px 8px rgba(122, 122, 122, 0.15);
  background: #fff;
  border-radius: 9px;
}

.cancel_all_btn {
  width: 70px;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  padding: 6px 0;
  height: 30px;
}

.progress_modal_cancel_all_btn {
  width: 117px;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  padding: 6px 0;
  height: 30px;
}

.bottom_text {
  margin: 0;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  color: #11256d;
}

.bottom_text_selection_only {
  margin: 0;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  color: #9a9a9a;
}

.header_search {
  display: flex;
  border-radius: 74px;
  background: #fafafa;
  margin-left: 15px;
  padding-right: 20px;
  align-items: center;
}

.search_box {
  width: 680px;
  border: none;
  background: #fafafa;
  font-size: 16px;
  line-height: 21px;
  border-radius: 74px;
  padding: 10px 0 10px 20px;
  height: 54px;
}

.folder_modal_header_container {
  display: flex;
  align-items: center;
}

.folder_view_cancel_all_btn {
  background: #fafafa;
  margin-right: 10px;
  width: 106px;
  padding: 6px 0;
  color: #9a9a9a;
  height: 30px;
}

.toggle_btn_container {
  display: flex;
}

.folder_modal_section_wrapper {
  width: 48%;
  margin: 0 10px;
  max-width: 48%;
  min-width: 48%;
  /* background: #fafafa; */
}

.files_section_heading_container {
  display: flex;
  align-items: center;
  padding: 10px 20px;
}

.folder_view_heading {
  margin: 0;
  padding: 0;
  font-size: 18px;
  line-height: 21px;
  color: #7a7a7a;
  margin: 0 20px;
}

.folder_view_right_name {
  margin: 0;
  padding: 0;
  font-size: 18px;
  line-height: 21px;
  color: #2c2c2c;
}

.folder_view_right_icon {
  margin: 0 20px;
  width: 18px;
}

.folder_view_right_icon_file {
  margin: 0 20px;
  width: 16px;
}

.folder_view_right_container {
  max-height: 350px;
  min-height: 350px;
  overflow-y: scroll;
}

.folder_view_right_container::-webkit-scrollbar {
  height: 12px;
}
.folder_view_right_container::-webkit-scrollbar-thumb {
  background-color: #11256d !important;
}

.folder_view_modal_bottom_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 60px;
  /* margin-top: 10px; */
  padding: 0 40px;
  box-shadow: 0px -2px 2px rgba(122, 122, 122, 0.15);
  background: #fff;
  border-radius: 9px;
}

.local_files_input {
  display: none;
}

/* New Checkbox */
.round {
  position: relative;
}

.round label {
  position: absolute;
  background-color: #eaeaea;
  /* border: 1px solid #ccc; */
  border-radius: 50%;
  cursor: pointer;
  width: 15px;
  height: 15px;
  left: 0;
  top: 4px;
}

.round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: '';
  width: 8px;
  height: 4px;
  left: 4px;
  top: 5px;
  opacity: 0;
  position: absolute;
  transform: rotate(-45deg);
}

.round input[type='checkbox'] {
  visibility: hidden;
}

.round input[type='checkbox']:checked + label {
  background-color: #11256d;
  border-color: #11256d;
}

/* .round input[type='checkbox']:checked + label:after {
  opacity: 1;
} */

/****************** Toggle Switch *********************/
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 35px;
  height: 19px;
  margin-right: 10px;
}
/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #eaeaea;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.slider:before {
  position: absolute;
  content: '';
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: #9a9a9a;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
input:checked + .slider {
  background-color: #11256d;
}
input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}
input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}
/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}
.slider.round:before {
  border-radius: 50%;
}
/****************** Toggle Switch *********************/

.sort_name_arrow {
  cursor: pointer;
}

.edit_name_icon {
  cursor: pointer;
  margin: 14px 0;
}

.edit_name_search_box {
  width: 300px;
  border: none;
  /* background: #fafafa; */
  font-size: 16px;
  line-height: 21px;
  border-radius: 74px;
  padding: 10px 0 10px 0px;
}
