.heading_row {
    display: flex;
    justify-content: space-between;
}
.all_documents_card_body {
    padding: 0.8em;
    min-height: 200px;
}
.all_documents_content_wrapper {
    margin-top: 30px;
}
.all_documents_tab_container {
    position: relative;
}
.all_documents_btn_container {
    position: absolute;
    right: 0;
    top: 0;
}
.all_document_btn {
    font-size: 0.6875em;
    padding: 4px 9px 4px 8px;
    border-radius: 4px;
    font-weight: 500;
    border: 1.5px solid #144192;
}
.all_document_btn:focus {
    outline: none;
}
.upload_doc_btn {
    color: #144192;
    background: transparent;
    margin-right: 12px;
}
.btn_icon {
    margin-right: 4px;
    font-size: 13px;
}
.doc_status_row{
    display: inline-block;
}
.circle {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    line-height: 2.7;
    text-align: center;
    background: #CCD5F7;
    color: #144192;
    float: left;
    margin-left: 28px;
    margin-right: 24px;
    margin-bottom: 8px;
}
.doc_status{
    text-align: center;
    font-size: 10px;
    color: #373B53;
    
}

.createTicket {
    text-align: center;
    margin-top: 60px;
}
.upload_doc_btn_center {
    color: #144192;
    background: transparent;
    margin-right: 12px;
    padding: 4px 9px 4px 8px;
    border-radius: 4px;
    font-weight: 500;
    border: 1.5px solid #144192;   
}