.wrapper{
    position: fixed;
    background: #ffffff;
    z-index: 500000000000;
    /* width: 100%; */
    width: calc(100% - 65px);
    height: 100vh;
    bottom: 0;
    margin: 0;
    right: 0;
    box-shadow: 10px 0px 6px #00000029;
}


  
  .response_section {
    margin: 5px 0px;
    display: flex;
    /* height: 37vh; */
    background: #ffffff;
  }

  .response_body {
    font-family: "Roboto", sans-serif;
    padding: 15px;
    width: 100%;
    height: 185px;
    color: #144192;
    opacity: 1;
    border: 1px solid #3b4856 !important;
  }
  
  .cross_btn {
    text-align: right;
    font-size: 20px;
    width: 99%;
  }
  .cross_btn:hover {
    cursor: pointer;
  }
  
  .response_save {
    width: 250px !important;
  }
  
  .response_clear {
    width: 250px !important;
    background-color: #ffffff !important;
    color: #144192 !important;
    border: 1px solid #144192;
  }
  .response_clear:hover {
    background-color: #ffffff !important;
    color: #144192 !important;
    border: 1px solid #144192;
  }
  