.doc_wrapper {
  margin-top: 62px;
}
.doc_control_container {
  margin-bottom: 3em;
  padding: 1.2em 1em;
  background-color: #d3dbf8;
  color: #708bc3;
  font-weight: 500;
  font-size: 13px;
  position: fixed;
  height: 51px;
  width: 70%;
  z-index: 4;
  left: 65px;
}
.doc_control_section {
  display: flex;
  justify-content: space-between;
}
.icon_style {
  padding-right: 0.5em;
  padding-left: 0.5em;
  cursor: pointer;
  font-size: larger;
  margin-top: 2px;
}
.doc_render_wrapper {
  text-align: center;
  /* padding-top: 100px; */
  /* overflow: auto; */
}

.AgreeLeft .AsideBox {
  width: 100%;
  cursor: pointer;
  /* height: 38px; */
  height: 50px;
  overflow: hidden;
  text-align: left;
  
}
.doc_render_wrapper .AgreeLeft .AgreeBox{
  /* padding-right: 120px;
  padding-left:  120px  */
}

.AgreeLeft .AsideBox:hover {
  height: auto;
  min-height: 50px;
}
.AgreeLeft .AgreeBox figcaption {
  display: flex;
  position: relative;
  min-height: 55px;
}
.AgreeLeft .AgreeBox figcaption article {
  background-color: #fff;
  width: 80%;
  padding: 30px 70px 20px 50px;
  overflow: inherit;
  text-align: left;
}

.AgreeLeft .AgreeBox figcaption article table {
 max-width:480px !important;

}

.AgreeLeft .AgreeBox figcaption article span {
  font-size: 14px;
}

.AgreeLeft .AgreeBox figcaption aside {
  width: 200px;
  right: 8px;
  margin: 0px;
  position: absolute;
  overflow: inherit;
  top: 20px;
}

.AgreeLeft .AgreeBox figcaption aside .AsideBox:hover {
  height: auto;
  min-height: 50px;
}

.AgreeLeft .AgreeBox figcaption aside .AsideBox .AsideHead {
  width: auto;
  background-color: #fff;
  padding: 10px;
  border-radius: 8px;
  border-left: 4px solid #0fc759;
  border-top: 1px solid #0fc759;
  border-right: 1px solid #0fc759;
  border-bottom: 1px solid #0fc759;
  border-bottom: 1px solid #ddd;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  width: 100%;
  cursor: pointer;
  height: 50px;
  overflow: hidden;
  position: relative;
}
.AgreeLeft .AgreeBox figcaption aside .AsideBox .AsideHead .title {
  font-size: 12px;
  float: left;
  height: 20px;
  overflow: hidden;
}
.AgreeLeft
  .AgreeBox
  figcaption
  aside
  .AsideBox
  .AsideHead
  .confidence_container {
  font-size: 13px;
  position: absolute;
  bottom: 4px;
  left: 8px;
}
.AgreeLeft
  .AgreeBox
  figcaption
  aside
  .AsideBox
  .AsideHead
  .confidence_container
  .confidence_score {
  font-size: 10px;
  font-weight: bold;
}
.AgreeBox figcaption aside .AsideBox .AsideBody {
  background-color: #fff;
  width: 100%;
  padding: 10px 10px 10px 10px;
  z-index: 3;
  transition: 0.5s all ease-in-out;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  border: 1px solid #ddd;
  border-top: none;
}
.AgreeLeft .AgreeBox figcaption aside .AsideBox:hover .AsideBody {
  opacity: 1;
  visibility: visible;
}
.AgreeBox figcaption aside .AsideBox .AsideBody .subTagTitle {
  font-size: 11px;
  color: #bbbcc4bf;
  font-weight: 600;
}
.AgreeLeft .AgreeBox figcaption aside .AsideBox:hover .AsideHead {
  width: auto;
  height: auto;
  min-height: 50px;
  padding-bottom: 24px;
  border-radius: 8px 8px 0px 0px;
}

.AgreeLeft .AgreeBox figcaption aside .AsideBox:hover .AsideHead span.title {
  width: 60% !important;
}

.AgreeLeft .AgreeBox figcaption aside .AsideBox:hover .AsideHead span.title {
  height: auto;
  overflow: inherit;
}

.AgreeLeft .AgreeBox figcaption aside .AsideBox:hover .AsideHead span.action {
  opacity: 1;
  visibility: visible;
}
.AgreeArea .AgreeLeft .AgreeBox figcaption aside .AsideBox:hover .AsideBody {
  opacity: 1;
  visibility: visible;
}

.AgreeBox figcaption aside .AsideBox .AsideBody h4 {
  font-size: 13px;
  margin: 0 0 8px 0;
  color: #525050;
}

.AgreeBox figcaption aside .AsideBox .AsideBody ul {
  display: block;
  max-height: 115px;
  overflow: auto;
  padding: 0px;
}

.AgreeBox figcaption aside .AsideBox .AsideBody ul li {
  border: 1px solid #ddd;
  width: 100%;
  margin: 0 9px 10px 0;
  padding: 5px 7px;
  background-color: #fff;
  display: block;
  color: #635f5f;
  font-size: 13px;
  border-radius: 8px;
}

.AgreeBox figcaption aside .AsideBox .AsideBody ul li:nth-child(even) {
  margin: 0 0 10px 0;
}
.AgreeLeft .AgreeBox figcaption aside .AsideBox .AsideHead span.action {
  float: right;
  opacity: 0;
  visibility: hidden;
}

.AgreeLeft .AgreeBox figcaption aside .AsideBox .AsideHead span.action img {
  width: 20px;
  margin-left: 3px;
}

.AgreeLeft .AgreeBox figcaption aside .AsideBox .AsideHead span.action span {
  margin: 0 0 0 7px;
  color: #9e9b9b;
}
.AgreeLeft .AgreeBox figcaption aside .AsideBox .AsideHead span.action span i {
  color: #144192;
  font-size: 16px;
}
.AgreeBox figcaption aside .AsideBox .AsideBody .mainTagStyle {
  border: 1px solid #ddd;
  width: 100%;
  margin: 0 9px 10px 0;
  padding: 5px 7px;
  background-color: #fff;
  display: block;
  color: #635f5f;
  font-size: 13px;
  border-radius: 8px;
}

.comment_container {
  position: absolute;
  right: 10px;
  width: 200px;
  padding: 0px;
}
.comment_container textarea {
  width: 210px;
  height: 90px;
  border-radius: 4px;
  border: 2px solid #144192;
  border-left-color: #144192;
  padding: 8px;
}
.resize_icons {
  margin-right: 40px;
}



/* ***************************************************** */


.AgreeLeft .AsideBox_click {
  width: 100%;
  cursor: pointer;
  /* height: 38px; */
  height: 50px;
  overflow: hidden;
  text-align: left;
}

.AgreeLeft .AsideBox:hover {
  height: auto;
  min-height: 50px;
}
.AgreeLeft .AgreeBox figcaption {
  display: flex;
  position: relative;
  min-height: 55px;
}
.AgreeLeft .AgreeBox figcaption article {
  background-color: #fff;
  width: 80%;
  padding: 30px 70px 20px 50px;
  overflow: inherit;
  text-align: left;
}

.AgreeLeft .AgreeBox figcaption article span {
  font-size: 14px;
}

.AgreeLeft .AgreeBox figcaption aside {
  width: 200px;
  right: 8px;
  margin: 0px;
  position: absolute;
  overflow: inherit;
  top: 20px;
}

.AgreeLeft .AgreeBox figcaption aside .AsideBox_click {
  height: auto;
  min-height: 50px;
}

.AgreeLeft .AgreeBox figcaption aside .AsideBox_click .AsideHead {
  width: auto;
  background-color: #fff;
  padding: 10px;
  border-radius: 8px;
  border-left: 4px solid #0fc759;
  border-top: 1px solid #0fc759;
  border-right: 1px solid #0fc759;
  border-bottom: 1px solid #0fc759;
  border-bottom: 1px solid #ddd;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  width: 100%;
  cursor: pointer;
  height: 50px;
  overflow: hidden;
  position: relative;
}
.AgreeLeft .AgreeBox figcaption aside .AsideBox_click .AsideHead .title {
  font-size: 12px;
  float: left;
  height: 20px;
  overflow: hidden;
}
.AgreeLeft
  .AgreeBox
  figcaption
  aside
  .AsideBox_click
  .AsideHead
  .confidence_container {
  font-size: 13px;
  position: absolute;
  bottom: 4px;
  left: 8px;
}
.AgreeLeft
  .AgreeBox
  figcaption
  aside
  .AsideBox_click
  .AsideHead
  .confidence_container
  .confidence_score {
  font-size: 10px;
  font-weight: bold;
}
.AgreeBox figcaption aside .AsideBox_click .AsideBody {
  background-color: #fff;
  width: 100%;
  padding: 10px 10px 10px 10px;
  z-index: 3;
  transition: 0.5s all ease-in-out;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  border: 1px solid #ddd;
  border-top: none;
}
.AgreeLeft .AgreeBox figcaption aside .AsideBox_click .AsideBody {
  opacity: 1;
  visibility: visible;
}
.AgreeBox figcaption aside .AsideBox_click .AsideBody .subTagTitle {
  font-size: 11px;
  color: #bbbcc4bf;
  font-weight: 600;
}
.AgreeLeft .AgreeBox figcaption aside .AsideBox_click .AsideHead {
  width: auto;
  height: auto;
  min-height: 50px;
  padding-bottom: 24px;
  border-radius: 8px 8px 0px 0px;
}

.AgreeLeft .AgreeBox figcaption aside .AsideBox_click .AsideHead span.title {
  width: 60% !important;
}

.AgreeLeft .AgreeBox figcaption aside .AsideBox_click .AsideHead span.title {
  height: auto;
  overflow: inherit;
}

.AgreeLeft .AgreeBox figcaption aside .AsideBox_click .AsideHead span.action {
  opacity: 1;
  visibility: visible;
}
.AgreeArea .AgreeLeft .AgreeBox figcaption aside .AsideBox_click .AsideBody {
  opacity: 1;
  visibility: visible;
}

.AgreeBox figcaption aside .AsideBox_click .AsideBody h4 {
  font-size: 13px;
  margin: 0 0 8px 0;
  color: #525050;
}

.AgreeBox figcaption aside .AsideBox_click .AsideBody ul {
  display: block;
  max-height: 115px;
  overflow: auto;
  padding: 0px;
}

.AgreeBox figcaption aside .AsideBox_click .AsideBody ul li {
  border: 1px solid #ddd;
  width: 100%;
  margin: 0 9px 10px 0;
  padding: 5px 7px;
  background-color: #fff;
  display: block;
  color: #635f5f;
  font-size: 13px;
  border-radius: 8px;
}

.AgreeBox figcaption aside .AsideBox_click .AsideBody ul li:nth-child(even) {
  margin: 0 0 10px 0;
}
.AgreeLeft .AgreeBox figcaption aside .AsideBox_click .AsideHead span.action {
  float: right;
  opacity: 0;
  visibility: hidden;
}

.AgreeLeft .AgreeBox figcaption aside .AsideBox_click .AsideHead span.action img {
  width: 20px;
  margin-left: 3px;
}

.AgreeLeft .AgreeBox figcaption aside .AsideBox_click .AsideHead span.action span {
  margin: 0 0 0 7px;
  color: #9e9b9b;
}
.AgreeLeft .AgreeBox figcaption aside .AsideBox_click .AsideHead span.action span i {
  color: #144192;
  font-size: 16px;
}
.AgreeBox figcaption aside .AsideBox_click .AsideBody .mainTagStyle {
  border: 1px solid #ddd;
  width: 100%;
  margin: 0 9px 10px 0;
  padding: 5px 7px;
  background-color: #fff;
  display: block;
  color: #635f5f;
  font-size: 13px;
  border-radius: 8px;
}