.root {
}

.header {
  background-color: #11256d;
  color: #ffffff;
  border-radius: 6px;
  margin-bottom: 10px;
  padding: 12px 15px;
}

.headings {
  border-bottom: 1px solid #eaeaea;
}

.heading {
  font-size: 12px;
  font-weight: 400;
}

.subheading {
  font-size: 18px;
  font-weight: 700;
}

.add_data_source_section {
  display: flex;
  margin-top: 5px;
}
.add_data_source_text {
  font-weight: 400;
  font-size: 18px;
}

.plus_circle_icon_container {
  margin-left: auto;
}
.plus_circle_icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.checkbox_container {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eaeaea;
  padding: 5px;
  overflow-y: auto;
}

.minus_circle_icon_container {
  margin-left: auto;
}

.minus_circle_icon {
  width: 18px;
  height: 18px;
}

.checkbox_title {
  margin-left: 10px;
  margin-bottom: 5px;
  font-weight: 400;
  font-size: 15px;
  color: #7a7a7a;
}

/* Sources Box */
.sources_box {
  display: flex;
  flex-direction: column;
  height: 412px;
  background-color: #ffffff;
}

.sources_container {
  overflow-y: auto;
  padding: 0px 24px;
}
.sources_container::-webkit-scrollbar-thumb {
  background-color: #11256d;
}

/* Search Bar */
/* .sources_box::-webkit-scrollbar-thumb {
  background-color: #11256d;
} */
.search_bar_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  padding: 15px 22px;
  border-bottom: 1px solid #eaeaea;
}
.search_bar_background {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #fafafa;
  border-radius: 24px;
  padding: 3px;
}
.search_bar_wrapper {
  width: 90%;
  background-color: inherit;
}
/* Search Bar End */

/* New Checkbox */
.round {
  position: relative;
}

.round label {
  position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  width: 18px;
  height: 18px;
  left: 0;
  top: 0;
}

.round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: '';
  width: 8px;
  height: 4px;
  left: 5px;
  top: 6px;
  opacity: 0;
  position: absolute;
  transform: rotate(-45deg);
}

.round input[type='checkbox'] {
  visibility: hidden;
}

.round input[type='checkbox']:checked + label {
  background-color: #11256d;
  border-color: #11256d;
}

.round input[type='checkbox']:checked + label:after {
  opacity: 1;
}
