.root {
  width: 100%;
  height: 100%;
  background-color: #fafafa;
}

.search_box_container {
  margin-bottom: 24px;
}

.data_instances_container {
  display: flex;
  height: fit-content;
  margin-bottom: 20px;
}

.left {
  flex: 1 1 73%;
  /* background-color: grey; */
  margin-right: 30px;
}

.right {
  width: 27%;
  flex: 1 1 27%;
}

.recent_document_card {
  margin-bottom: 30px;
}
.recent_documents_container {
  height: fit-content;
  margin: 24px 0;
  border-bottom: 1px solid #eaeaea;
}

.recent_documents_header_container {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.recent_documents_title {
  display: inline-block;
  font-size: 24px;
  font-weight: 500;
  margin-right: 10px;
}

.react_pagination {
  display: flex;
  margin-left: auto;
  align-self: center;
}

.loader_wrapper {
  position: fixed;
  width: 100vw;
  height: 5px;
  top: 0;
  left: 0;
  z-index: 100;
}
