.root {
  display: flex;
  flex-direction: column;
}
.main_container {
  /* padding: 30px 15px 0 15px; */
  padding-top: 78px;
  padding-left: 73px;
  padding-right: 30px;
  /* background-color: #fafafa; */
  overflow-y: hidden;
  /* height: calc(100vh - 78px); */
}
.split_pane_wrapper {
  height: calc(100vh - 78px);
  position: relative;
  /* overflow-y: hidden; */
}
.split_pane_wrapper > div:first-child {
  position: relative;
  height: 104%;
}
.split_pane_wrapper > div:first-child > section:last-child {
  /* overflow: hidden; */
}
.upper_part_root {
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  background-color: #fafafa;
  height: 100%;
}
.section_header_left_file_info_container {
  display: flex;
  align-items: center;
}
.header {
  display: flex;
  align-items: center;
  /* margin-bottom: 22px; */
  padding: 0 15px;
  padding-bottom: 22px !important;
  border-bottom: 1px solid #eaeaea;
}

.back_arrow {
  padding-right: 15px;
  border-right: 1px solid #eaeaea;
  margin-right: 20px;
  cursor: pointer;
}
.heading_container {
  font-weight: 400;
  font-size: 15px;
  color: #c4c4c4;
  margin-left: 20px;
  width: 360px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 20px;
}
.sub_heading {
  font-weight: 500;
  color: #2c2c2c;
  font-size: 18px;
}
.icon_container {
  position: relative;
  display: flex;
  align-self: flex-start;
  margin-left: auto;
}
.share_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  margin-right: 30px;
  border-radius: 50%;
  cursor: pointer;
}
.share_icon_active {
  background-color: #11256d;
}
.info_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  cursor: pointer;
}
.info_panel {
  position: absolute;
  top: 50px;
  right: 0;
  width: 240px;
  height: 142px;
  background-color: #fff;
  padding: 18px 15px;
  box-shadow: 0px 0px 12px rgba(122, 122, 122, 0.15);
  border-radius: 6px;
  z-index: 1;
}
.info_panel > div {
  display: flex;
  align-items: center;
}
.info_panel > div > span:first-child {
  font-size: 15px;
  color: #9a9a9a;
}
.info_panel > div > span:last-child {
  margin-left: auto;
  font-size: 12px;
  color: #2c2c2c;
}
.info_panel > div:nth-child(2) {
  padding-bottom: 10px;
  border-bottom: 1px solid #eaeaea;
}
.info_panel > div:last-child {
  margin-top: 10px;
}
.authors {
  display: flex;
  flex-direction: column;
  justify-self: flex-end;
  margin-left: auto;
  text-align: end;
}
.editor_and_middle_part_wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
  height: 100%;
  padding: 0 15px;
}
.editor_and_middle_part_wrapper > div:first-child {
  /* height: 100vh; */
  padding: 0 15px;
  height: 100%;
}
.editor_and_middle_part_wrapper::-webkit-scrollbar-track {
  background-color: #fafafa;
}
.editor_and_middle_part_wrapper::-webkit-scrollbar-thumb {
  background: #dadada;
  border: 4px solid #fafafa;
  /* opacity: 0.8 !important; */
}

.editing_panel {
  width: 100%;
  height: 54px;
  background-color: #fff;
  box-shadow: 0px 4px 8px rgba(122, 122, 122, 0.15);
  padding: 0 15px 15px 15px;
}

/* Middle Part */
.middle_part {
  display: flex;
  margin-top: 15px;
  position: relative;
  /* height: 95%; */
}

.middle_part_left_container {
  width: 23.5%;
  margin-right: 20px;
  border-right: 1px solid #eaeaea;
}
.left_heading {
  padding: 12px 0;
  border-bottom: 1px solid #eaeaea;
  font-weight: 400;
  font-size: 15px;
  color: #7a7a7a;
}
.issues_wrapper {
  width: 100%;
  height: 306px;
  padding-top: 15px;
  /* padding-right: 18px; */
  display: flex;
  /* overflow-y: scroll; */
}
.issues_bar {
  display: flex;
  height: 95%;
  border-radius: 2px;
  margin-bottom: 15px;
  margin-right: 10px;
}
.issues_bar > div {
  width: 100%;
}
.part_1 {
  width: 40%;
  border-radius: 2px 0 0 2px;
  background-color: #f50072;
}
.part_2 {
  width: 30%;
  background-color: #f59f00;
}
.part_3 {
  width: 30%;
  border-radius: 0 2px 2px 0;
  background-color: #f5dc00;
}

.issue_container {
  font-weight: 400;
  font-size: 12px;
  color: #9a9a9a;
  height: 95%;
  overflow-y: scroll;
  width: 100%;
}
.issue_container::-webkit-scrollbar-thumb {
  background: #dadada;
  border: 4px solid #fafafa;
}
.issue_container::-webkit-scrollbar-track {
  background-color: #fafafa;
}
.issue_details {
  display: flex;
  align-items: center;
  padding-right: 5px;
}
.issue_color_1 {
  /* width: 14px; */
  height: 14px;
  border-radius: 2px;
  margin-right: 8px;
  max-width: 14px;
  min-width: 14px;
  /* background-color: #f50072; */
}
.issue_color_2 {
  width: 14px;
  height: 14px;
  border-radius: 2px;
  margin-right: 8px;
  background-color: #f59f00;
}
.issue_color_3 {
  width: 14px;
  height: 14px;
  border-radius: 2px;
  margin-right: 8px;
  background-color: #f5dc00;
}
.issue_title {
  width: 220px;
  margin-right: 15px;
}
.issue_percentage {
  margin-left: auto;
}

.citations_container {
  width: 100%;
  border-top: 1px solid #eaeaea;
  color: #7a7a7a;
}
.citations_heading {
  padding: 10px 0;
  border-bottom: 1px solid #eaeaea;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 15px;
}
.citations_list {
  width: 100%;
  height: 259px;
  padding-top: 15px;
  font-size: 12px;
  color: #9a9a9a;
  overflow-y: auto;
}
.citation_list_container {
  display: flex;
  margin-bottom: 20px;
}
.citation_title {
  width: 90%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-left: 10px;
}
.citations_list::-webkit-scrollbar-thumb {
  background: #dadada;
  border: 4px solid #fafafa;
}
.citations_list::-webkit-scrollbar-track {
  background-color: #fafafa;
}

.middle_part_middle_container {
  flex-grow: 1;
  max-width: 48%;
  max-height: 650px;
  position: relative;
}

.middle_part_middle_wrapper {
  height: 100%;
}
.editor_container {
  margin-right: 20px;
  height: 100%;
}
.middle_part_right_container {
  width: 27%;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  border-left: 1px solid #eaeaea;
  max-height: 650px;
}
.right_container_container {
  height: 75%;
}
.right_container_header {
  display: flex;
  align-items: center;
  font-size: 15px;
  padding: 12px 0;
  color: #7a7a7a;
  border-bottom: 1px solid #eaeaea;
  cursor: pointer;
}
.right_container_header > span {
  margin-right: auto;
  padding: 0 !important;
  border: none !important;
}
.date_container {
  padding: 15px 0;
  font-weight: 500;
  font-size: 12px;
  border-bottom: 1px solid #eaeaea;
}
.date_container > img {
  margin-right: 15px;
}

.search_bar_and_chips {
  height: 322px;
  /* border-bottom: 1px solid #eaeaea; */
  height: 100%;
  overflow-y: scroll;
}

.search_bar_and_chips::-webkit-scrollbar-track {
  background-color: #fafafa !important;
}
.search_bar_and_chips::-webkit-scrollbar-thumb {
  border: 4px solid #fafafa;
  background-color: #dadada;
}

.search_bar_and_chips_height {
  height: 322px;
  border-bottom: 1px solid #eaeaea;
  height: 100%;
  overflow-y: scroll;
}
.search_bar_and_chips_height::-webkit-scrollbar-track {
  background-color: #fafafa;
}
.search_bar_and_chips_height::-webkit-scrollbar-track {
  background-color: #fafafa;
}
.search_bar_and_chips_height::-webkit-scrollbar-thumb {
  background: #7a7a7a;
}

.search_bar_and_chips_drag::-webkit-scrollbar-track {
  background-color: #efefef !important;
}
.search_bar_and_chips_drag::-webkit-scrollbar-thumb {
  border: 4px solid #efefef;
  background-color: #7a7a7a;
}

.search_bar_and_chips_drag {
  height: 322px;
  border-bottom: 1px solid #eaeaea;
  height: 100%;
  overflow-y: scroll;
}

/* Search Bar */
.search_bar_background {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 42px;
  margin: 15px 0;
  padding: 12px;
  background-color: #ffffff;
  border-radius: 24px;
}
.search_bar_wrapper {
  width: 90%;
  background-color: inherit;
}
/* Search Bar End */

/* Chips */
.chips_container {
  display: flex;
  flex-wrap: wrap;
}
.chips_container > span {
  padding: 9px 15px;
  margin: 0 9px 9px 0;
  border-radius: 30px;
  font-size: 12px;
  color: #9a9a9a;
  border: 1px solid #9a9a9a;
  cursor: pointer;
}
/* .chips_container > span:last-child {
  color: #11256d;
  border: 2px solid #11256d;
} */

.btn_container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-top: 20px;
  font-size: 15px;
  color: #fff;
  text-align: center;
  line-height: 1.2;
  z-index: 1;
  border-top: 1px solid #eaeaea;
}
.prepare_response_btn {
  padding: 15px;
  border-radius: 4px;
  margin-bottom: 10px;
  background-color: #11256d;
  cursor: pointer;
}
.prepare_response_btn_disabled {
  padding: 15px;
  border-radius: 4px;
  margin-bottom: 10px;
  background-color: #11256d;
  cursor: not-allowed;
  opacity: 0.8;
}
.submit_review_btn {
  padding: 15px;
  border-radius: 4px;
  margin-bottom: 10px;
  background-color: #1ba94c;
  cursor: pointer;
}
.submit_review_btn_disabled {
  padding: 15px;
  border-radius: 4px;
  margin-bottom: 10px;
  background-color: #1ba94c;
  cursor: not-allowed;
  opacity: 0.8;
}
/* .btn_container > div:last-child {
  padding: 15px;
  border-radius: 4px;
  margin-bottom: 30px;
  background-color: #1ba94c;
  cursor: pointer;
} */

/* Related Documents */
.related_documents_root {
  height: 100%;
  position: relative;
  background-color: #efefef;
  border-top: 3px solid #eaeaea;
  /* overflow: hidden; */
}
.split_bar_arrow {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -25px;
}
.split_bar_arrow > img {
  pointer-events: none;
}
.related_documents_header_wrapper {
  display: flex;
  align-items: center;
}
.related_documents_wrapper {
  margin: 0 -15px;
  padding: 0 30px;
}
.related_documents_header {
  flex-grow: 1;
  display: flex;
  align-items: center;
  padding: 22px 0;
  font-size: 15px;
  color: #7a7a7a;
  border-bottom: 1px solid #e0e0e0;
}
.related_documents_header > span:first-child {
  margin-right: 18px;
}
.related_documents_header > span:last-child {
  margin-left: auto;
}

.plane2ResizeArrow {
  cursor: pointer;
}
.related_dropdown {
  outline: 0;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0px 5px;
}
.related_dropdown option {
  padding: 5px 0;
}

.right_container_steps_open {
  height: 420px;
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
}
.right_container_steps_open::-webkit-scrollbar-thumb {
  background: #dadada;
  border: 4px solid #fafafa;
}

.right_container_steps_open::-webkit-scrollbar {
  background: transparent;
}

.right_container_steps_open::-webkit-scrollbar-track {
  background: transparent;
}

.right_container_step {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  position: relative;
}

.steps_dot_and_date_container {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.steps_left_green_dot {
  width: 15px;
  height: 15px;
  background-color: #1ba94c;
  border-radius: 50%;
  margin-right: 15px;
}
.steps_left_green_dot_inactive {
  width: 15px;
  height: 15px;
  background-color: #eaeaea;
  border-radius: 50%;
  margin-right: 15px;
}

.steps_middle_active {
  color: #1ba94c;
}

.last_edit_three_dot_icon {
  cursor: pointer;
}

.last_edit_three_dot_icon_active {
  cursor: pointer;
  position: relative;
  left: 10px;
}

/* .right_container_steps {
  position: relative;
  left: 10px;
} */

.document_analytics_right_container_steps
  .ant-steps-item-process
  .ant-steps-item-icon
  > .ant-steps-icon
  .ant-steps-icon-dot {
  background-color: red;
}

.action_dots {
  /* position: relative; */
}

.recent_doc_three_dot_icon {
  height: 20px;
  padding: 2px 0 2px 5px;
  cursor: pointer;
  margin-left: 30px;
  margin-top: 10px;
}

.action_popup {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 180px;
  height: 160px;
  box-shadow: 0px 3px 6px #00000029;
  right: 0px;
  background: #ffffff;
  border-radius: 8px;
  top: 0px;
  /* padding: 12px; */
  /* bottom: -30px; */
  z-index: 3;
  font-size: 15px;
}

.action_popup_row_content {
  flex-grow: 1;
  display: flex;
  align-items: center;
  padding: 5px;
}
.action_popup_share {
  flex-grow: 1;
  padding: 5px;
}
.share_content_wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.action_popup_tag {
  flex-grow: 1;
  display: flex;
  align-items: center;
  padding: 5px;
  cursor: pointer;
  border-bottom: 1px solid #eaeaea;
}
.action_popup_text {
  margin-left: 11px;
}

.action_cloud_download_icon {
  width: 24px;
}

.step_heading {
  font-size: 12px;
  margin: 0;
  color: #2c2c2c;
  cursor: pointer;
}

.step_sub_heading {
  font-size: 12px;
  margin: 0;
  color: #9a9a9a;
}
.related_documents_bottom {
  display: flex;
  width: 100%;
  margin-top: 30px;
  height: 81%;
}
.related_documents_bottom_left {
  width: 33%;
  border-right: 1px solid #e0e0e0;
  margin-top: 10px;
}
.related_documents_bottom_middle {
  /* flex-grow: 1; */
  width: 76%;
  height: 100%;
  padding: 20px 15px 20px 30px;
  overflow-y: scroll;
  background: #f0f0f0;
}

.related_documents_bottom_middle::-webkit-scrollbar {
  background-color: #efefef;
}
.related_documents_bottom_middle::-webkit-scrollbar-track {
  background: #efefef;
}
.related_documents_bottom_middle::-webkit-scrollbar-thumb {
  background-color: #7a7a7a;
  /* border: 4px solid #7a7a7a; */
  border: 4px solid #efefef;
}

.related_documents_bottom_right {
  width: 36%;
  padding-left: 25px;
  margin-top: 30px;
  border-left: 1px solid #e0e0e0;
}

.similar_docs_html_wrapper {
  background-color: #fff;
  /* padding: 30px; */
  /* padding: 30px 0; */
}
.similar_docs_html_wrapper_loading {
  background-color: #fff;
  padding: 0px;
}
.similar_docs_html_wrapper pre {
  white-space: pre-wrap;
  word-break: break-word;
}
.similar_docs_html_wrapper a {
  display: none;
  pointer-events: none;
  cursor: default;
  text-decoration: none;
  color: inherit;
}

.similar_doc_border_container {
  padding: 10px 30px;
  border-left: 4px solid #1ba94c;
}

.similar_doc_default_container {
  padding: 10px 30px;
}
.similar_doc_default_container_fade {
  padding: 10px 30px;
  color: #eaeaea;
}
.similar_doc_default_container_fade pre {
  padding: 10px 30px;
  color: #eaeaea;
}

.search_container {
  display: flex;
  align-items: center;
  font-size: 15px;
  color: #9a9a9a;
  border-bottom: 1px solid #e0e0e0;
  padding-right: 10px;
}
.search_container > input {
  outline: none;
  border: none;
  background-color: #efefef;
}
.search_container > img {
  margin-left: auto;
}

.cards_container {
  width: 100%;
  height: calc(100% - 50px);
  margin-top: 24px;
  overflow-y: auto;
}
.cards_container::-webkit-scrollbar {
  background-color: #efefef;
}
.cards_container::-webkit-scrollbar-track {
  background: #efefef;
}
.cards_container::-webkit-scrollbar-thumb {
  background-color: #7a7a7a;
  /* border: 4px solid #7a7a7a; */
  border: 4px solid #efefef;
}
.related_documents_wrapper {
  width: 100%;
  height: 100%;
}
/* .related_documents_chips > span {
  border: 1px solid #9a9a9a;
} */
/* .related_documents_chips > span:last-child {
  color: #9a9a9a;
  border: 1px solid #9a9a9a;
} */

.related_doc_chip {
  border: 1px solid #9a9a9a;
}

.related_doc_chip:last-child {
  color: #9a9a9a;
  border: 1px solid #9a9a9a;
}

.related_doc_chip_active {
  background-color: #1ba94c;
  color: #fff !important;
}

.related_dropdown {
  border: none;
  background-color: #efefef;
  width: 200px;
}

.related_documents_header select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='grey' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: -2px;
  padding-right: 10px;
}

.show_clustered_result_btn {
  padding: 15px;
  border-radius: 4px;
  margin-bottom: 10px;
  background-color: #11256d;
  cursor: pointer;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.document_container {
  display: flex;
  justify-content: start;
  max-height: 655px;
  overflow-y: auto;
  padding: 5px 0px;
  padding-right: 10px;
  flex-direction: column;
  height: 100%;
}
.document_container_review {
  display: flex;
  justify-content: start;
  max-height: 655px;
  /* overflow-y: auto; */
  padding: 5px 0px;
  padding-right: 10px;
  flex-direction: column;
  height: 100%;
}
.document_container::-webkit-scrollbar {
  background-color: #efefef !important;
}
.document_container::-webkit-scrollbar-thumb {
  background: #dadada;
  border: 4px solid #fafafa;
  /* width: 6px; */
}
.document_container::-webkit-scrollbar-track {
  background-color: #fafafa !important;
}

.drag_document_document_container {
  display: flex;
  justify-content: start;
  width: fit-content;
  margin-left: 20px;
  padding-right: 5px;
  max-height: 650px;
  overflow-y: auto;
  background-color: #efefef;
}
.drag_document_document_container::-webkit-scrollbar-thumb {
  background: #7a7a7a;
  border: 4px solid #efefef;
}
.drag_document_document_container::-webkit-scrollbar-track {
  background-color: #efefef !important;
}
.drag_document_document_container::-webkit-scrollbar {
  background-color: #efefef !important;
}
.tag_action_popup {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 258px;
  box-shadow: 0px 3px 6px #00000029;
  background: #ffffff;
  border-radius: 8px;
  top: 0px;
  z-index: 3;
  font-size: 15px;
  right: -230px;
}

.action_popup_row_content {
  flex-grow: 1;
  display: flex;
  /* align-items: center; */
  padding: 25px;
}
.action_popup_share {
  flex-grow: 1;
  padding: 25px;
}
.share_content_wrapper {
  display: flex;
  /* align-items: center; */
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.tag_action_popup_tag {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  padding: 25px;
  cursor: pointer;
  border-bottom: 1px solid #eaeaea;
}
.tag_action_popup_text {
  /* margin-left: 11px; */
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  color: #9a9a9a;
  margin-bottom: 15px;
}

.tag_subtag_popup_heading {
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #f50072;
}
.tag_subtag_popup_heading_suggested {
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #11025d;
}
.tag_subtag_popup_heading_suggested > div {
  margin-right: 10px;
  width: 10px;
  height: 10px;
  position: relative;
  top: 1px;
  border-radius: 50%;
  background-color: #11025d;
  display: inline-block;
}
.tag_subtag_popup_heading_high {
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #1ba94c;
}
.tag_subtag_popup_heading_high > div {
  margin-right: 10px;
  width: 10px;
  height: 10px;
  position: relative;
  top: 1px;
  border-radius: 50%;
  background-color: #1ba94c;
  display: inline-block;
}
.tag_subtag_popup_heading_custom {
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #f59f00;
}
.tag_subtag_popup_heading_custom > div {
  margin-right: 10px;
  width: 10px;
  height: 10px;
  position: relative;
  top: 1px;
  border-radius: 50%;
  background-color: #f59f00;
  display: inline-block;
}
.tag_subtag_popup_heading_medium {
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #f5dc00;
}
.tag_subtag_popup_heading_medium > div {
  margin-right: 10px;
  width: 10px;
  height: 10px;
  position: relative;
  top: 1px;
  border-radius: 50%;
  background-color: #f5dc00;
  display: inline-block;
}
.tag_subtag_popup_heading_low {
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #f50072;
}
.tag_subtag_popup_heading_low > div {
  margin-right: 10px;
  width: 10px;
  height: 10px;
  position: relative;
  top: 1px;
  border-radius: 50%;
  background-color: #f50072;
  display: inline-block;
}
.tag_bottom_container {
  display: flex;
  justify-content: space-between;
}

.tag_bottom_maintag {
  color: #2c2c2c;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
}

.tag_bottom_confidence {
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #1ba94c;
}

.tag_main_text_para {
  font-weight: normal;
  font-size: 12px;
  color: #2c2c2c;
  margin-bottom: 20px;
  line-height: 20px;
  word-break: break-all;
}

/* .tag_main_text_para div  pre ::-webkit-scrollbar-track {
  background-color: transparent !important;
}
.tag_main_text_para div  pre ::-webkit-scrollbar-thumb {
  background: transparent !important;
} */
.tag_main_text_para pre {
  white-space: pre-wrap;
  word-break: break-word;
}

.tag_main_text_para_fade {
  color: #eaeaea;
}
.tag_main_text_para_fade pre {
  color: #eaeaea;
}

.tag_main_text_para a {
  display: none;
  pointer-events: none;
  cursor: default;
  text-decoration: none;
  color: inherit;
}

.edit_tag_main_container {
  padding: 30px 0;
  background-color: #fff;
  min-height: 640px;
}

.tag_main_text_para_green_border {
  /* width: 10px; */
  border-left: 4px solid #1ba94c;
  padding: 0 30px;
}

.tag_main_text_para_default {
  padding: 0 30px;
}

.selected_sub_tag {
  background-color: #1ba94c;
  color: #ffffff !important;
  border: none !important;
}

.see_similar_paras_btn {
  background-color: #1ba94c;
  margin: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #fff;
  padding: 8px 0px;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  border-radius: 4px;
}
.similar_para_header_container {
  color: #7a7a7a;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
}
.similar_para_single_para {
  background: #f8f9fc;
  border-radius: 4px;
  padding: 24px;
  margin-bottom: 20px;
}
.similar_para_single_para_active {
  background: #f8f9fc;
  border-radius: 4px;
  padding: 24px;
  margin-top: 20px;
  margin-bottom: 20px;
  border-left: 6px solid #1ba94c;
}
.similar_para_single_para_heading {
  border-bottom: 1px solid #eaeaea;
  margin-bottom: 20px;
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
}
.similar_para_single_para_heading_left {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.similar_para_single_para_heading_left_main {
  color: #2c2c2c;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  margin: 8px 0;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
}
.similar_para_single_para_heading_left_sub {
  color: #9a9a9a;
  font-weight: normal;
  font-size: 9px;
  line-height: 11px;
}
.similar_para_single_para_body {
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #2c2c2c;
  overflow-y: scroll;
  max-height: 150px;
}
.similar_para_single_para_body::-webkit-scrollbar-track {
  background-color: #f8f9fc !important;
}
.similar_para_single_para_body::-webkit-scrollbar-thumb {
  background: #7a7a7a;
}
.similar_para_single_para_heading_right > img {
  cursor: pointer;
}

.last_edit_menu_btn {
  border: none;
  background: transparent;
}

.last_edit_action_popup {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 180px;
  height: 117px;
  right: 0px;
  top: 40px;
  z-index: 3;
  font-size: 15px;
  background: #ffffff;
  box-shadow: 0px 0px 12px rgba(122, 122, 122, 0.15);
  border-radius: 6px;
}
.last_edit_action_popup_initial {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 180px;
  height: 117px;
  right: 0px;
  top: 50px;
  z-index: 3;
  font-size: 15px;
  background: #ffffff;
  box-shadow: 0px 0px 12px rgba(122, 122, 122, 0.15);
  border-radius: 6px;
}

.action_popup_row_content {
  flex-grow: 1;
  display: flex;
  align-items: center;
  padding: 5px;
}
.last_edit_action_popup_tag {
  flex-grow: 1;
  display: flex;
  align-items: center;
  padding: 0 15px;
  cursor: pointer;
  align-items: center;
}
.last_edit_action_popup_text {
  color: #9a9a9a;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
}

.grey_pen_icon_subtag {
  width: 20px;
  height: 15px;
}
.para_text_img {
  width: 100%;
}
.subtag_pen_container {
  display: flex;
  justify-content: space-between;
}
.update_tag_container {
  position: absolute;
  right: 280px;
  top: 20px;
  width: 234px;
  /* height: 102px; */
  background: #ffffff;
  box-shadow: 0px 0px 12px rgba(122, 122, 122, 0.15);
  border-radius: 6px;
  word-break: break-all;
  padding: 18px 12px 12px 12px;
}
.update_tag_container_input {
  width: 100%;
  border: none;
  border-bottom: 1px solid #eaeaea;
  padding: 10px;
}
.update_tag_keyword_container {
  width: 100%;
  max-height: 100px;
  overflow-y: scroll;
  margin-top: 10px;
  margin-left: 10px;
}
.update_sub_tags_actual_name {
  color: #9a9a9a;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
}
.text_html_container {
  padding: 20px;
  word-break: break-all;
  background-color: #fff;
}
.restore_secondary_div_container {
  width: 100%;
  height: 50px;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(122, 122, 122, 0.15);
  margin-bottom: 15px;
}
.restore_previous_version_btn {
  background: #1ba94c;
  border-radius: 4px;
  padding: 4px 17px;
  color: #fff;
  cursor: pointer;
}
.restore_secondary_div_info {
  display: flex;
  align-items: center;
}
.middle_restore_green_dot {
  width: 14px;
}
.restore_secondary_div_time {
  margin: 0;
  padding: 0;
  margin-left: 20px;
  margin-right: 20px;
  color: #2c2c2c;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
}
.restore_secondary_div_name {
  margin: 0;
  padding: 0;
  color: #9a9a9a;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
}
.all_file_comments_container {
  width: 100%;
  height: 90%;
  overflow-y: scroll;
  padding: 15px;
}
.all_file_comments_container::-webkit-scrollbar-track {
  background-color: #fafafa;
}
.all_file_comments_container::-webkit-scrollbar-thumb {
  background: #dadada;
  border: 4px solid #fafafa;
}
.single_comment_container {
  width: 100%;
  /* border: 1px solid #1BA94C; */
  display: flex;
  border-radius: 4px;
  margin-bottom: 10px;
  background-color: #fff;
}

.comment_left_number_container {
  /* height: 100%; */
  /* width: 70px; */
  margin: 20px 15px;
  background-color: #1ba94c;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-weight: 500;
  font-size: 9px;
  line-height: 11px;
}
.comment_right_content_container {
  width: calc(100% - 70px);
  height: 100%;
}
.comment_card_heading_container {
  height: 65px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.comment_card_heading_wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.comment_card_head_heading {
  margin: 0;
  padding: 0;
  color: #2c2c2c;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
}
.comment_card_head_time {
  margin: 0;
  padding: 0;
  color: #c4c4c4;
  font-weight: normal;
  font-size: 9px;
  line-height: 11px;
}
.comment_text {
  word-break: break-all;
  color: #9a9a9a;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  padding: 0 10px 10px 0;
}
.new_comment_container {
  width: 100%;
  margin-bottom: 10px;
}
.new_comment_send_btn {
  width: 100%;
  height: 40px;
  background-color: #1ba94c;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  margin-bottom: 10px;
  cursor: pointer;
}
.new_comment_cancel_btn {
  width: 100%;
  height: 40px;
  background-color: red;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  cursor: pointer;
}
.new_comment_input {
  width: 100%;
  height: 80px;
  margin-bottom: 10px;
}
.new_message_input_field {
  border: 1px solid #eaeaea;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 10px;
  width: 100%;
}
.file_status_container {
  display: flex;
  align-items: center;
}

.file_status_container > p {
  margin: 0;
  color: #9a9a9a;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
}
.file_status_indicator {
  width: 12px;
  height: 12px;
  background-color: #9a9a9a;
  border-radius: 50%;
  margin-right: 9px;
}
.file_status_indicator_green {
  width: 12px;
  height: 12px;
  background-color: #1ba94c;
  border-radius: 50%;
  margin-right: 9px;
}

.file_status_container_green {
  display: flex;
  align-items: center;
}

.file_status_container_green > p {
  margin: 0;
  color: #1ba94c;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
}

.review_model_editor_wrapper {
  height: 100%;
}

.related_docs_center_table {
  width: 100%;
}

.selected_comment_menu {
  position: relative;
  cursor: pointer;
  left: 10px;
}

.comment_menu {
  cursor: pointer;
}

.file_comment_action_popup {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 180px;
  height: 70px;
  right: 0px;
  top: 50px;
  z-index: 3;
  font-size: 15px;
  background: #ffffff;
  box-shadow: 0px 0px 12px rgba(122, 122, 122, 0.15);
  border-radius: 6px;
}

.file_comment_action_popup_discard {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 180px;
  height: 50px;
  right: 0px;
  top: 50px;
  z-index: 3;
  font-size: 15px;
  background: #ffffff;
  box-shadow: 0px 0px 12px rgba(122, 122, 122, 0.15);
  border-radius: 6px;
}
