.uploaded_doc_content_wrapper {
  display: flex;
  justify-content: space-between;
}
.uploaded_doc_section {
  width: 74%;
  background-color: #f5f7fd;
}
.doc_preview_section {
  height: 100vh;
  position: fixed;
  right: 0;
  width: 26%;
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.239));
  margin-top: 62.6px;
  z-index: 5;
}

/* @media (max-width: 1024) {
    .dashboard .main_dash_right {
      padding: 25px;
      width: 100%;
    }
} */
