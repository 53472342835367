.root {
    background-color: #fafafa;
  }

  .support_page {
    width: 100%;
  }
  .tabs{
      padding: 28px 12px 12px 10px;
  }
  .support_page td {
    padding: 14px 10px;
    /* border-bottom: 1px solid #eaeaea; */
  }
  .support_page th {
    padding: 15px 10px;
    /* border-bottom: 1px solid #eaeaea; */
    text-align: left;
    /* background-color: #fafafa !important; */
    color: #C4C4C4;
    font-weight: 500;
    font-size: 16px;
    z-index: 0;
  }
  /* .support_page tr:nth-child(odd) {
    background-color: #FAFAFA;
    border-radius: 4px;
    color: #000000;
} */

  .support_id{
      font-weight: 700;
  }

.support_page_label {
    padding: 0px;
    font-size: 16px;
  }

  .support_page_container{
      background-color: #ffffff;
      padding-left: 16px;
      padding-right: 16px;
      border-radius: 6px;
      margin-left: 14px;

  }

  .support_page_header_section{
    margin-bottom: 28px;
    margin-left: 34px;
    position: relative;
    display: flex;
    justify-content: space-between;
  }
  .support_page_header_arrow_icon{
    position: absolute;
    top: 12px;
    left: -34px;
    color: #C4C4C4;
    cursor: pointer;
  }
  .support_page_header_text{
    font-size: 24px;
    color: #C4C4C4;
    font-weight: 500;
  }

  .create_ticket_button{
    width: 211px;
    height: 54px;
    background: #FFFFFF;
    box-shadow: 0px 0px 8px rgba(122, 122, 122, 0.15);
    border-radius: 6px;
    padding: 14px 16px;
    font-size: 18px;
    color: #7A7A7A;
    cursor: pointer;
  }

  .create_ticket_button_content{
      display: flex;
      justify-content: space-between;
  }

  .section_client_name {
    display: flex;
    width: 100%;
  }
  .client_name {
    margin-right: 30px;
    margin-bottom: 24px;
  }
  .client_name input {
    width: 465px;
    height: 51px;
    padding: 15px 24px;
    background-color: #fafafa;
    border-radius: 4px;
    color: #000;
    font-size: 18px;
    border: none;
  }
  .accessibility {
    flex-grow: 1;
  }
  .section_address {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
  }
  .section_address textarea {
    width: 100%;
    padding: 15px 24px;
    background: #fafafa;
    color: #000;
    font-size: 18px;
    border-radius: 4px;
    border: none;
    line-height: 1.2;
  }

  .project_modal_bottom_content {
    display: flex;
    align-items: baseline;
  }
  .section_btn {
    display: flex;
    align-items: center;
    width: 60%;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.2;
  }

.upload_doc {
  display: flex;
  color: #fff;
  width: 465px;
  padding: 13px 22px;
  background-color: #7a7a7a;
  border-radius: 4px;
  cursor: pointer;
}
.upload_doc img {
  margin-left: auto;
}
.create_project {
    color: #fff;
    background-color: #11256d;
    margin-left: auto;
    padding: 13px 22px;
    border-radius: 4px;
    cursor: pointer;
    width: 200px;
    text-align: center;
    font-size: 18px;
    line-height: 1.4;
  }
  .id_container{
    display: flex;
  }

  .priority_badge{
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: block;
    margin-right: 10px;
  }
  .action_popup {
    position: absolute;
    width: 140px;
    height: 75px;
    box-shadow: 0px 3px 6px #00000029;
    right: 5px;
    background: #ffffff;
    border-radius: 8px;
    top: 20;
    padding: 12px;
    bottom: -30px;
    z-index: 100;
  }
  
  .actionText {
    font-size: 12px;
    color: #3b4856;
    cursor: pointer;
  }