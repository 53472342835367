.root {
  position: fixed;
  display: flex;
  align-items: center;
  height: 78px;
  width: 100%;
  z-index: 1000;
  background-color: #fff;
}
.loader_wrapper {
  position: fixed;
  width: 100vw;
  height: 5px;
  top: 0;
  left: 0;
  z-index: 1000000;
}
.company_name {
  margin-left: 24px;
  font-weight: 700;
  font-size: 24px;
  color: #7a7a7a;
}
.right_side {
  display: flex;
  align-items: center;
  margin-left: auto;
}
.upload_document {
  margin-right: 30px;
  margin-bottom: 0px;
  cursor: pointer;
}
.upload_document > input {
  display: none;
}
/* Section Time Tracking Starts*/
.time_tracking {
  position: relative;
  width: 70px;
  padding: 5px;
  margin-right: 24px;
  background-color: #eaeaea;
  color: #11256d;
  font-weight: 500;
  font-size: 9px;
  border-radius: 40px;
  cursor: pointer;
}
.time_tracking_wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.time_tracking_wrapper > span {
  margin-left: auto;
}
.time_tracking_wrapper > img {
  margin-left: auto;
}

.time_tracking_panel {
  position: absolute;
  top: 40px;
  right: 0;
  align-items: center;
  width: 360px;
  height: 377px;
  background-color: #fff;
  box-shadow: 0px 0px 12px rgba(122, 122, 122, 0.15);
  border-radius: 6px;
  font-weight: 400;
  font-size: 15px;
  color: #c4c4c4;
  z-index: 1;
  cursor: default;
}
.upper_part {
  display: flex;
  align-items: center;
  margin: 0 18px;
  padding: 10px 0;
  border-bottom: 1px solid #eaeaea;
}
.lower_part {
  height: 300px;
  padding: 0 0 0 18px;
  margin-top: 15px;
}
.lower_part_data_container {
  height: 200px;
  overflow-y: auto;
  margin-bottom: 40px;
  padding-right: 5px;
}
.lower_part_data_container::-webkit-scrollbar-thumb {
  background-color: #11256d;
}

.lower_part_data {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
.circle_icon {
  margin-right: 9px;
}
.filename {
  width: 180px;
  margin-right: 9px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.time {
  font-size: 12px;
  margin-left: auto;
}
.lower_part_progress {
  display: flex;
  align-items: flex-end;
  width: 324px;
  height: 45px;
  background: #fafafa;
  border-radius: 6px;
  color: #2c2c2c;
  font-size: 12px;
  font-weight: 400;
}
.progress_container {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 15px 12px;
  background: #fff;
  border-radius: 6px;
  color: #000;
  border: 1px solid #11256d;
}
.file_icon {
  margin-right: 12px;
}
.file_progress_text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.progress_percentage {
  margin-left: auto;
}

.switch_container {
  margin-left: auto;
}
/* Section Time Tracking Ends*/

.bell_icon_container {
  position: relative;
  margin-right: 24px;
  cursor: pointer;
}
.badge {
  position: absolute;
  top: 1px;
  right: 0.5px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #f50072;
}
.stats_icon {
  /* position: relative; */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  margin-right: 24px;
  background-color: #eaeaea;
  border-radius: 50%;
  padding: 2px;
  cursor: pointer;
}
.stats_popup_container {
  position: absolute;
  right: 0;
  top: 38px;
  box-shadow: 0px 0px 8px rgba(122, 122, 122, 0.15);
  border-radius: 6px;
}
.stats_popup_content {
  width: 800px;
  height: 310px;
  padding: 30px;
  background-color: #ffffff;
}
.stats_header_section {
  display: flex;
  justify-content: space-between;
}
.header_left_section {
  display: flex;
  align-items: center;
}
.header_text {
  font-size: 24px;
  color: #2c2c2c;
  font-weight: 500;
  margin-right: 40px;
}
.stats_popup_close_icon {
  background-color: #fafafa;
  width: 30px;
  height: 30px;
  text-align: center;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.stats_popup_bottom_divider {
  border-bottom: 1px solid #eaeaea;
  margin-top: 20px;
}
.stats_graph_container {
  display: flex;
  justify-content: space-around;
  margin-top: 24px;
}
.user_icon {
  margin-right: 24px;
  overflow: hidden;
  padding: 2px;
  cursor: pointer;
}
.user_icon > img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.name_container {
  font-weight: 400;
  font-size: 18px;
  color: #7a7a7a;
  margin-right: 70px;
  cursor: pointer;
}

.popup_overlay {
  position: fixed;
  width: 100%;
  height: 100vh;
  /* background: #0000007a; */
  z-index: 10000;
  bottom: 0;
}
.popup_container {
  position: fixed;
  margin: auto;
  top: 4;
  width: 210px;
  height: 98px;
  background: #ffffff;
  /* filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.239)); */
  z-index: 10001;
  top: 65px;
  /* right: 300px; */
  right: 160px;
  box-shadow: 0px 0px 12px rgba(122, 122, 122, 0.15);
border-radius: 6px;
}
.popup_content {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  padding: 14px 20px;
  font-size: 15px;
  color: #9A9A9A;
  cursor: pointer;
}
.popup_content span{
  margin-left: 16px;
}
.settings_divider{
  border-bottom: 1px solid #EAEAEA;
  margin: 4px 0px;
}
/****************** Toggle Switch *********************/

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 35px;
  height: 19px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #eaeaea;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: #9a9a9a;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #eaeaea;
}

input:focus + .slider {
  box-shadow: 0 0 1px #eaeaea;
}

input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
  background-color: #11256d;
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
