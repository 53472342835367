.root {
  display: flex;
  /* flex-direction: column; */
  width: 100%;
  /* height: 294px; */
  background-color: white;
}
.root_search {
  display: flex;
  /* flex-direction: column; */
  width: 100%;
  /* height: 294px; */
  background-color: #ededed;
  padding: 4px 10px;
  border-radius: 4px;
}

.button_container {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  font-weight: 400;
  font-size: 12px;
  color: #c4c4c4;
}

.add_btn {
  height: fit-content;
  padding: 6px 15px;
  background-color: #11256d;
  border-radius: 4px;
  font-weight: 400;
  font-size: 15px;
  color: #ffffff;
  cursor: pointer;
}
.bottom_text {
  font-weight: 400;
  font-size: 12px;
  color: #c4c4c4;
  transform: translateX(-10%);
}

/* Toggle Switch */
.toggle_btn {
  flex: 1 1 15%;
  display: flex;
}
.switch_container {
}

/* Search Bar */
.search_bar_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  padding: 0 5px;
  border-bottom: 1px solid #eaeaea;
}
.search_bar_background {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #fafafa;
  border-radius: 24px;
}
.search_bar_wrapper {
  width: 98%;
  background-color: inherit;
}
/* Search Bar End */

.members_list {
  overflow-y: auto;
  height: 198px;
  padding: 9px 15px;
}
.members_list::-webkit-scrollbar-thumb {
  background-color: #11256d;
}

.checkbox_container {
  display: flex;
  align-items: center;
  padding: 5px;
  overflow-y: auto;
}

.checkbox_title {
  margin-left: 10px;
  margin-bottom: 5px;
  font-weight: 400;
  font-size: 15px;
  color: #7a7a7a;
}
/* Round Checkbox Start */
.round {
  position: relative;
  margin-right: 15px;
}

.round label {
  position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  width: 18px;
  height: 18px;
  left: 0;
  top: 0;
}

.round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: '';
  width: 8px;
  height: 4px;
  left: 5px;
  top: 6px;
  opacity: 0;
  position: absolute;
  transform: rotate(-45deg);
}

.round input[type='checkbox'] {
  visibility: hidden;
}

.round input[type='checkbox']:checked + label {
  background-color: #11256d;
  border-color: #11256d;
}

.round input[type='checkbox']:checked + label:after {
  opacity: 1;
}
/* Round Checkbox End*/

/****************** Toggle Switch *********************/
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 55px;
  height: 19px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #eaeaea;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: #9a9a9a;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #11256d;
}

input:focus + .slider {
  box-shadow: 0 0 1px #11256d;
}

input:checked + .slider:before {
  -webkit-transform: translateX(22px);
  -ms-transform: translateX(22px);
  transform: translateX(22px);
  background-color: #fff;
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* .root {
  width: 100%;
  display: flex;
  background-color: inherit;
} */
.search_box {
  flex-grow: 1;
  background-color: #ededed;
  border: none;
  width: 170px;
  margin-right: 10px;
}
::-webkit-input-placeholder {
  color: #9a9a9a;
}

.search_icon {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #9a9a9a;
  font-size: 12px;
}
