.root {
  padding-left: 160px;
  padding-top: 145px;
  background-color: #fafafa;
  /* line-height: 1.2; */
}

.upper_part {
}
.relative {
  position: relative;
}
.back_icon {
  position: absolute;
  left: -50px;
  top: 0px;
  cursor: pointer;
}
.header {
  width: fit-content;
  color: #c4c4c4;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 30px;
  cursor: pointer;
}
.heading {
  color: #1ba94c;
}
.details_container {
  border-bottom: 1px solid #eaeaea;
}
.project_details_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0;
  border-top: 1px solid #eaeaea;
  font-weight: 400;
  font-size: 15px;
  color: #cacaca;
}
.client_name_container {
  width: 30%;
}
.start_date_name_container {
  width: 20%;
}
.end_date_name_container {
  width: 30%;
}
.client_name {
  font-weight: 500;
  font-size: 18px;
  color: #2c2c2c;
}
.date {
  font-weight: 500;
  font-size: 18px;
  color: #2c2c2c;
}

/* Project Details */
.project_details_btn_container {
  flex-grow: 1;
  align-self: flex-start;
  display: flex;
}
.project_details_btn {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 180px;
  height: 30px;
  margin-left: auto;
  background-color: #eaeaea;
  font-weight: 400;
  font-size: 15px;
  border-radius: 4px;
  color: #7a7a7a;
  cursor: pointer;
}
.project_details_btn > div {
  margin-left: 10px;
}
.btn_container {
  display: flex;
  align-items: center;
  line-height: 1.2;
}
.btn_container > span {
  padding: 6px 15px;
  font-weight: 400;
  font-size: 15px;
  color: #fff;
  border-radius: 4px;
  margin-right: 9px;
}
.btn_container > span:first-child {
  background-color: #2c2c2c;
}
.btn_container > span:nth-child(2) {
  background-color: #9a9a9a;
}
.btn_container > span:last-child {
  background-color: #f50072;
}

.project_details_panel {
  padding-bottom: 45px;
}
.project_details {
  display: flex;
  height: 400px;
  margin-top: 30px;
}
.project_details_text_wrapper {
  line-height: 1.5;
  margin-bottom: 30px;
}
.project_details_text_1 {
  font-weight: 400;
  color: #c4c4c4;
  font-size: 15px;
}
.project_details_text_2 {
  font-weight: 500;
  color: #2c2c2c;
  font-size: 18px;
  text-transform: capitalize;
}
.part_1 {
  width: 30%;
  margin-right: 10px;
}
.part_2 {
  width: 20%;
}
.part_3 {
  display: flex;
  flex-direction: column;
  width: 20%;
}
.part_4 {
  flex-grow: 1;
}
.generate_bill_btn {
  width: fit-content;
  margin-top: auto;
  padding: 6px 15px;
  font-weight: 400;
  font-size: 15px;
  color: #fff;
  border-radius: 4px;
  background: #1ba94c;
  line-height: 1.2;
}

/* Teams Section */
.teams_section {
  display: flex;
  flex-direction: column;
}
.progress_container {
  font-weight: 700;
  font-size: 12px;
  border-bottom: 1px solid #eaeaea;
  padding: 24px 0;
  line-height: 1.2;
  display: flex;
}
/* .progress_container div:first-child {
  margin-right: 100px;
} */

.team_wrapper {
  display: flex;
}
.team_container {
  width: 70%;
  display: flex;
  align-items: center;
  padding: 10px 18px 10px 0;
  border-bottom: 1px solid #eaeaea;
  border-right: 1px solid #eaeaea;
  font-weight: 700;
  font-size: 12px;
  line-height: 1.2;
}
.team_container > span:first-child {
  width: 19%;
  margin-right: 15px;
}
.team_members {
  flex-grow: 1;
}
.team_members > span img {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 3px;
  cursor: pointer;
}
.team_heading {
  width: 30%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  font-weight: 700;
  font-size: 12px;
  color: #2c2c2c;
  border-bottom: 1px solid #eaeaea;
}
.arrow_right_icon {
  margin-left: auto;
}
.arrow_right_icon img {
  width: 12px;
  height: 12px;
}

.team_member_img_container {
  position: relative;
}
.edit_team_member {
  position: absolute;
  display: flex;
  align-items: center;
  width: 240px;
  height: 48px;
  padding: 15px;
  background-color: #fff;
  box-shadow: 0px 0px 12px rgba(122, 122, 122, 0.15);
  border-radius: 6px;
  font-size: 12px;
  color: #c4c4c4;
  font-weight: 400;
  margin-top: 6px;
  z-index: 1;
}
.remove_btn {
  margin-left: auto;
  background: #11256d;
  border-radius: 4px;
  color: #fff;
  font-size: 15px;
  padding: 6px 15px;
  cursor: pointer;
}

/* For Chat Section */
.chat {
  border-left: 1px solid #eaeaea;
}
.team_chat_icons {
  padding: 11px 0px 11px 15px;
  /* border-bottom: 1px solid #eaeaea; */
}
.team_chat_icons > span > img {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 3px;
}

/* Recent Documents Section */
.recent_document {
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 25px;
}
.recent_document_container {
  margin: 16px 0px;
}
.tab_title {
  width: 45px;
  height: 6px;
  border-radius: 1px;
}

.recent_documents_container {
  margin: 22px 0 22px 0;
  border-bottom: 1px solid #eaeaea;
}

.recent_documents_header_container {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.recent_documents_title {
  display: inline-block;
  font-size: 24px;
  font-weight: 500;
  margin-right: 10px;
  color: #2c2c2c;
}

.react_pagination {
  display: flex;
  margin-left: auto;
}
.recent_document_container {
  margin: 16px 0px;
}
.recent_document_icon {
  width: 16px;
  margin-left: 6px;
}
.recent_document_section {
  margin-top: 22px;
  margin-bottom: 28px;
}

/* Section Projects List */
.projects_list {
  padding-bottom: 120px;
}
.list_container {
  display: flex;
  padding: 22px 0;
  font-size: 18px;
  border-bottom: 1px solid #eaeaea;
}
.name_and_icon_container {
  display: flex;
  align-items: center;
  width: 60%;
}
.list_icon {
  /* line-height: 1.2; */
}
.status_container {
  display: flex;
  align-items: center;
  width: 20%;
  color: #9a9a9a;
  font-weight: 400;
}
.status {
  margin-left: 15px;
}
.date_container {
  display: flex;
  align-items: center;
  width: 20%;
  color: #9a9a9a;
  font-weight: 400;
}
.project_name {
  font-weight: 500;
  color: #2c2c2c;
  margin-left: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.projects_list_header {
  display: flex;
  padding: 15px 0;
  font-weight: 400;
  line-height: 1.2;
  font-size: 18px;
  color: #7a7a7a;
  border-bottom: 1px solid #eaeaea;
}
.projects_list_header > span:first-child {
  display: flex;
  align-items: center;
  width: 60%;
}
.projects_list_header > span:nth-child(2) {
  width: 20%;
}
.projects_list_header > span:last-child {
  flex-grow: 1;
}
.projects_list_header > span:first-child > span:first-child {
  margin-right: 20px;
}
/* Search Bar */
.search_bar_container {
  display: flex;
  align-items: center;
  width: 50%;
  height: 45px;
  margin-left: auto;
  border-bottom: 1px solid #fff;
  padding: 12px 0 12px 30px;
}
.search_bar_background {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 45px;
  background-color: #fff;
  border-radius: 24px;
}
.search_bar_wrapper {
  display: flex;
  align-items: center;
  padding: 20px;
  width: 100%;
  height: 26px;
  border-radius: 24px;
  background-color: inherit;
}
/* Search Bar End */

/* For Gantt chart */
.gantt_chart_section {
  display: flex;
  border-bottom: 1px solid #eaeaea;
  margin-bottom: 45px;
}
.left {
  width: 13%;
  border-right: 1px solid #eaeaea;
}
.task_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 700;
  font-size: 12px;
  color: #1ba94c;
  padding: 26px 15px 26px 0;
  border-bottom: 1px solid #eaeaea;
}
.planning_container {
  line-height: 1.2;
  padding: 18px 12px 0 0;
  border-bottom: 1px solid #eaeaea;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
@keyframes open {
  from {
    height: 0px;
  }
  to {
    height: 107px;
  }
}
.planning_text_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.text_1 {
  font-size: 18px;
}
.text_2 {
  color: #9a9a9a;
  font-size: 9px;
  margin-bottom: 12px;
}
.up_arrow_icon {
  align-self: flex-start;
  cursor: pointer;
}
.avatars {
  display: inline-flex;
}

/* For Overlapping Images */
.avatar {
  position: relative;
  border: 1px solid #fff;
  overflow: hidden;
  padding-bottom: 18px;
  transition: all 2s ease;
}

.avatar:not(:last-child) {
  margin-right: -8px;
}

.avatar img {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.design_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  padding: 18px 12px 18px 0;
  border-bottom: 1px solid #eaeaea;
}
.implementation_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  padding: 18px 12px 18px 0;
}
.implementation_container > span {
  width: 100px;
  padding-right: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.chart {
  width: 57%;
}
.chat {
  width: 30%;
}

.add_team_member_section {
  display: inline;
  position: relative;
  cursor: pointer;
}
.add_team_member_popup_section {
  position: absolute;
  z-index: 2;
  right: 0;
  top: 25px;
}
/****************** Toggle Switch *********************/

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 35px;
  height: 19px;
  margin-right: 12px;
  margin-top: 5px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #eaeaea;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: #9a9a9a;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #eaeaea;
}

input:focus + .slider {
  box-shadow: 0 0 1px #eaeaea;
}

input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
  background-color: #11256d;
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.progress_step {
  width: 100%;
}

.related_dropdown {
  cursor: pointer;
  outline: 0;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0px 5px;
}
.related_dropdown option {
  color: black;
  padding: 5px 0;
}

.related_documents_header {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #7a7a7a;
}

.related_documents_header select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='grey' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 130%;
  background-position-y: -4px;
  padding-right: 10px;
  width: 50px;
}



/* For Tooltip */
.tooltip {
  position: relative;
  display: inline-block;
  /* border-bottom: 1px dotted black; */
  cursor: pointer;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: fit-content;
  background-color: #eaeaea;
  color: #000000;
  text-align: center;
  border-radius: 6px;
  padding: 15px;
  font-size: 10px;

  /* Position the tooltip */
  position: absolute;
  top: 30px;
  left: 0;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}