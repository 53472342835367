.fp_form_container{
    margin-top: 200px;
    margin-left: 140px;
}
.fp_main_text{
    font-size: 1.5em;
    font-weight: 600;
    margin-bottom: 4px;
}

.fp_submit_container {
    text-align: center;
    margin-top: 20px;
}