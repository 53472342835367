.recent_doc_container {
  display: flex;
  position: relative;
}
.recent_document_card_wrapper:not(:last-of-type) {
  width: 18.5%;
  display: flex;
  flex-direction: column;
  margin-right: 37px;
  
}
.recent_document_card_wrapper:last-of-type {
  width: 18.5%;
  display: flex;
  flex-direction: column;
 
}
.recent_doc_card {
  display: flex;
  flex-direction: column;
  height: 122px;
  background-color: #ffffff;
  padding: 18px 18px 10px 18px;
  border-radius: 6px;
  margin-bottom: 9px;
}
.chips_container {
  display: flex;
  flex-wrap: wrap;
}
.chips_container > span {
  padding: 5px 15px;
  margin: 0 6px 6px 0;
  background: #eaeaea;
  border-radius: 30px;
  font-weight: 400;
  font-size: 9px;
  color: #9a9a9a;
}
.recent_doc_card_upper_content {
  display: flex;
  flex-direction: column;
  position: relative;
}
.recent_doc_card_upper_content img {
  align-self: flex-start;
}
.recent_doc_grey_document {
  margin-bottom: 8px;
}
.recent_doc_card_title {
  height: 36px;
  font-size: 15px;
  line-height: 20px;
  color: #2c2c2c;
  font-weight: 400;
  cursor: pointer;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  margin-bottom: 12px;
}
.badge_container {
  margin-top: auto;
  font-weight: 400;
  font-size: 10px;
  color: #1ba94c;
}
.badge_container > span:last-child {
  margin-left: 9px;
}
.recent_doc_card_subtitle {
  font-size: 10px;
  color: #9a9a9a;
  word-wrap: break-word;
}
.recent_doc_card_bottom_content {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 9px;
  color: #9a9a9a;
}
.recent_doc_card_bottom_text {
  display: flex;
  align-items: flex-end;
}
.action_dots {
  position: relative;
}
.recent_doc_three_dot_icon {
  height: 100%;
  padding: 2px 0 2px 5px;
  cursor: pointer;
}
.dot {
  margin-left: 2px;
  font-size: 12px;
  font-weight: bold;
  margin-right: 2px;
}

.next_icon {
  position: absolute;
  right: -12px;
  bottom: 78px;
  cursor: pointer;
}

.action_popup {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 180px;
  height: 160px;
  box-shadow: 0px 3px 6px #00000029;
  right: 5px;
  background: #ffffff;
  border-radius: 8px;
  top: 25px;
  /* padding: 12px; */
  /* bottom: -30px; */
  z-index: 101;
  right: -4px;
  font-size: 15px;
}

.action_popup_row_content {
  flex-grow: 1;
  display: flex;
  align-items: center;
  padding: 5px;
}
.action_popup_share {
  flex-grow: 1;
  padding: 5px;
}
.share_content_wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.action_popup_tag {
  flex-grow: 1;
  display: flex;
  align-items: center;
  /* margin-bottom: 8px; */
  padding: 5px;
  cursor: pointer;
  border-bottom: 1px solid #eaeaea;
}
.action_popup_text {
  margin-left: 11px;
}
.action_share_icon {
  width: 24px;
}
.action_cloud_download_icon {
  width: 24px;
}

.recently_added_sources {
}
.recently_added_sources_header {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 24px;
}


/* For Tooltip */
.tooltip {
  /* position: relative; */
  display: inline-block;
  /* border-bottom: 1px dotted black; */
  cursor: default;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: fit-content;
  background-color: #000;
  opacity: .8;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 15px;
  font-size: 10px;

  /* Position the tooltip */
  position: absolute;
  top: 50px;
  left: 0;
  z-index: 100;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}