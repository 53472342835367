


.change_password_container{
    display:flex;
     justify-content:space-between
}
.change_password_form_section{
    width: 35%;
    margin-left: 150px;
    margin-top: 40px;
}
.edit_profile_submit_container{
    margin-top: 20px;
}
.password_rules_container{
    width:200px;
    height:160px;
    border: 1px solid lightgrey;
    margin-right: 20px;
    margin-top: 24px;
    border-radius: 8px;
}
.password_rules_section{
    font-size: 10px;
    font-weight: bold;
    color: #144192;
    margin-left: 10px;
    margin-top: 12px;
}
.password_rules{
    font-weight: normal;
}
.password_rules_container p{
    margin-bottom: 8px;
}
.password_strength_container{
    margin-top: 20px;
    margin-bottom: 32px;
}
.password_strength_label{
font-size: 0.8em;
font-weight: bold;
float: left;
margin-right: 16px;
}
