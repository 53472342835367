.buttons_wrapper{
    text-align: right;
     margin-top: 82px
}
.btn_gap{
    margin-right: 16px;
}

.create_project {
    color: #fff;
    background-color: #11256d;
    margin-left: auto;
    padding: 13px 22px;
    border-radius: 4px;
    cursor: pointer;
    width: 200px;
    text-align: center;
    font-size: 18px;
    line-height: 1.4;
  }