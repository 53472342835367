.wrapper {
}
.root {
  padding: 45px 30px 0 30px;
  background-color: #fafafa;
  box-shadow: 0px 0px 4px rgba(122, 122, 122, 0.15);
}

/* Data Catalog CSS */
.data_catalog_container {
  background-color: #fafafa;
  /* margin-top: 10px; */
}
.top_container {
  display: flex;
  justify-content: space-between;
}
.data_catalog_heading {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  color: #2c2c2c;
}
.data_catalog_title {
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 10px;
  margin-top: -5px;
}
.data_catalog_subtitle {
  font-size: 36px;
  font-weight: 500;
  line-height: 1.2;
}

.top_container_cards_section {
  width: 100%;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  margin-bottom: 30px;
  /* border: 1px solid red; */
}
.top_container_cards_section i {
  font-size: 40px;
}
.card_wrapper {
  display: flex;
  margin-left: auto;
}

.new_project_card {
  display: flex;
  align-items: center;
  width: 360px;
  height: 122px;
  margin-left: 15px;
  padding: 15px;
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(122, 122, 122, 0.15);
}
/* .new_project_card_body {
    padding: 1em;
  } */

/* .top_container_content {
    display: flex;
    padding: 15px;
    align-items: center;
    border: 1px solid red;;
  } */
.folder_icon_container {
  position: relative;
  display: flex;
  background: #f7f7f7;
  width: 92px;
  height: 92px;
  border-radius: 4px;
}
.folder_icon_container img {
  margin: 12px;
}
.badge {
  position: absolute;
  top: -5px;
  right: -5px;
  width: 15px;
  height: 15px;
  padding: 1px;
  border-radius: 50%;
  background-color: #f50072;
}
.connector_icon_container {
  display: flex;
  justify-content: center;
  background: #f7f7f7;
  width: 92px;
  height: 92px;
  border-radius: 4px;
}
.connector_icon_container img {
  padding: 10px;
}

.plus_icon {
  cursor: pointer;
}

/* Search Bar */
.search_bar_container {
  display: flex;
  flex-direction: column;
  /* justify-content: center;
  align-items: center; */
  border-bottom: 1px solid #eaeaea;
  margin-bottom: 24px;
}
.search_bar_background {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 45px;
  padding: 12px 30px;
  background-color: #fff;
  border-radius: 74px;
  margin-bottom: 9px;
}
.search_bar_wrapper {
  width: 100%;
  background-color: inherit;
}
/* Search Bar End */

.recent_searches_wrapper {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #c4c4c4;
  padding: 0 30px;
  margin-bottom: 30px;
}
.recent_search_results_container {
  display: flex;
  align-items: center;
  margin-right: 24px;
  cursor: pointer;
}
.recent_search_results_container > img {
  display: inline-block;
  margin-right: 9px;
}
.recent_search_results_container > span {
  display: inline-block;
  max-width: 237px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.middle_section {
  display: flex;
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid #eaeaea;
}
.projects {
  width: 37%;
  margin-right: 15px;
}
.time_spent {
  width: 34%;
  height: 444px;
}
.recent_searches_and_private_folders {
  width: 26%;
  height: 444px;
}
.recent_searches {
  margin-bottom: 22px;
}
.recent_searches_container_wrapper {
  padding-bottom: 15px;
  border-bottom: 1px solid #eaeaea;
}
.recent_search_container {
  background: #ffffff;
  padding: 15px;
  border-radius: 6px;
  margin-bottom: 6px;
  cursor: pointer;
}
.recent_search_content {
  display: flex;
  align-items: center;
}
.recent_search_icon {
  padding: 0px 13px 0 5px;
}
.recent_search_text {
  display: inline-block;
  max-width: 240px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 15px;
}
.recent_search_date {
  width: fit-content;
  margin-left: auto;
  color: #9a9a9a;
  font-size: 8px;
}

.heading {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 24px;
  margin-bottom: 24px;
}
.heading > span:first-child {
  margin-right: 16px;
}
.view_all {
  margin-left: auto;
  font-weight: 400;
  font-size: 18px;
  color: #7a7a7a;
  cursor: pointer;
}
/* For Related Folders */
.related_folder__root {
  width: 100%;
  overflow-y: auto;
  background-color: #fafafa;
}

.related_folder__folder {
  display: flex;
  width: 100%;
  height: 72px;
  margin-bottom: 10px;
  align-items: center;
  background-color: white;
  padding: 20px 10px;
  border-radius: 6px;
  cursor: pointer;
}

.related_folder__name {
  font-weight: 500;
  font-size: 18px;
  color: #2c2c2c;
}

.related_folder__icon {
  margin: 0 40px 0 20px;
}

.cards_wrapper {
  height: 444px;
  overflow-y: auto;
  overflow-x: hidden;
}
.cards_wrapper::-webkit-scrollbar {
  width: 0px;
}
.card_container_wrapper {
  display: flex;
  align-items: flex-end;
  border-radius: 4px 10px 10px 4px;
  margin-bottom: 9px;
  margin-right: 10px;
  cursor: pointer;
}
.cards_container {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.project_card {
  display: flex;
  background-color: #ffffff;
  padding: 13px 24px;
}
.left_part {
  display: flex;
  flex-direction: column;
  width: 60%;
}
.avatars {
  display: inline-flex;
  padding: 2px;
}

.avatar {
  position: relative;
  width: 24px;
  height: 24px;
  border: 1px solid #fff;
  border-radius: 50%;
  overflow: hidden;
}

.avatar:not(:last-child) {
  margin-right: -8px;
}

.avatar img {
  width: 24px;
  height: 24px;
}

.project_name {
  width: fit-content;
  font-weight: 500;
  font-size: 18px;
}
.company_name {
  font-size: 12px;
  color: #1ba94c;
  margin-bottom: 12px;
}
.right_part {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 40%;
  font-size: 9px;
  color: #9a9a9a;
}
.steps {
  margin-bottom: 10px;
  margin-right: 15px;
}
.date_container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.all_folder_and_searches_container {
  display: flex;
}
.all_folders_container {
  width: 60%;
  /* flex: 1 1 60%; */
  margin-right: 30px;
}
.recent_searches_container {
  width: 40%;
  /* flex: 1 1 40%; */
}

.top_container_card_info {
  flex-grow: 1;
  color: #7a7a7a;
  margin-left: 13px;
  padding: 5px 5px 5px 0;
}
.top_container_title {
  font-size: 12px;
}
.top_container_count {
  font-size: 18px;
  font-weight: 700;
  border-bottom: 1px solid #7a7a7a;
  padding-bottom: 6px;
  margin-bottom: 6px;
  cursor: pointer;
}
.text_plus_icon_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.top_container_action {
  font-size: 18px;
}
.card_container {
  display: flex;
  justify-content: space-between;
  /* height: 350px; */
  /* margin-bottom: 80px;
    margin-top: 18px; */
  /* border: 1px solid red; */
  padding-bottom: 30px;
  margin-top: 20px;
  border-bottom: 1px solid #eaeaea;
}
.recent_folders_heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}
.title {
  font-size: 22px;
  font-weight: 500;
  color: #2c2c2c;
}
.heading_icon {
  margin-left: 8px;
  width: 20px;
}
.recent_queries_card {
  height: 360px;
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(122, 122, 122, 0.15);
  border-radius: 6px;
}
.recent_queries_card_body {
  height: 100%;
  padding-top: 10px;
}
.data_catalog_tabs {
  margin-right: 12px;
}
.recent_folders_view_all {
  font-size: 16px;
  font-weight: 400;
  color: #7a7a7a;
  cursor: pointer;
  margin-right: 15px;
}
.recent_folders_card {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 358px;
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(122, 122, 122, 0.15);
  border-radius: 6px;
  padding: 10px 30px;
}
.recent_folders_card::-webkit-scrollbar-thumb {
  background-color: #11256d;
}

.tab_title {
  width: 45px;
  height: 6px;
  border-radius: 1px;
}

.react_pagination {
  display: flex;
  margin-left: auto;
}
.recent_document_icon {
  width: 16px;
  margin-left: 6px;
}
.recent_document_section {
  margin-top: 22px;
  margin-bottom: 28px;
}
.all_folder_title_icon {
  width: 20px;
  margin-left: 6px;
}
.recent_search_title_icon {
  width: 24px;
  margin-left: 6px;
}
.recent_searches_title_container {
  padding: 0 0px 22px 0;
}
.all_folders_title_container {
  padding: 0 0px 22px 0;
  border-bottom: 1px solid #eaeaea;
}
.recent_document_icon {
  font-size: 14px;
}
.left_card {
  width: 60%;
  margin-right: 30px;
}
.right_card {
  width: 40%;
}
.add_source_modal_btn {
  cursor: pointer;
}

.recent_documents_container {
  margin: 22px 0 22px 0;
  border-bottom: 1px solid #eaeaea;
}

.recent_documents_header_container {
  display: flex;
  align-items: center;
}

.recent_documents_title {
  display: inline-block;
  font-size: 24px;
  font-weight: 500;
  margin-right: 10px;
  color: #2c2c2c;
}
.icon_container {
  margin-right: 30px;
}
.upload_documents_btn {
  width: 153px;
  font-size: 9px;
  background-color: #11256d;
  border-radius: 4px;
  color: #fff;
}
.upload_documents_btn > label {
  display: flex;
  align-items: center;
  padding: 6px 12px;
  margin: 0;
  cursor: pointer;
}
.upload_documents_btn > label > img {
  margin-left: auto;
}
.upload_documents_btn > label > input {
  display: none;
}

.react_pagination {
  display: flex;
  margin-left: auto;
}
.recent_document_container {
  margin: 16px 0px;
}
.recent_document_icon {
  width: 16px;
  margin-left: 6px;
}
.recent_document_section {
  margin-top: 22px;
  margin-bottom: 28px;
}

.recently_added_sources_header {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 20px;
}
.recently_added_sources_header > span {
  margin-right: 15px;
}
.recently_added_sources_container {
  display: flex;
  align-items: center;
  overflow-x: auto;
  padding-bottom: 45px;
}
.recently_added_sources_card {
  display: flex;
  align-items: center;
  width: 18%;
  margin-right: 15px;
  background: #ffffff;
  border-radius: 6px;
  padding: 15px;
  cursor: pointer;
}
.recently_added_sources_card > img {
  margin-right: 15px;
}
.recently_added_sources_card > span {
  display: inline-block;
  max-width: 80%;
  font-size: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.d3_chart_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 85%;
}
.pie_chart_bottom_label {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.total_time_text {
  padding: 0;
  margin: 0;
  margin-bottom: 8px;
  color: #9a9a9a;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
}
.time_taken_time_text {
  color: #2c2c2c;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
}

.radio_btn_container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.radio_btn_container > p {
  margin: 0;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 0;
  color: #c4c4c4;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 35px;
  height: 19px;
  margin-right: 10px;
}
/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #eaeaea;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.slider:before {
  position: absolute;
  content: '';
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: #9a9a9a;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
input:checked + .slider {
  background-color: #1ba94c;
}
input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}
input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}
/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}
.slider.round:before {
  border-radius: 50%;
}
