
.badge1 {
  position: relative;
  font-size: 24px;
  margin-top: 8px;
  /* margin-right: 15px; */
  color: #bdc1c6;
  cursor: pointer;
}

.badge1[data-badge]:after {
  content: attr(data-badge);
  position: absolute;
  top: -4px;
  right: -4px;
  font-size: 0.7em;
  background: #e63e3c;
  color: white;
  width: 8px;
  height: 8px;
  text-align: center;
  line-height: 18px;
  border-radius: 50%;
}

  .notification {
    top: 189px;
    left: 148px;
    height: 16px;
    color: #3b4856;
    margin-left: 50px;
  }
  
  .notification_card {
    margin-bottom: 25px;
    display: block;
  }
  
  .notification_heading {
    color: #3b4856;
    top: 189px;
    /* left: 148px; */
    height: 16px;
    letter-spacing: 0px;
    font-weight: bold;
    opacity: 1;
    /* text-align: left; */
    margin-bottom: 9px;
    font-size: 12px;
  }
  
  .notification_data {
    /* display: flex; */
    color: #3b4856;
    opacity: 0.68;
    top: 214px;
    left: 148px;
    height: 35px;
    font-size: 12px;
    /* justify-content: space-between; */
    font-family: "Roboto", sans-serif;
  }
  
  .noNotification {
    font-family: "Roboto", sans-serif;
    text-align: center;
    margin-top: 200px;
  }
  
  .notificationDate {
    /* justify-content: space-between; */
    text-align: right;
    /* color: #c0c0c0; */
    font-size: 12px;
    top: 126px;
    height: 10px;
    /* margin-left: 500px; */
    opacity: 1;
    font-family: "Roboto", sans-serif;
  }

  
  .markAsRead {
    color: #144192;
    font-weight: 600;
    font-size: 12px;
    box-shadow: none !important;
    border-radius: 4px;
    padding-top: 12px;
    padding-bottom: 12px;
    padding: 3px !important;
  }
  
  .markAsRead:hover {
    color: #144192 !important;
  }
  
  .notifications_container {
    margin-left: 24px;
    overflow: auto;
    /* height: calc(100vh - 300px); */
  }
  