/* .Sidebar {
    background-color: #020027;
    height: 100%;
    min-height: 100vh;
  }
  .imgContainer{
    text-align: center;
    padding-top: 60px;
  }
.text{
  font-size: 42px;
    color: #ffffff;
    font-weight: bolder;
    letter-spacing: 2px;
    line-height: 1;
}
.subText{
  font-size: 13px;
    color: #ffffff;
    font-weight: 500;
}
.login_logo{
  position: absolute;
    bottom: 0;
    left: 0;
} */

.left_part {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height:100vh;
  color: #fff;
  background-color: #11256d;
}
.left_part > img {
  margin-bottom: 114px;
}

.left_part > div:nth-child(2){
  width: 377px;
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 15px;
}

.left_part > div:last-child {
  width: 377px;
}
