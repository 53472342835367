.root {
  position: absolute;
  width: 240px;
  height: 72px;
  background-color: white;
  padding: 15px;
  z-index: 100;
}
.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 15px;
}
.icon_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 15px;
  cursor: pointer;
}
.text {
  margin-right: auto;
  color: #9a9a9a;
}
.tag {
  margin-right: 15px;
  color: #11256d;
  cursor: pointer;
}
.icon_container {
  cursor: pointer;
}
