
.search_result_card_body {
    padding: .8em 2em 0.8em 2em;
    min-height: 200px;
}
.search_result_content_wrapper {
    margin-top: 30px;
}


.search_result_table{
    width: 100%;
}
.search_result_row_label th label{
    font-size: 13px;
    color: #BDBEC5;
}
.search_result_list_container{
    overflow: auto;
}
.search_result_list_container table {
    border-collapse:separate; 
    border-spacing: 0 1.5em;
  }
  .search_result_table thead{
      line-height: 0.4;
  }

  /* .search_result_table thead tr th:last-child { text-align: right; }
.search_result_table td:last-child { text-align: right; } */
  
 table thead tr th {
    background-color: #ffffff;
    position: sticky !important;
    z-index: 100;
    top: 0;
  }

  .search_result_list_row_content td span{
    font-size: 12px;
    color: #3B4856;
}
.doc_name{
    /* font-size: 14px !important; */
    color: #3B4856;
    font-weight: 500;
    cursor: pointer;
}
.doc_status{
    font-size: 11px !important;
    color: #11256D;
    font-weight: 500;
    background-color: #CCD5F7;
    padding: 7px 26px;
    border-radius: 20px;
}
.doc_version{
    /* text-decoration: underline; */
    color: #11256D;
    font-weight: 500;
    cursor: pointer;
}

.doc_version u {
    text-decoration: none;
    border-bottom: 1.5px solid #11256D;
}
.no_result_text{
}
.action_popup{
    position: absolute;
    width: 140px;
    height: 80px;
    box-shadow: 0px 3px 6px #00000029;
    right: 5px;
    background: #ffffff;
    border-radius: 8px;
    top: 20;
    padding: 12px;
    bottom: -30px;
    z-index: 100;
}
.actionText{
    font-size: 12px;
    color: #3B4856;
}