.root {
  display: flex;
  position: absolute;
  right: 0;
  bottom: 0;
  flex-direction: column;
  position: fixed;
  width: 18px;
  height: calc(100% - 78px);
  z-index: 1000;
  background-color: #fff;
}
