.team_member_list_table {
    width: 100%;
}
.team_member_list_row_label th label {
    font-size: 11px;
    color: #bdbec5;
    margin-right: 8px;
    cursor: pointer;
    position: relative;
}
.team_member_list_container .team_member_list_table td,
th {
  border-bottom: none;
  text-align: left;
  padding: 0px;
  background-color: #fff !important ;
  color: inherit;
  font-weight: 400;
}
.team_member_list_row_label i{
    position: absolute;
}
.team_member_avatar {
    margin-left: 0px;
    width: 50px;
    height: 50px;
    margin-right: 30px;
    border-radius: 50%;
}
.team_member_list_container {
    margin-left: 24px;
    overflow: auto;
    height: calc(100vh - 300px);
}
.team_member_list_container table {
    border-collapse: separate;
    border-spacing: 0 1.2em;
}
.team_member_list_row_content td i {
    cursor: pointer;
}
.team_member_list_row_content td span {
    font-size: 12px;
    color:#3B4856
}
.member{
    font-weight: 500;
}