.all_folders_tree_folders_container {
  display: flex;
  /* justify-content: space-between; */
}

.all_folders_tree_folders_card {
  background: #ffffff;
  height: 72px;
  width: 18.5%;
  display: flex;
  align-items: center;
  padding: 15px;
  border-radius: 6px;
  margin-right: 20px;
  cursor: pointer;
}

.folder_icon {
  margin-right: 20px;
}

.folder_title {
  color: #2c2c2c;
  font-weight: 400;
  font-size: 18px;
  overflow: hidden;
}
