.version_history_container {
    padding: 20px 20px 20px 20px;
}
.version_history_section {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}
.version {
    font-size: 12px;
    color: #3b4856;
    font-weight: bold;
}
.date {
    font-size: 9px;
    color: #1e2d3d;
}
.open_doc {
    font-size: 12px;
    color: #144192;
    font-weight: bold;
    cursor: pointer;
}
