.root {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.upper_part {
  display: flex;
  height: 490px;
  padding-bottom: 40px;
  border-bottom: 3px solid #eaeaea;
}
.bottom_part {
  display: flex;
  height: 60px;
  padding-top: 15px;
}
.pdf_controls {
}
.left {
  width: 44%;
  margin-right: 30px;
  margin-right: 30px;
  height: fit-content;
  border: 1px solid #eaeaea;
}
.right {
  width: 56%;
}
.container {
  display: flex;
  flex-direction: column;
  font-size: 18px;
  color: #c4c4c4;
  margin-bottom: 24px;
}
.label {
  font-size: 15px;
  color: #2c2c2c;
  margin-bottom: 9px;
}
.input_wrapper {
  display: flex;
  padding: 13px 24px;
  background-color: #fafafa;
  border-radius: 4px;
}
.input_wrapper > input {
  width: 100%;
  color: #2c2c2c;
  outline: none;
  border: none;
  outline: 0px !important;
  -webkit-appearance: none;
  background-color: #fafafa;
}
.pdf_controls {
  width: 44%;
  display: flex;
  justify-content: center;
}
.pdf_controls > p {
  color: #11256d;
}
.pdf_controls > div {
  cursor: pointer;
}
.pdf_controls > div:first-child {
  margin-right: auto;
}
.pdf_controls > div:last-child {
  margin-left: auto;
}
.cluster_btn {
  margin-left: auto;
  height: fit-content;
  padding: 4px 15px;
  color: #fff;
  background-color: #11256d;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
