.holder {
  position: relative;
}
.search_dropdown {
  position: absolute;
  /* display: none; */
  z-index: 9999;
  width: 100%;
  cursor: pointer;
  background-color: #f5f7fd;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
}

input:focus + .search_dropdown {
  display: block;
}
body {
  background-color: #f5f7fd;
}

.badge1 {
  position: relative;
  font-size: 24px;
  margin-top: 8px;
  margin-right: 32px;
  color: #bdc1c6;
  cursor: pointer;
}

.badge1[data-badge]:after {
  content: attr(data-badge);
  position: absolute;
  top: -4px;
  right: -4px;
  font-size: 0.7em;
  background: #e63e3c;
  color: white;
  width: 8px;
  height: 8px;
  text-align: center;
  line-height: 18px;
  border-radius: 50%;
}
.avatar {
  vertical-align: middle;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-left: 20px;
  cursor: pointer;
}
.navbar_container {
  display: flex;
}
.navbar_profile_section {
  text-align: right;
}
.navbar_profile_name {
  font-size: 12px;
  font-weight: 500;
}
.navbar_profile_email {
  display: block;
  font-size: 12px;
  margin-top: -4px;
}
.popup_overlay {
  position: fixed;
  width: 100%;
  height: 100vh;
  background: #0000007a;
  z-index: 10000;
  bottom: 0;
}
.popup_container {
  position: fixed;
  margin: auto;
  top: 4;
  width: 210px;
  height: 85px;
  background: #ffffff;
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.239));
  z-index: 10001;
  top: 60px;
  right: 8px;
  border-radius: 8px;
  background-color: #f5f7fd;
}

.popup_content {
  font-size: 14px;
  color: #3b4856;
  font-weight: bold;
  margin-left: 20px;
  margin-top: 14px;
  cursor: pointer;
}

.popup_overlay_notification {
  position: fixed;
  width: 100%;
  height: 100vh;
  background: #0000007a;
  z-index: 10000;
  bottom: 0;
}

.popup_container_notification {
  position: fixed;
  margin: auto;
  /* width: 259px; */
  height: 361px;
  /* background: #ffffff; */
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.239));
  z-index: 10001;
  top: 11px;
  right: 151px;
  border-radius: 8px;
  background-color: #f5f7fd;
  overflow: auto;
}

.popup_container_notification_heading {
  display: flex;
  justify-content: space-between;
  top: 24px;
  left: 814px;
  width: 87px;
  height: 14px;
  color: #3b4856;
  text-align: left;
  opacity: 1;
  font-size: 12px;
  margin-top: 12px;
  margin-left: 12px;
}

.popup_container_notification_heading_sub {
  white-space: nowrap;
  color: #144192;
  margin-left: 100px;
  cursor: pointer;
}

.popup_content_notification {
  font-size: 14px;
  color: #3b4856;
  font-weight: bold;
  margin-left: 20px;
  margin-top: 14px;
  cursor: pointer;
}

.notification {
  display: flex;
  font-size: 12px;
  color: #3b4856;
  top: 63px;
  left: 814px;
  width: 222px;
  height: 15px;
  margin-bottom: 0;
  margin-right: 5px;
  font-family: 'Roboto', sans-serif;
}

.notificationDate {
  color: #c0c0c0;
  font-size: 11.5px;
  top: 126px;
  height: 10px;
  margin-top: 5px;
  font-family: 'Roboto', sans-serif;
}

.notificationDetails {
  display: flex;
  color: #a0a2ae;
  font-size: 12px;
  /* top: 82px;
  left: 814px; */
  width: 226px;
  /* height: 48px; */
  font-weight: normal;
  opacity: 0.68;
  /* margin-bottom: -7px; */
  font-family: 'Roboto', sans-serif;
}

.signout {
  margin-top: 16px;
}
.formControl {
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
}
.vl {
  border-left: 1px solid #bdc1c6;
  height: 36px;
  margin-right: 20px;
}
.searchIcon {
  position: absolute;
  right: 12px;
  bottom: 3px;
}
.searchBarContainer {
  position: relative;
}
.loader_wrapper {
  position: absolute;
  width: 100%;
}
