.container {
    height: 100vh;
    background-color: #F5F7FD;
}
.heading_container{
    text-align: left;
}
.card{
    box-shadow: 0 2px 2px rgba(0,0,0,.24);
}
.text{
    font-size: 2em;
    font-weight: 600;
    margin-bottom: 0px;
}
.ac_card{
    margin-top: 10px;
    border-radius: 8px !important;

}
.ac_card_body{
    text-align: left;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 10px;
}
.header_container{
    display:flex;
    justify-content:space-between;
    background-color: #ffffff;
    height: 65px;
}
.strip_logo {
    font-size: 1em;
    color: #ffffff;
    background-color: #144192;
    height: 65px;
    width:65px;
    text-align:center;
    line-height:4;
    cursor: pointer;
  }
  .heading_action_container{
    line-height: 3.7;
    margin-right: 40px;
  }

  .ac_wrapper{
    padding-left: 110px;
    padding-top: 32px;
    padding-right: 50px;
  }

  .ac_wrapper i{
    margin-left: -32px;
    margin-right: 10px;
    cursor: pointer;

  }
