.outerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height:374px;
    /*background-color: #1A1A1D;*/
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: inherit;
    border-radius: 8px;
    height: 100%;
    width: 100%;
    padding-right: 0px;
    padding-left: 0px;
}

@media (min-width: 320px) and (max-width: 480px) {
    .outerContainer {
        height: 100%;
    }

    .container {
        width: 100%;
        height: 100%;
    }
}

@media (min-width: 480px) and (max-width: 1200px) {
    .container {
        width: 60%;
    }
}