.root {
  background-color: #fafafa;
}

/* Upper Part */
.upper_part {
  border-bottom: 1px solid #eaeaea;
}
.header {
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  font-size: 24px;
  color: #c4c4c4;
}
.heading {
  line-height: 1.2;
  margin-top: 25px;
  font-weight: 500;
}
.username {
  color: #2c2c2c;
}
.search_bar_section {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding-bottom: 30px;
}
.search_bar_container {
  width: 600px;
  height: 45px;
  background-color: #fff;
  padding: 8px 30px;
  border-radius: 74px;
}
.project_info {
  align-self: flex-end;
  display: flex;
  align-items: baseline;
  font-weight: 400;
  font-size: 18px;
  color: #c4c4c4;
}
.circle_fill_icon {
  margin-right: 15px;
}

/* For Card */
.new_project_card {
  display: flex;
  align-items: center;
  width: 360px;
  height: 122px;
  margin-left: 15px;
  padding: 15px;
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(122, 122, 122, 0.15);
  border-radius: 6px;
}
.folder_icon_container {
  display: flex;
  background: #f7f7f7;
  width: 92px;
  height: 92px;
  border-radius: 4px;
}
.folder_icon_container img {
  margin: 12px;
}
.connector_icon_container {
  display: flex;
  background: #f7f7f7;
  width: 92px;
  height: 92px;
}
.text_plus_icon_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.connector_icon_container img {
  padding: 10px;
}
.create_new_project_container {
  display: 'flex';
  justify-content: 'space-between';
  align-items: center;
}
.plus_icon {
  display: flex;
  align-items: flex-start;
  cursor: pointer;
}
.top_container_card_info {
  flex-grow: 1;
  color: #7a7a7a;
  margin-left: 13px;
  padding: 5px 5px 5px 0;
}
.top_container_title {
  font-size: 12px;
}
.top_container_count {
  font-size: 18px;
  font-weight: 700;
  border-bottom: 1px solid #7a7a7a;
  padding-bottom: 6px;
  margin-bottom: 6px;
}
.top_container_action {
  font-size: 18px;
}

/* Middle Part */
.middle_part {
  margin-top: 24px;
  background-color: #fafafa;
}
.middle_part_heading {
  font-weight: 500;
  font-size: 24px;
  color: #2c2c2c;
  margin-bottom: 24px;
}
.overview_heading {
  font-weight: 500;
  margin-right: 15px;
}
.tabs {
  display: inline-block;
  margin-bottom: 15px;
  border-radius: 1px solid #eaeaea;
}
.dropdown_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0;
  margin: 15px 0;
  border-top: 1px solid #eaeaea;
  border-bottom: 1px solid #eaeaea;
}
.dropdown_right_container {
  display: flex;
}
.dropdown {
  display: flex;
}
.dropdown_left_margin {
  margin-left: 15px;
}
.graph_container {
  /* height: 200px; */
}

/* Lower Part */
.lower_part {
  margin-top: 20px;
  border-top: 1px solid #eaeaea;
}
