.inreview_files_content_wrapper{
    display: flex;
     justify-content: space-between;
}
.inreview_para_section{
    width: 74%;
     background-color: #F5F7FD;
}
.inreview_file_details_section {
    height: 100vh;
    position: fixed;
    right: 0;
    /* width: 26%; */
    width: 27%;
    filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.239));
    margin-top: 62.6px;
    z-index: 5;
    
}