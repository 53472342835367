.checkbox_container {
  display: flex;
  align-items: center;
  /* margin: 5px 0; */
}
.wrapper {
  display: flex;
  height: 300px;
}
.list {
  flex-grow: 1;
  height: 300px;
  height: inherit;
  overflow-y: scroll;
}
.alphabets_list {
  display: flex;
  flex-direction: column;
  height: 316px;
  /* overflow-y: scroll; */
  line-height: 1.3;
}
.alphabets {
  font-size: 9px;
  font-weight: 400;
  cursor: pointer;
  /* margin-bottom: 10px; */
}
.char_label {
  width: 10px;
  margin-right: 5px;
  color: #11256d;
  font-weight: 500;
}
.checkbox_title {
  font-weight: 400;
  font-size: 15px;
  color: #7a7a7a;
  text-align: left;
}

.minus_icon {
  margin-left: auto;
  cursor: pointer;
}

/* Custom Checkbox */
.round {
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 15px;
}

.round label {
  position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  width: 18px;
  height: 18px;
  left: 0;
  top: 0;
}

.round label:after {
  border: 0 solid #fff;
  border-top: none;
  border-right: none;
  content: '';
  width: 0;
  height: 0;
  left: 5px;
  top: 0px;
  opacity: 0;
  position: absolute;
  transform: rotate(-45deg);
}

.round input[type='checkbox'] {
  visibility: hidden;
}

.round input[type='checkbox']:checked + label {
  background-color: #11256d;
  border-color: #11256d;
}
.round input[type='checkbox']:not(:checked) + label {
  background-color: #eaeaea;
  border-color: #eaeaea;
}

.round input[type='checkbox']:checked + label:after {
  opacity: 1;
}
