
.top_actions_strip{
    background-color:#D3DBF8;
     height:60px;
     margin-bottom: 40px;

     /* position: fixed;
     width: 82.5%;
     z-index: 5000000; */
}
.actions_strip_section{
    display:flex;
     justify-content:space-between;
      line-height:4;
      padding: 0px 12px;
}
.file_name{
    font-size: 12px;
    color:#144192;
    font-weight: bold;
    line-height: 5;
}
.icon_style{
    padding-right:0.5em; 
    padding-left:0.5em; 
    cursor:pointer;
    font-size: larger;
    margin-top: 2px;
    color:#144192;
}


.cut_icon{
    font-size: 24px;
    margin-left: 12px;
    cursor: pointer;
    color:#144192;
}
