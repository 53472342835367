.modal_header_container {
  width: 100%;
  display: flex;
  align-items: center;
}
.modal_header_heading {
  display: flex;
  align-items: center;
  width: 126px;
  height: 54px;
  margin: 0;
  margin-right: 30px;
}
.search_bar_container {
  flex-grow: 1;
  background-color: #fafafa;
  border-radius: 74px;
  padding: 9px 20px;
}
.email_container {
  display: flex;
  align-items: flex-start;
  padding: 15px 0;
  border-bottom: 1px solid #eaeaea;
}
.checkbox {
  margin-right: 30px;
}
.sender_details {
  margin-right: 30px;
}
.sender_details > div:last-child {
  width: 200px;
  font-size: 15px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.label {
  font-size: 9px;
  color: #9a9a9a;
}
.subject_section {
  margin-right: 30px;
}
.title {
  width: 320px;
  font-size: 15px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.attachments {
  display: flex;
  align-items: center;
  margin-top: 7px;
  font-size: 12px;
  color: #c4c4c4;
}
.attachment_1 {
  display: flex;
  align-items: center;
  width: 150px;
  height: 26px;
  padding: 6px 8px;
  margin-right: 6px;
  border-radius: 42px;
  border: 0.5px solid #c4c4c4;
}
.attachment_1 > img {
  margin-right: 10px;
}
.attachment_2 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  border: 0.5px solid #c4c4c4;
  line-height: 1.2;
}

.date_section > div:last-child {
  max-width: 120px;
  font-size: 15px;
}

.dropdown_container {
  position: relative;
  margin-left: auto;
  cursor: pointer;
}
.dropdown {
  display: flex;
  align-items: center;
  padding: 9px 15px;
  font-size: 12px;
  background: #fafafa;
  border-radius: 4px;
}
.dropdown > div:first-child {
  max-width: 120px;
  margin-right: 13px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.panel {
  position: absolute;
  width: 240px;
  right: 0;
  top: 40px;
  background: #ffffff;
  box-shadow: 0px 0px 12px rgba(122, 122, 122, 0.15);
  border-radius: 6px;
  z-index: 1;
  color: #9a9a9a;
  cursor: auto;
}
.filter_container {
  height: 190px;
  padding: 0 15px 12px 15px;
  overflow-y: auto;
}
.search_bar {
  border-bottom: 1px solid #eaeaea;
  margin-bottom: 12px;
  padding: 12px 15px;
}
.filter {
  margin-bottom: 10px;
  font-size: 15px;
}

/*************** Custom Checkbox *********************/
.container {
  display: block;
  position: relative;
  width: 24px;
  height: 24px;
  /* padding-left: 35px; */
  /* margin-bottom: 12px; */
  cursor: pointer;
  /* font-size: 22px; */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  background-color: #fafafa;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #fafafa;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #11256d;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
