.edit_profile_form_section {
  width: 35%;
}
.profile_pic_section {
  text-align: center;
  margin-top: 40px;
}


.picture_selection {
  text-align: center;
  margin-top: 5px;
  width: 100%;
  height: 100%;
}

.buttons {
  text-align: center;
  margin-top: 95px;
}

.profile_pic_label {
  position: relative;
  cursor: pointer;
  height: 150px;
  width: 150px;
  border-radius: 50%;
}
.profile_pic_label_crop {
  position: relative;
  cursor: pointer;
  height: 150px;
  width: 150px;
}
.profile_pic {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}
.file_input {
  display: none;
}
.picture_change_text {
  color: #144192;
  text-decoration: underline;
  cursor: pointer;
}
.edit_profile_submit_container {
  text-align: left;
  margin-top: 20px;
}

.btn {
  display: block;
}

.second_btn {
  display: block;
  margin-top: 35px;
}

.buttons_wrapper {
  text-align: right;
  margin-top: 82px;
}

.btn_gap {
  margin-right: 16px;
}
