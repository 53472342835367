/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  font-size: 16px;
}

hr {
  border-color: #eaeaea !important;
}
/************* Global styling for tree view start ***********/
.ant-tree-treenode-selected::before {
  background: #11256d !important;
}

.ant-tree-switcher {
  margin: 0 10px 5px 20px;
}

.anticon-file > svg {
  width: 20px !important;
}

.ant-tree.ant-tree-directory .ant-tree-treenode {
  padding: 15px 0;
}

.ant-tree-node-selected > span {
  color: #fff !important;
}

.ant-tree-title {
  position: relative;
  top: 4px;
  margin-left: 20px;
  font-size: 18px;
  color: #7a7a7a;
}

.anticon-folder > svg {
  width: 25px;
  height: 25px;
}

.anticon-file > svg {
  width: 25px;
  height: 25px;
}

.anticon-folder-open > svg {
  width: 25px;
  height: 25px;
}

.folder_tree_container .ant-tree-list-holder {
  background: #fafafa;
}
.folder_tree_container .ant-tree-title {
  font-size: 18px;
  font-weight: 500;
  color: #7a7a7a;
}
.folder_tree_container .ant-tree-switcher {
  margin-right: 20px;
}
/************* Global styling for tree view end ***********/

.data_source_model_blue_bg {
  background: rgba(17, 37, 109, 0.9);
  opacity: 1 !important;
}

.highcharts-credits {
  display: none;
}

.highcharts-yaxis-labels > text {
  font-size: 12px !important;
  color: #9a9a9a;
}

.highcharts-xaxis-labels > text {
  font-size: 12px !important;
  color: #9a9a9a;
}

/* ************************* NewProjectModal ********************************/
.new_project_modal_blue_bg {
  background: rgba(17, 37, 109, 0.9);
  opacity: 1 !important;
}
/* ************************* EmailModal ********************************/
.email_common_modal_blue_bg {
  background: rgba(17, 37, 109, 0.9);
  opacity: 1 !important;
}
/* ************************* UploadDocumentModal ********************************/
.upload_document_common_modal_blue_bg {
  background: rgba(17, 37, 109, 0.9);
  opacity: 1 !important;
}
/* ************************* Document analytics page similar para styling ********************************/
.documents_analytics_similar_para_model_blue_bg {
  background: transparent;
  opacity: 1 !important;
}

/* ************************* Project page small steps styling ********************************/
.all_project_home_steps_small .ant-steps-item-tail {
  width: 135% !important;
  margin: 0 0 0 5px !important;
}

.all_project_home_steps_small
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: #1ba94c !important;
}

.all_project_home_steps_small .ant-steps-item-content {
  width: 30px !important;
}

.all_project_home_steps_small .ant-steps-item-icon {
  margin-left: 10px !important;
  top: 1px !important;
}

.all_project_home_steps_small .ant-steps-icon-dot {
  width: 7px !important;
  height: 7px !important;
  background: #1ba94c !important;
}

.all_project_home_steps_small
  .ant-steps-item-wait
  .ant-steps-item-icon
  > .ant-steps-icon
  .ant-steps-icon-dot {
  background: #f7f7f7 !important;
  position: relative;
  top: 1px;
}

/* for dashboard */
.dashboard_v2_steps
  .ant-steps-item-wait
  .ant-steps-item-icon
  > .ant-steps-icon
  .ant-steps-icon-dot {
  background: #f7f7f7 !important;
  position: relative;
  top: 1px;
}

.document_analytics_right_container_steps .ant-steps-item-icon {
  width: 7px !important;
  height: 7px !important;
  margin-left: -4px !important;
}

.document_analytics_right_container_steps
  .ant-steps-item-process
  .ant-steps-item-icon
  > .ant-steps-icon
  .ant-steps-icon-dot {
  background: #1ba94c !important;
  width: 15px;
  height: 15px;
}

.document_analytics_right_container_steps
  .ant-steps-item-wait
  .ant-steps-item-icon
  > .ant-steps-icon
  .ant-steps-icon-dot {
  width: 15px;
  height: 15px;
}

.document_analytics_right_container_steps
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: #1ba94c !important;
}

.document_analytics_right_container_steps .ant-steps-item-title {
  width: 95%;
}

/* ************************* Document analytics text editor styling ********************************/
.document_analytics_edit .fr-box {
  height: 88% !important;
}
.document_analytics_edit .fr-toolbar {
  z-index: 0 !important;
  margin-bottom: 15px;
  border-radius: 0;
  border: none;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(122, 122, 122, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
}
.document_analytics_edit .fr-btn-grp {
  display: flex !important;
  margin: 0 !important;
  /* border-right: 1px solid #EAEAEA; */
}
.document_analytics_edit .fr-wrapper {
  height: 100% !important;
  z-index: 0;
  border: none;
  background: #ffffff;
  padding: 30px;
  border: none !important;
  overflow-y: scroll;
  max-height: 60vh;
  /* max-height: initial !important; */
}
.document_analytics_edit .fr-element {
  height: 100% !important;
}
.document_analytics_edit .fr-second-toolbar {
  display: none !important;
}

.document_analytics_pane_container .Pane {
  height: 100%;
  width: 100%;
}

.document_analytics_pane_container .quill {
  height: 100%;
}

.document_analytics_pane_container .ql-container {
  max-height: 630px;
  overflow-y: scroll;
  margin-top: 20px;
  border: none !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
}
.document_analytics_pane_container .ql-editor {
  background: white;
  padding: 30px;
}
.document_analytics_pane_container .ql-toolbar {
  background: white;
  border: none !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  padding: 15px 10px;
}
.document_analytics_pane_container .gutter {
  position: relative;
  background-color: transparent;
  cursor: row-resize;
  z-index: 1;
}

/* ********************** CreateDocument ************************** */

.create_document_pane_container .Pane {
  height: 100%;
  width: 100%;
}

.create_document_pane_container .quill {
  height: 100%;
}

.create_document_pane_container .ql-container {
  height: calc(100% - 60px);
  overflow-y: scroll;
  margin-top: 20px;
  border: none !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
}
.create_document_pane_container .ql-editor {
  background: white;
  padding: 30px;
}
.create_document_pane_container .ql-toolbar {
  background: white;
  border: none !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  padding: 15px 10px;
}
.create_document_pane_container .gutter {
  position: relative;
  background-color: transparent;
  cursor: row-resize;
  z-index: 1;
}

/* ************************* Dashboard v2 small steps styling ********************************/
.dashboard_v2_steps .ant-steps-item-tail {
  width: 160% !important;
  margin: -1px 0 0 5px !important;
  height: 1px !important;
}

.dashboard_v2_steps
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: #1ba94c !important;
}

.dashboard_v2_steps .ant-steps-item-content {
  width: 0px !important;
}

.dashboard_v2_steps .ant-steps-item-icon {
  position: relative;
  right: -15px;
  width: 7px !important;
  height: 7px !important;
  margin: 0 !important;
}

.dashboard_v2_steps .ant-steps-icon-dot {
  background: #1ba94c !important;
  width: 7px;
  height: 7px;
}

/* .highcharts-tooltip > span {
  max-height: 200px;
  overflow-y: auto;
} */

.project_detail_progress_steps .ant-steps-item-content {
  display: none !important;
}

.project_detail_progress_steps .ant-steps-icon-dot {
  background: #1ba94c !important;
  width: 14px;
  height: 14px;
}

.project_detail_progress_steps
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: #1ba94c !important;
}

/* React Bootstrap Tooltip */
.my-tooltip > .tooltip-inner {
  background-color: #1ba94c;
}

.bs-tooltip-auto[x-placement^='bottom'] .arrow::before,
.bs-tooltip-bottom .arrow::before {
  border-bottom-color: #1ba94c;
}

.bs-tooltip-auto[x-placement^='top'] .arrow::before,
.bs-tooltip-top .arrow::before {
  border-top-color: #1ba94c;
}

.bs-tooltip-auto[x-placement^='left'] .arrow::before,
.bs-tooltip-left .arrow::before {
  border-left-color: #1ba94c;
}

.bs-tooltip-auto[x-placement^='right'] .arrow::before,
.bs-tooltip-right .arrow::before {
  border-right-color: #1ba94c;
}

.fr-wrapper > div > a {
  display: none !important;
}
#edit_history_non_display_tooltip {
  display: none !important;
}

.tooltipp {
  position: relative;
  display: inline-block;
}

.tooltipp .tooltiptextt {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -60px;
}

.tooltipp .tooltiptextt::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  /* border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent; */
}
.highP::after {
  border-color: #eb3e3e transparent transparent transparent;
}
.urgentP::after {
  border-color: red transparent transparent transparent;
}
.mediumP::after {
  border-color: #ffa800 transparent transparent transparent;
}
.lowP::after {
  border-color: #1ba94c transparent transparent transparent;
}

.tooltipp:hover .tooltiptextt {
  visibility: visible;
}
.document_analytics_main_container .hidden_text {
  display: none;
}
.create_document_main_container .hidden_text {
  display: none;
}

#da_highlighted_text_1 {
  background-color: red !important;
  color: rebeccapurple;
}
/* .review_highlighted_text {
  cursor: pointer;
} */
/* .review_model_editor_wrapper .quill {
  height: 100%;
  min-height: 500px;
} */

/* react-router-transition */
.switch-wrapper {
  position: relative;
}

.switch-wrapper > div {
  width: 99vw;
  overflow-x: hidden;
  position: absolute;
}
.issues_addressed_tooltip {
  z-index: 100;
}
.issues_addressed_tooltip_arrow {
  width: 14px;
  height: 20px;
  background-color: #c4c4c4;
  position: absolute;
  left: -6px;
  transform: rotate(45deg);
}

.document_analytics_middle_part_scrolling::-webkit-scrollbar-thumb {
  background: #7a7a7a !important;
}

.edit_history_scroll_active::-webkit-scrollbar-thumb {
  background: #7a7a7a !important;
}

.document_analytics_citations_scrolling::-webkit-scrollbar-thumb {
  background: #7a7a7a !important;
}
.document_analytics_issue_address_scrolling::-webkit-scrollbar-thumb {
  background: #7a7a7a !important;
}
.document_analytics_center_docs::-webkit-scrollbar-thumb {
  background: #7a7a7a !important;
}
.document_analytics_comment_container::-webkit-scrollbar-thumb {
  background: #7a7a7a !important;
}
.document_analytics_edit_history_container::-webkit-scrollbar-thumb {
  background: #7a7a7a !important;
}
