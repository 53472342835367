.admin_view_options_card_container {
    width: 22%;
}

.admin_view_options_card_body {
    text-align: left;
    height: 200px;

}

.title {
    font-size: 20px;
    color: #11256D;
    font-weight: 500;
    margin-bottom: 16px;
}
.title i{
    font-size: 12px;
    margin-left: 2px;
}
.title_details{
    font-size: 12px;
    color: #144192;
    margin-bottom: 14px;
    font-weight: 500;
    cursor: pointer;
}
