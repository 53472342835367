.modal-content {
  border-radius: 0px;
}
.modal-header {
  border-bottom: 0px;
}
.modal-title {
  font-size: 24px;
  color: #11256d;
  margin-left: 15px;
}
/* *******************************upload_document_modal start***************************** */
.upload_document_modal {
  width: 53%;
  max-width: initial;
}
.upload_document_modal .modal-body {
  padding-bottom: 4em;
}

/* *******************************upload_document_modal end***************************** */

/* *******************************team_common_modal start***************************** */
.team_common_modal {
  width: 60%;
  max-width: initial;
}
.team_common_modal .modal-body {
  padding-bottom: 3em;
  padding-left: 3em;
  padding-right: 3em;
}
/* .team_common_modal .modal-title{
    font-size: 30px;
    margin-left: 50px;
} */

/* *******************************team_common_modal end***************************** */

/* *******************************edit_tag_modal start***************************** */
.edit_tag_modal {
  width: 70%;
  max-width: initial;
}
.edit_tag_modal .modal-body {
  padding: 0em 2em 2em 2em;
}
/* *******************************edit_tag_modal end***************************** */

/* *******************************edit_tag_modal start***************************** */
.advance_search_modal {
  width: 70%;
  max-width: initial;
}
.advance_search_modal .modal-body {
  padding: 0em 2em 2em 2em;
}

/* *******************************edit_tag_modal end***************************** */

.display_document_modal {
  width: 85%;
  max-width: initial;
  /* height: 85%; */
  /* overflow: auto !important; */
  /* height: auto !important; */
}
.display_document_modal .modal-body {
  padding: 0px 0px 50px 0px;
  /* height: 500px; */
  height: calc(100vh - 50px);
  overflow-y: auto;
}

.display_document_modal .modal-content {
  background-color: #dfe1e5;
}

/* *************************************display document end*************************************** */
.display_similar_doc_modal {
  width: 85%;
  max-width: initial;
}
.display_similar_doc_modal .modal-body {
  padding: 0px 0px 50px 0px;
  /* height: 500px; */
  height: calc(100vh - 50px);
  overflow-y: auto;
  /* width: 50%; */
  margin: auto;
}

.display_similar_doc_modal .modal-content {
  background-color: #dfe1e5;
}

/* *******************************data_source_common_modal start***************************** */
.data_source_common_modal {
  width: 1020px;
  max-width: initial;
}

.data_source_common_modal .modal-content {
  border-radius: 9px;
  padding: 10px 0px;
}

.data_source_common_modal .modal-header button {
  border-radius: 50%;
  background: #fafafa;
  width: 54px;
  height: 54px;
  opacity: 1;
  line-height: 0;
  margin-right: 15px;
  font-size: 2rem;
  font-weight: 300;
}

.source_file_progress_modal {
  width: 1020px;
  max-width: initial;
  /* background: #fafafa; */
}

.source_file_progress_modal .modal-header button {
  border-radius: 50%;
  background: #fafafa;
  width: 54px;
  height: 54px;
  opacity: 1;
  line-height: 0;
  margin-right: 15px;
  font-size: 2rem;
  font-weight: 300;
}

.source_file_progress_modal .modal-content {
  width: 1020px;
  max-width: initial;
  background: #fafafa;
  border-radius: 9px;
}

.source_file_progress_modal .modal-body {
  padding: 0;
}

.data_source_confirmation_modal {
  width: 540px;
  max-width: initial;
}

.source_file_folder_modal {
  width: 1020px;
  max-width: initial;
  /* background: #fafafa; */
}

.source_file_folder_modal .modal-header {
  align-items: center;
  padding: 30px 15px;
}

.source_file_folder_modal .modal-header button {
  border-radius: 50%;
  background: #fafafa;
  width: 54px;
  height: 54px;
  opacity: 1;
  line-height: 0;
  margin-right: 15px;
  font-size: 2rem;
  font-weight: 300;
}

.source_file_folder_modal .modal-body {
  padding: 0;
}

.source_file_folder_modal .modal-content {
  width: 1020px;
  max-width: initial;
  background: #fff;
  border-radius: 9px;
}

/* *******************************data_source_common_modal end***************************** */

/* ******************************* new_project_common_modal start***************************** */
.new_project_common_modal {
  width: 1020px;
  max-width: initial;
}
.new_project_common_modal .modal-content {
  width: 1020px;
  max-width: initial;
  /* background: #fafafa; */
  border-radius: 9px;
}
.new_project_common_modal .modal-header {
  display: flex;
  align-items: center;
}
.new_project_common_modal .modal-body {
  padding: 30px;
}
.new_project_common_modal .modal-header button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #fafafa;
  width: 52px;
  height: 52px;
  opacity: 1;
  line-height: 1.2;
  margin-right: 15px;
  font-size: 2rem;
  font-weight: 300;
  padding-top: 12px;
}
/* ******************************* new_project_common_modal end***************************** */

/* *******************************email_common_modal start***************************** */
.email_common_modal {
  width: 1020px;
  max-width: initial;
}
.email_common_modal .modal-content {
  width: 1020px;
  max-width: initial;
  /* background: #fafafa; */
  border-radius: 9px;
}
.email_common_modal .modal-header {
  display: flex;
  align-items: center;
  padding: 24px 30px;
  border-bottom: 1px solid #eaeaea;
}
.email_common_modal .modal-body {
  padding: 0 30px 30px 30px;
  height: 550px;
  overflow-y: auto;
}
.email_common_modal .modal-body::-webkit-scrollbar-thumb {
  background-color: #11256d;
}
.email_common_modal .modal-header .modal-title {
  width: 90%;
  margin: 0;
}
.email_common_modal .modal-header button {
  border-radius: 50%;
  background: #fafafa;
  width: 54px;
  height: 54px;
  opacity: 1;
  line-height: 0;
  margin-right: 15px;
  margin-left: 30px;
  font-size: 2rem;
  font-weight: 300;
}
.email_common_modal .modal-header > button > span {
  position: relative;
  top: -2px;
}
.email_common_modal .modal-header > button > span:last-child {
  display: none;
}
/* ******************************* email_common_modal end***************************** */

/* ******************************* upload_document_common_modal start***************************** */
.upload_document_common_modal {
  width: 1020px;
  max-width: initial;
}
.upload_document_common_modal .modal-content {
  width: 1020px;
  max-width: initial;
  /* background: #fafafa; */
  border-radius: 9px;
}
.upload_document_common_modal .modal-header {
  display: flex;
  align-items: center;
  padding: 24px 30px;
}
.upload_document_common_modal .modal-body {
  padding: 0 30px 30px 30px;
  height: 550px;
  overflow-y: auto;
}
.upload_document_common_modal .modal-body::-webkit-scrollbar-thumb {
  background-color: #11256d;
}
.upload_document_common_modal .modal-header .modal-title {
  width: 90%;
  margin: 0;
}
.upload_document_common_modal .modal-header button {
  border-radius: 50%;
  background: #fafafa;
  width: 54px;
  height: 54px;
  opacity: 1;
  line-height: 0;
  margin-right: 4px;
  margin-left: 30px;
  font-size: 2rem;
  font-weight: 300;
}
/* ******************************* upload_document_common_modal end***************************** */

.documents_analytics_similar_para_modal {
  width: 720px;
  max-width: unset;
}

.documents_analytics_similar_para_modal .modal-header {
  padding: 24px 24px 0 24px !important;
}

.documents_analytics_similar_para_modal .modal-title {
  margin: 0;
}

.documents_analytics_similar_para_modal .modal-body {
  padding: 24px;
  max-height: 70vh;
  overflow-y: scroll;
}
/* ******************************* account_settings_modal start ***************************** */
.account_settings_modal {
  width: 1020px;
  max-width: initial;
}
.account_settings_modal_blue_bg {
  background: rgba(17, 37, 109, 0.9);
  opacity: 1 !important;
}
.account_settings_modal .modal-content {
  width: 1020px;
  max-width: initial;
  /* background: #fafafa; */
  border-radius: 9px;
}

.account_settings_modal .modal-header {
  display: flex;
  align-items: center;
}
.account_settings_modal .modal-body {
  padding: 30px;
}
.account_settings_modal .modal-header button {
  border-radius: 50%;
  background: #fafafa;
  width: 54px;
  height: 54px;
  opacity: 1;
  line-height: 0;
  margin-right: 15px;
  font-size: 2rem;
  font-weight: 300;
}

/* ******************************* account_settings_modal end ***************************** */

/* ******************************* support_ticket_common_modal start***************************** */
.support_ticket_common_modal {
  width: 1020px;
  max-width: initial;
}
.support_ticket_common_modal .modal-title{
  color: #2C2C2C;
}
.support_ticket_common_modal .modal-content {
  width: 1020px;
  max-width: initial;
  /* background: #fafafa; */
  border-radius: 9px;
}
.support_ticket_common_modal .modal-header {
  display: flex;
  align-items: center;
}
.support_ticket_common_modal .modal-body {
  padding: 30px;
}
.support_ticket_common_modal .modal-header button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #fafafa;
  width: 52px;
  height: 52px;
  opacity: 1;
  line-height: 1.2;
  margin-right: 15px;
  font-size: 2rem;
  font-weight: 300;
  padding-top: 12px;
}
/* ******************************* support_ticket_common_modal end***************************** */



/* ******************************* delete_ticket_modal start***************************** */
.delete_ticket_modal {
  width: 1020px;
  max-width: initial;
}
.delete_ticket_modal .modal-title{
  color: #2C2C2C;
}
.delete_ticket_modal .modal-content {
  width: 1020px;
  max-width: initial;
  /* background: #fafafa; */
  border-radius: 9px;
}
.delete_ticket_modal .modal-header {
  display: flex;
  align-items: center;
}
.delete_ticket_modal .modal-body {
  padding: 30px;
}
.delete_ticket_modal .modal-header button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #fafafa;
  width: 52px;
  height: 52px;
  opacity: 1;
  line-height: 1.2;
  margin-right: 15px;
  font-size: 2rem;
  font-weight: 300;
  padding-top: 12px;
}
/* ******************************* delete_ticket_modal end***************************** */