.doc_preview_title{
    border-bottom: 1px solid #BDBEC5;
    padding: 12px 20px;
    font-weight: bold;
    font-size: 12px;
    text-align: center;
}
.doc_preview_container{
    height: 80vh;
    background-color: #ffffff;
     overflow: auto;
}
.doc_preview_form_container{
    padding: 0px 20px;

}
.doc_preview_two_column_fields{
    display: flex;
     justify-content: space-between;
}
.doc_preview_two_column_fields_width{
    width: 48%;
}
.doc_type_radios label{
    font-size: 12px;
    margin-left: 8px;
}
.doc_preview_last_field_bottom_spacing{
    margin-bottom: 20px;

}
.doc_prevew_form_submit_container{
    background: #ffffff;
}
.doc_prevew_form_submit_section{
    margin: 4px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 11vh;
    background: #ffffff;
}
.btn_gap{
    margin-right: 20px;
}
.small_font{
    font-size: 12px;
}
input[type="date"]::-webkit-calendar-picker-indicator {
   cursor: pointer;
}
.doc_preview_text_field {   
    /* font-size: 12px;
    padding: 8px 14px; */
}
.calander{
    padding: 17.5px 6px;
}