.all_documents_list_table {
    width: 100%;
    
}
.all_documents_list_row_label th label {
    font-size: 12px;
    color: #bdbec5;
    margin-right: 8px;
    cursor: pointer;
    position: relative;
}

.all_documents_list_row_label i{
    position: absolute;
}
.all_documents_list_container {
    margin-left: 24px;
    overflow: auto;
    height: calc(100vh - 300px);
}
.all_documents_list_container table {
    border-collapse: separate;
    border-spacing: 0 1.2em;
}
.all_documents_list_row_content {
    cursor: pointer;
}

.all_documents_list_row_content td span{
    font-size: 12px;
    color: #3B4856;
}
.doc_name{
    /* font-size: 14px !important; */
    color: #3B4856;
    font-weight: 500;
}
.doc_status{
    font-size: 11px !important;
    color: #11256D;
    font-weight: 500;
    background-color: #CCD5F7;
    padding: 7px 26px;
    border-radius: 20px;
}
.doc_version{
    /* text-decoration: underline; */
    color: #11256D;
    font-weight: 500;
}

.doc_version u {
    text-decoration: none;
    border-bottom: 1.5px solid #11256D;
}
.action_popup{
    position: absolute;
    width: 140px;
    height: 80px;
    box-shadow: 0px 3px 6px #00000029;
    right: 5px;
    background: #ffffff;
    border-radius: 8px;
    top: 20;
    padding: 12px;
    bottom: -30px;
    z-index: 100;
}
.actionText{
    font-size: 12px;
    color: #3B4856;
}