/*********** Button Styling *************/
.btn_ripple {
  display: inline-block;
  position: relative;
  overflow: hidden;
  transition: all ease-in-out 0.5s;
}

.btn_ripple::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 25%;
  height: 100%;
  width: 50%;
  background-color: #000;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  transition: all ease-in-out 1s;
  transform: scale(5, 5);
}

.btn_ripple:active::after {
  padding: 0;
  margin: 0;
  opacity: 0.2;
  transition: 0s;
  transform: scale(0, 0);
}
/*********** Button Styling End *************/

/* ************************* Bootstrap no padding no margin styling starts ***************************/

.no_gutter {
  margin-left: 0;
  margin-right: 0;
}

.no_gutter > [class^='col-'] {
  padding-left: 0;
  padding-right: 0;
}
/* ************************* Bootstrap no padding no margin styling end ***************************/

/* ************************* Home page common styling starts ***************************/
.home_top_action_btn {
  font-size: 10px;
  color: #144192;
  font-weight: 600;
  font-size: 12px;
  box-shadow: none !important;
  border: 1.5px solid #144192;
  border-radius: 4px;
  margin-left: 8px;
  padding-left: 20px;
  padding-right: 20px;
}

.home_top_action_btn:hover {
  color: #144192 !important;
}
.home_top_container {
  margin-top: 12px;
  margin-right: 16px;
}
.home_top_section {
  float: right;
}
.home_top_text {
  font-size: 12px;
  color: #144192;
  font-weight: 500;
}

/* ************************* Home page common styling end ***************************/

/* ************************* Form common styling starts ***************************/
.common_form_text_field {
  width: 100%;
  border: 1.5px solid lightgray;
  border-radius: 6px;
  /* padding: 7px 12px; */
  /* margin-bottom: 12px; */

  font-size: 12px;
  padding: 18px 14px;
}
.home_form_section {
  width: 55%;
}
.common_form_label {
  font-size: 0.6875em;
  margin-bottom: 8px;
  margin-top: 16px;
  font-weight: 500;
  color: #bdbec5;
}
.common_submit_container {
  text-align: center;
}

.common_light_btn {
  background-color: #ffffff;
  font-size: 10px;
  color: #144192;
  font-weight: 600;
  font-size: 12px;
  box-shadow: none !important;
  border-radius: 4px;
  width: 160px;
  padding-top: 12px;
  padding-bottom: 12px;
  border: 1.5px solid #144192;
}
.common_light_btn:hover {
  color: #144192 !important;
}
.common_submit_btn {
  background-color: #144192;
  font-size: 10px;
  color: #ffffff;
  font-weight: 600;
  font-size: 12px;
  box-shadow: none !important;
  border-radius: 4px;
  width: 160px;
  padding-top: 12px;
  padding-bottom: 12px;
}
.common_submit_btn:hover {
  color: #ffffff !important;
}
/************************** Form common styling end ***************************/

/**************************common css for screens starts**************************/

.commom_wrapper {
  padding-left: 110px;
  /* padding-top: 95px; */
  padding-right: 50px;
  padding-top: 130px;
}
.commom_data_catalog_wrapper {
  padding-left: 100px;
  /* padding-top: 95px; */
  /* padding-right: 30px; */
  padding-right: 50px;
  padding-top: 125px;
  padding-bottom: 45px;
  background-color: #fff;
}
.common_workflow_management_wrapper {
  padding-left: 110px;
  padding-top: 130px;
  padding-right: 60px;
}
.dashboard_v2_wrapper {
  padding-left: 73px;
  padding-top: 78px;
  padding-right: 30px;
  padding-bottom: 45px;
  background-color: #fff;
}
.commom_title {
  /* font-size: 1.8em; */
  font-size: 24px;
  font-weight: bold;
  color: #144192;
}
.commom_subtitle {
  color: #144192;
  font-size: 0.7em;
  font-weight: 500;
  line-height: 0.9;
}
.common_header_margin {
  margin-top: 62px;
}

/* *************************common css for screens end************************* */

/* ******************************common card starts****************************************** */
.card_layout {
  filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.129));
  border-radius: 8px;
}

/* ******************************common card end****************************************** */

/*******************************************************************************************/
/* ****************************** V2 ****************************************************** */
.v2_card_layout {
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(122, 122, 122, 0.15);
  border-radius: 6px;
}
