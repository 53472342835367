.dropdown {
  display: flex;
  align-items: center;
  width: 100px;
  height: 26px;
  padding: 5px;
  background-color: #e5e5e5;
  font-weight: 400;
  font-size: 18px;
  color: #7a7a7a;
  border: none;
  cursor: pointer;
}
.icon {
  width: fit-content;
  margin-left: auto;
  align-self: center;
}
