.data_catalog_all_folders_table {
  width: 100%;
}

.data_catalog_all_folders_table th {
  border-bottom: 1px solid #eaeaea;
  text-align: left;
  padding: 16px 10px;
  background-color: #fafafa !important;
  color: #7a7a7a;
  font-weight: 400;
  font-size: 18px;
}
.data_catalog_all_folders_table td {
  padding: 16px 10px;
  border-bottom: 1px solid #eaeaea;
}
.all_folders_row_label {
  padding: 0px;
}
.folder_name {
  color: #2c2c2c;
  font-weight: 400;
  font-size: 18px;
}
.all_folder_tree_table_text {
  font-size: 18px;
  font-weight: 400;
}

.folder_name i {
  margin-right: 8px;
}
.arrow_up {
  width: 21px;
  margin-left: 20px;
  margin-bottom: 5px;
  cursor: pointer;
  transition: all 0.4s ease;
}

.arrow_down {
  transform: rotateZ(180deg);
}

.view_all_folders {
  color: #7a7a7a;
  margin: 10px 0px;
  cursor: pointer;
}
.grey_folder {
  width: 21px;
  margin-right: 16px;
}
.grey_document {
  width: 16px;
  margin-right: 16px;
}
.confidence_score {
  width: 16px;
  height: 16px;
  background: #00cc66;
  display: inline-block;
  border-radius: 50%;
  line-height: 1;
  margin-right: 10px;
}
.modified {
  color: #9a9a9a;
  font-size: 18px;
  font-weight: 400;
}
.number {
  color: #9a9a9a;
}
.files_list_file {
  cursor: pointer;
}
