.root {
  /* width: 1020px; */
  /* padding: 30px; */
  display: flex;
  flex-direction: column;
}
.label {
  font-weight: 400;
  font-size: 15px;
  color: #2c2c2c;
  margin-bottom: 2px;
  /* margin-top: 24px; */
}
.role_label {
  margin-left: 15px;
}
.date_label {
  margin-left: 22px;
}
.header {
  /* display: flex;
  margin-bottom: 30px; */
}
.header input {
  outline: 0px !important;
  -webkit-appearance: none;
  font-weight: 500;
  font-size: 24px;
  border: none;
  color: #000;
}
.cross_btn {
  margin-left: auto;
}

.section_client_name {
  display: flex;
}
.client_name {
  margin-right: 30px;
  margin-bottom: 24px;
}
.client_name input {
  width: 465px;
  height: 51px;
  padding: 15px 24px;
  background-color: #fafafa;
  border-radius: 4px;
  color: #000;
  font-size: 18px;
  border: none;
}
.accessibility {
  flex-grow: 1;
}

.section_address {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}
.section_address input {
  width: 100%;
  padding: 15px 24px;
  background: #fafafa;
  color: #000;
  font-size: 18px;
  border-radius: 4px;
  border: none;
  line-height: 1.2;
}

.section_type_of_project {
  display: flex;
  margin-bottom: 24px;
}
.type_of_project {
  width: 50%;
  margin-right: 30px;
}
.type_of_project > div:last-child {
  width: 100%;
}
.bill_container {
  display: flex;
  width: 50%;
}
.bill_type {
  width: 50%;
  margin-right: 30px;
}
.bill {
  width: 50%;
}
.bill > div:last-child {
  display: flex;
}
.bill input {
  height: 51px;
  width: 70%;
  padding: 15px 24px;
  background: #fafafa;
  color: #000;
  font-size: 18px;
  border-radius: 4px;
  border: none;
}
.bill_field_text {
  height: 51px;
  width: 30%;
  padding: 15px 24px;
  background: #fafafa;
  color: #c4c4c4;
  font-size: 18px;
  border-radius: 4px;
  border: none;
}

.section_date {
  display: flex;
  padding-bottom: 45px;
  border-bottom: 1px solid #eaeaea;
}
.start_date {
  width: 50%;
  margin-right: 30px;
}
.start_date > input {
  height: 51px;
  width: 100%;
  padding: 15px 24px;
  background: #fafafa;
  color: #c4c4c4;
  font-size: 18px;
  border-radius: 4px;
  border: none;
}
.end_date {
  width: 50%;
}

.end_date > input {
  height: 51px;
  width: 100%;
  padding: 15px 24px;
  background: #fafafa;
  color: #c4c4c4;
  font-size: 18px;
  border-radius: 4px;
  border: none;
}

.section_project_lead {
  margin-top: 20px;
  margin-bottom: 24px;
}
.section_project_lead input {
  height: 51px;
  width: 50%;
  padding: 15px 24px;
  background: #fafafa;
  color: #c4c4c4;
  font-size: 18px;
  border-radius: 4px;
  border: none;
}

.section_team_member {
  display: flex;
  /* margin-bottom: 6px; */
}
.team_member {
  display: flex;
  width: 50%;
  flex-direction: column;
}
.team_member {
  /* border-right: 1px solid #eaeaea; */
  margin-bottom: 9px;
}
.add_member_container {
  display: flex;
  align-items: center;
  width: 50%;
  height: 51px;
  width: 24%;
  padding: 15px 24px;
  background: #fafafa;
  color: #c4c4c4;
  font-size: 18px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
}
.add_member_container img {
  width: 30px;
  height: 30px;
  margin-left: auto;
}

.role {
  width: 50%;
}

.section_task {
  display: flex;
  /* padding-bottom: 45px; */
  /* padding-top: 15px; */
  /* border-bottom: 1px solid #eaeaea; */
  margin-top: 16px;
}
.task {
  display: flex;
  flex-direction: column;
  width: 25%;
}
.task > input {
  height: 51px;
  padding: 15px 24px;
  background: #fafafa;
  color: #000;
  font-size: 18px;
  border-radius: 4px;
  border: none;
  margin-bottom: 9px;
  border-right: 1px solid #eaeaea;
}

.task > div:last-child {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 51px;
  padding: 15px 15px 15px 24px;
  background: #fafafa;
  color: #000;
  font-size: 18px;
  border-radius: 4px;
  border: none;
  margin-bottom: 9px;
}
.task > div:last-child img {
  width: 30px;
  height: 30px;
  margin-right: auto;
}
.task > div:last-child input {
  width: 90%;
  background: #fafafa;
  color: #000;
  font-size: 18px;
  border: none;
}
.task > input:first-child {
  border-right: 1px solid #eaeaea;
}
.task input:last-child {
  width: 25%;
}

.date {
  display: flex;
  width: 45%;
  margin: 0 5px;
  /* border: 1px solid red; */
}
.date > div:first-child {
  width: 50%;
}
.date > div:first-child > input {
  width: 100%;
}
.date > div:last-child {
  width: 50%;
}
.date > div:last-child > input {
  width: 100%;
}

.date > div > input {
  height: 51px;
  padding: 15px 24px;
  background: #fafafa;
  color: #c4c4c4;
  font-size: 18px;
  border-radius: 4px;
  border: none;
  margin-bottom: 9px;
}
.date > div:first-child > input {
  border-right: 1px solid #eaeaea;
  margin-right: 1px;
}
.status {
  display: flex;
  width: 25%;
}

.section_btn {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 45px;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.2;
}
.upload_doc {
  display: flex;
  color: #fff;
  width: 465px;
  padding: 13px 22px;
  background-color: #7a7a7a;
  border-radius: 4px;
  cursor: pointer;
}
.upload_doc img {
  margin-left: auto;
}

.create_project {
  color: #fff;
  background-color: #11256d;
  margin-left: auto;
  padding: 13px 22px;
  border-radius: 4px;
  cursor: pointer;
  width: 222px;
  text-align: center;
  font-size: 18px;
  line-height: 1.4;
}

/* .add_task_section {
  margin-top: 9px;
  background: #fafafa;
} */

.add_task_section {
  display: flex;
  align-items: center;
  width: 50%;
  height: 51px;
  width: 24%;
  padding: 15px 24px;
  background: #fafafa;
  color: #c4c4c4;
  font-size: 18px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
}
.add_task_section img {
  width: 30px;
  height: 30px;
  margin-left: auto;
}

.upload_files_list {
  display: flex;
  align-items: center;
  margin: 4px 0px;
}
.project_uploaded_files {
  font-size: 16px;
  font-weight: 500;
}
.switch_container {
  margin-left: auto;
}

/****************** Toggle Switch *********************/

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 35px;
  height: 19px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.switch {
  margin-right: 10px;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #eaeaea;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: #9a9a9a;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #eaeaea;
}

input:focus + .slider {
  box-shadow: 0 0 1px #eaeaea;
}

input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
  background-color: #11256d;
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.loader_wrapper {
  position: absolute;
  width: 100%;
}

.project_modal_bottom_divider {
  border-bottom: 1px solid #eaeaea;
  margin-top: 40px;
}
.project_modal_bottom_content {
  display: flex;
  align-items: baseline;
}