.screen {
  background-color: #f5f7fd;
  margin-top: 150px;
  font-family: "Roboto", sans-serif;
}

.success_section {
  display: flex;
  justify-content: center;
  color: #144192;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
}

.success_heading {
  font-size: 46px;
  opacity: 1;
}

.btn {
  text-align: center;
  border: 1px solid #144192;
  color: #144192;
  font-size: 12px;
}

.file {
  font-size: 11px !important;
  color: #11256d;
  font-weight: 500;
  background-color: #ccd5f7;
  padding: 7px 26px;
  border-radius: 20px;
}
