.workflowChatContainer .form {
    display: flex;
    border: 1px solid #EAEAEA;
    border-radius: 4px;
}

.workflowChatContainer .input {
    padding: 3%;
    width: 80%;
    font-size: 1.2em;
    background-color: #fafafa;
    border: none;
    border-right: none;
    border-radius: 4px;

}

.workflowChatContainer input:focus, textarea:focus, select:focus{
    outline: none;
}

.workflowChatContainer .sendButton {
    color: #fff;
    text-decoration: none;
    background: #11256D;;
    /* padding: 20px; */
    display: inline-block;
    border: none;
    width: 24%;
    margin: 2%;
    border-radius: 4px;
    font-size: 15px;
}