.root {
  min-height: 100vh;
  background-color: #eaeaea;
}
.wrapper {
  display: flex;
  padding: 40px;
}

.left_section {
  display: flex;
  align-items: center;
  width: 30%;
  /* background-color: red; */
}
.left_section_wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 51px;
}

.middle_section {
  position: relative;
  /* width: 70%; */
  /* height: 50px; */
  /* border-left: 4px solid #1ba94c; */
  /* background-color: blue; */
}

.right_section {
  width: 30%;
  height: 50px;
  /* background-color: green; */
}

.middle_cards {
  display: flex;
  align-items: flex-start;
  padding-bottom: 9px;
  border-left: 2px solid #1ba94c;
}
.add_middle_cards {
  position: relative;
  border-left: 8px solid #eaeaea;
  left: -6px;
  top: 1px;
}
.main_issue_cards_container {
  display: flex;
  align-items: center;
}
.border_remover_container {
  position: relative;
  left: -3px;
  width: 3px;
  align-self: flex-start;
  background-color: #eaeaea;
}
.border_remover_container_opposite {
  position: relative;
  left: -3px;
  width: 3px;
  align-self: flex-end;
  background-color: #eaeaea;
}

.main_issue_card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 248px;
  min-height: 42px;
  background: #ffffff;
  position: relative;
  z-index: 1;
  padding: 12px 12px;
  box-shadow: 0 0 8px hsl(0deg 0% 48% / 15%);
  border-radius: 4px;
  cursor: pointer;
}
.add_main_issue_card {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 248px;
  min-height: 42px;
  padding: 0px 15px;
  background-color: #eaeaea;
  border: 2px dashed #c4c4c4;
  box-sizing: border-box;
  border-radius: 4px;
  color: #c4c4c4;
  cursor: pointer;
}
.main_issue_card > input {
  outline: 0px;
  border: 0px;
  color: #2c2c2c;
}
.input_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.input_container > input {
  width: 90%;
  outline: 0px;
  border: 0px;
  color: #2c2c2c;
}
.metadataContainer {
  display: flex;
  flex-direction: column;
  margin-top: 9px;
  font-size: 9px;
  color: #9a9a9a;
}
.avatars {
  display: inline-flex;
  padding: 2px;
}

.avatar {
  position: relative;
  width: 24px;
  height: 24px;
  border: 1px solid #fff;
  border-radius: 50%;
  overflow: hidden;
}

.avatar:not(:last-child) {
  margin-right: -8px;
}

.avatar img {
  width: 24px;
  height: 24px;
}
.info_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sub_issue_card_container {
  position: relative;
  display: flex;
  width: 320px;
  flex-direction: column;
  border-radius: 4px 4px 0 0;
}
.sub_issue_card_container_wrapper {
  width: 320px;
  margin-bottom: 9px;
  background: #eaeaea;
  box-shadow: 0px 0px 4px rgba(122, 122, 122, 0.15);
  border-radius: 4px;
}
.sub_issue_card_container_wrapper_1 {
  padding: 0 15px;
  background-color: white;
  border-radius: 4px;
}
.sub_issue_card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 42px;
  /* background: #ffffff;
  position: relative;
  z-index: 1;
  box-shadow: 0 0 8px hsl(0deg 0% 48% / 15%); */
  /* border-left: 4px solid #1ba94c; */
  /* border-radius: 4px 0px 0px 4px; */
  border-bottom: 1px solid #eaeaea;
  cursor: pointer;
}
.subissue_input_container {
  display: flex;
  justify-content: space-between;
  width: 90%;
  flex-direction: column;
}
.sub_issue_card:last-child {
  border: none;
}
.subissue_input_container > input {
  outline: 0px;
  border: 0px;
  font-size: 12px;
  font-weight: 700;
  color: #2c2c2c;
  margin-top: 9px;
  margin-bottom: 4px;
}
.subissue_input_container > textarea {
  outline: 0px;
  border: 0px;
  font-size: 12px;
  font-weight: 300;
  color: #9a9a9a;
  resize: none;
  overflow: hidden;
  min-height: 50px;
  max-height: 100px;
  margin-bottom: 9px;
}
.subissue_pencil_icon {
  align-self: flex-start;
}
.add_sub_issue_card {
  display: flex;
  align-items: center;
  width: 320px;
  height: 42px;
  padding-left: 15px;
  background: #eaeaea;
  color: #c4c4c4;
  border: 2px dashed #c4c4c4;
  /* position: relative;
  z-index: 1;
  box-shadow: 0 0 8px hsl(0deg 0% 48% / 15%); */
  /* border-left: 4px solid #1ba94c; */
  border-radius: 4px;
  cursor: pointer;
}
.line {
  width: calc(100% - 248px);
  border-bottom: 1px solid #1ba94c;
  /* position: relative;
  bottom: 30px;
  z-index: 0; */
}

.middle_line {
  border-bottom: 1px solid #9a9a9a;
  z-index: 0;
  width: 169px;
}

.middle_line1 {
  border-bottom: 1px solid #9a9a9a;
  z-index: 0;
  width: 169px;
}
