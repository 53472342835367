.dropdown {
  display: flex;
  align-items: center;
  width: 100px;
  height: 26px;
  padding: 5px;
  background-color: #e5e5e5;
  font-weight: 400;
  font-size: 18px;
  color: #7a7a7a;
  border: none;
  cursor: pointer;
}
.common_dropdown_style {
  border: none;
}
.icon {
  width: fit-content;
  margin-left: auto;
  align-self: center;
}

.common_dropdown_style {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='grey' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 12px;
  padding-right: 10px;
  cursor: pointer;
}