
.login_form_container{
    margin-top: 160px;
    margin-left: 140px;
}
.login_main_text{
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 20px;
    width: 486px;
    text-align: center;
    font-weight: bold;
    color: #2C2C2C;

}

.login_form_password_section{
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
    width: 486px;
}
.login_form_password_text{
    font-size: 12px;
    color: #144192;
    margin-bottom: 0px;
    /* margin-top:14px; */
    cursor: pointer;
    font-weight: 600;
}
.login_form_submit_container {
    text-align: center;
    margin-top: 20px;
    width: 486px;
}
.login_form_captcha_container{
    margin:20px 0px ;
    display: flex;
    width: 500px;
    justify-content: center;
}
.login_form_password_field{
    position: relative;
    width: 486px;
}
.login_form_password_eye{
    position: absolute;
    top: 8px;
    bottom: 0;
    right: 8px;
    cursor: pointer;

}

.login_label{
    font-size: 15px;
    color: #2C2C2C;
    margin-bottom: 8px;
}

.login_form_text_field{
    width: 486px;
    background-color: #eaeaea;
    height: 50px;
    border-radius: 4px;
}
.login_form_button{
    width: 486px;
}

