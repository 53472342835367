.related_searches__root {
  flex-grow: 1;

}

.related_searches__wrapper {
  margin-top: 24px;
  border-bottom: 1px solid #eaeaea;
}
.related_searches__container {
  display: flex;
  background-color: #fafafa;
  margin-bottom: 24px;
}

.related_searches__left_container {
  width: 65%;
  flex: 1 0 60%;
  padding-right: 17%;
}

.related_searches__right_container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}

.related_searches__share_link {
  position: relative;
  text-align: end;
  margin-bottom: 13px;
}

/* For Body */
.body__root {
  width: 100%;
}

.body__heading {
  font-weight: 400;
  font-size: 15px;
  color: #00cc66;
}

.body__subheading {
  max-width: 550px;
  font-weight: 400;
  font-size: 18px;
  color: #2c2c2c;
  margin-bottom: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.body__description {
  max-width: 500px;
  font-weight: 400;
  font-size: 15px;
  color: #9a9a9a;
  margin-bottom: 9px;

  /* restricting text to 2 lines */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.body__date {
  font-size: 9px;
  font-weight: 400;
  color: #9a9a9a;
}

/* For Graphic Container */
.graphic_container__root {
  display: flex;
  flex-direction: column;
  width: 249px;
  height: fit-content;
}

.graphic_container__confidence {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 27px;
  background-color: white;
  border-radius: 4px;
  color: #9a9a9a;
  font-weight: 400;
  font-size: 9px;
  padding: 6px;
}

.confidence_container {
  background-color: rgb(192, 192, 192);
  border-radius: 15px;
}
.confidence {
  background-color: #00cc66;
  height: 2px;
  border-radius: 15px;
}

.confidence_value_container {
  display: flex;
  color: #9a9a9a;
}
.confidence_value {
  margin-left: auto;
}

.graphic_container__results_and_sources {
  display: flex;
  flex-grow: 1;
  width: 100%;
}

.graphic_container__results {
  height: fit-content;
  flex-grow: 1;
  margin: 4px 2px 0 0;
  background-color: white;
  border-radius: 4px;
  padding: 6px;
}

.graphic_container__sources {
  height: fit-content;
  flex-grow: 1;
  margin: 4px 0 0 2px;
  background-color: white;
  border-radius: 4px;
  padding: 6px;
}

/* For Graphic */
.graphic__root {
  flex-grow: 1;
  display: flex;
  align-items: center;
  padding: 1px;
  font-weight: 400;
}

.graphic__title {
  height: fit-content;
  font-size: 9px;
  color: #9a9a9a;
}

.graphic__count {
  font-size: 15px;
  color: #9a9a9a;
  height: fit-content;
}

.graphic__icon_container {
  margin: 0 10px 0 2px;
  background: #f7f7f7;
  border-radius: 4px;
}

.graphic__icon {
  width: 30px;
  height: 30px;
  padding: 5px;
}
