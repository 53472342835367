.root {
  background-color: #fafafa;
  padding-left: 140px;
  height: 100vh;
}
.relative {
  position: relative;
}
.back_icon {
  position: absolute;
  left: -50px;
  top: 8px;
  cursor: pointer;
}
.markAllAsReadImg {
  cursor: pointer;
}
.header {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: 500;
  color: #c4c4c4;
  margin-bottom: 30px;
}
.header > img {
  margin-left: auto;
}
.main_card {
  background-color: #fff;
  border-radius: 4px;
  padding: 15px;
}
.notification_card {
  display: flex;
  align-items: center;
  padding: 30px;
  cursor: pointer;
}
.notification_card > img {
  margin-right: 30px;
}
.content {
  flex-grow: 1;
}
.heading {
  font-size: 18px;
  font-weight: 700;
}
.subheading {
  font-size: 14px;
}
.assignee_name,
.file_name,
.event {
  font-weight: 700;
}
.time_period {
  font-size: 12px;
  color: #c4c4c4;
}
.three_dots_icon {
  position: relative;
  margin-right: auto;
  cursor: pointer;
}
.options_panel {
  position: absolute;
  right: 0;
  width: 327px;
  padding: 0 24px;
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(122, 122, 122, 0.15);
  border-radius: 6px;
  color: #7a7a7a;
}
.options_panel > div {
  padding: 10px 0;
}
.options_panel > div:first-child {
  border-bottom: 1px solid #eaeaea;
}
.notification_tooltip {
  width: 327px;
  padding: 12px 24px;
  background-color: #fff;
  box-shadow: 0px 0px 8px rgba(122, 122, 122, 0.15);
  border-radius: 6px;
  color: #7a7a7a;
}
.notification_tooltip > div {
  border-bottom: 1px solid #eaeaea;
}
