*:focus {
  outline: none;
}

body {
  font-family: 'Roboto', sans-serif !important;
  background-color: #fff !important;
}

.password_strength_progress_container .progress {
  border-radius: 0.5rem !important;
  height: 0.8rem;
}
.password_strength_progress_container .progress-bar {
  background-color: #144192 !important;
  border-radius: 8px;
}

/* -----------------------react-file-viewer start----------------- */
.pg-viewer-wrapper {
  overflow-y: inherit !important;
}
.document_render_container .pg-viewer-wrapper .pg-viewer {
  margin-top: 82px;
}

.pg-viewer .loading-container {
  display: none !important;
}
.react-pdf__Page__canvas {
  margin: auto;
  /* margin-top: 40px; */
}

/* -----------------------react-file-viewer end----------------- */

/* ****************************custom scrollbar start******************************************* */

::-webkit-scrollbar {
  background-color: #fff;
  width: 12px;
}
::-webkit-scrollbar-track {
  background-color: #fff;
}
::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  border-radius: 16px;
  border: 4px solid #fff;
}
::-webkit-scrollbar-button {
  display: none;
}

/* ****************************custom scrollbar end******************************************* */

/* ****************************React Bootstrap nav tabs start***************************************** */
.nav-tabs .nav-link.active {
  background-color: #fff;
  border: none !important;
  border-bottom: 1px solid #dee2e6 !important;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #000000;
  font-weight: 600;
}
.nav-tabs .nav-link:focus {
  outline: none;
  border: none;
}
.nav-tabs .nav-link {
  border: none;
  /* border-bottom: 1px solid #dee2e6 !important; */
  padding-bottom: 18px;
  color: grey;
}
.nav-tabs {
  font-size: 12px;
}

/* ****************************React Bootstrap nav tabs end***************************************** */

/* ****************************clustered paragraphs details tab start***************************************************************************** */
.tab_with_bottom_border .nav-tabs {
  justify-content: space-around;
}
.tab_with_bottom_border .nav-tabs .nav-link {
  padding-bottom: 18px;
  padding-top: 18px;
  font-size: 12px;
}

.tab_with_bottom_border .nav-tabs .nav-link.active {
  border-bottom: 2px solid !important;
}

/* ******************************clustered paragraphs details end************************************************************************************ */

/* ******************************Highlighter starts************************************************************************************ */
.YourHighlightClass {
  padding: 0px;
  background-color: green;
}
.HighlightClass {
  white-space: pre-line;
}
/* ******************************Highlighter end************************************************************************************ */

/* ******************************React tag input starts************************************************************************************ */

.selectedClass {
  width: 100%;
  word-break: break-word;
}

.tagClass {
  background: lightgrey;
  padding: 4px;
  word-break: break-word;
  display: inline-block;
  margin: 4px;
  border-radius: 2px;
  font-weight: 500;
}
.tagClass a {
  padding: 4px;
}
.tagInputClass {
  margin-top: 8px;
  padding: 4px;
  border-radius: 4px;
  width: 100%;
}
.tagInputClass {
  padding: 0px;
}
.tagInputFieldClass {
  border-radius: 4px;
  width: 100%;
  padding: 7px 12px;
  border: 1.5px solid lightgray !important;
}
/* **********************************react-spinners start******************************************************* */
.css-wo6vlw {
  z-index: 5000;
}
/* works in dev mode(to place loadinger bubble below text in upload doc modal) */
.upload_doc_container .Loader_outer__15PCk {
  position: initial !important;
}
/* works in prod mode */
.upload_doc_container .Loader_outer__3WVkw {
  position: initial !important;
}

/* **********************************react-spinners end******************************************************* */
.reports_graph_container .chartjs-render-monitor {
  padding-bottom: 50px;
}

/* **********************************react toastify start******************************************************* */
.Toastify__toast {
  font-family: 'Roboto', sans-serif !important;
}
/* **********************************react toastify end******************************************************* */

/* ******************************React tag input end************************************************************************************ */

/* ***********************************React multi select start****************************************************** */
.css-2b097c-container {
  font-size: 12px;
}

/* **************************************React multi select end********************************************************* */
/* .css-yk16xz-control{
    max-height: 5px !important;
    min-height: 32px !important;
 }
 .css-1hb7zxy-IndicatorsContainer{
     display: none !important;
 } */

/* .tab_with_bottom_border .nav-tabs .nav-link{
     padding: 18px 4px;
 } */

.tagInputFieldClass {
  font-size: 12px;
  padding: 9px 14px;
}
/* ***************************************Modal start************************************************************** */
/* to avoid modal flickering when clicked  outside  */
.fade {
  transition: none !important;
}

/* ******************************************Modal end**************************************************************** */

/* *************************************RANDOM CSS STYLES**************************************************** */

.document_type_radio_group .form-check {
  padding-bottom: 6px;
  font-size: 13px;
}

input[type='date']::-webkit-calendar-picker-indicator {
  opacity: 1;
  display: block;
  /* background: url(https://mywildalberta.ca/images/GFX-MWA-Parks-Reservations.png) no-repeat; */
  height: 20px;
  border-width: thin;
}
.fa-spin {
  margin-right: 8px;
}

.second-toolbar {
  display: none !important;
}
#moreRich-1 {
  display: none !important;
}

/* ****************** React Pagination ********************* */
/* In RelatedSearchesPage */
.react-pagination .pagination {
  width: 35%;
  justify-content: space-between;
  margin: 30px 0;
}

.react-pagination .pagination li {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  color: #9a9a9a;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
}

.react-pagination .pagination li:focus {
  outline: none;
}

.react-pagination .pagination li.active {
  background-color: #11256d;
  color: white;
}

.react-pagination .pagination li.previous,
li.next {
  border: none;
}

/* In DataCatalog2  */
.recent-documents-react-pagination .pagination {
  margin: 0;
  margin-right: 8px;
}
.recent-documents-react-pagination .pagination li {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  margin-right: 10px;
  border-radius: 50%;
  color: #9a9a9a;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
}
.recent-documents-react-pagination .pagination li.next {
  display: none;
}
.recent-documents-react-pagination .pagination li:focus {
  outline: none;
}

.recent-documents-react-pagination .pagination li.active {
  background-color: #11256d;
  color: white;
}

.dashboard-v2-react-pagination .pagination li.previous,
li.next {
  border: none;
}

/* Dashboard v2 */
/* In DataCatalog2  */
.dashboard-v2-react-pagination .pagination {
  margin: 0;
  margin-right: 8px;
}
.dashboard-v2-react-pagination .pagination li {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  margin-right: 10px;
  border-radius: 50%;
  color: #9a9a9a;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
}
.dashboard-v2-react-pagination .pagination li.next {
  display: none;
}
.dashboard-v2-react-pagination .pagination li:focus {
  outline: none;
}

.dashboard-v2-react-pagination .pagination li.active {
  background-color: #11256d;
  color: white;
}

.dashboard-v2-react-pagination .pagination li.previous,
li.next {
  border: none;
}

/* React pagination generalized*/
.pagination_wrapper .pagination {
  width: 100%;
  justify-content: space-between;
}

.pagination_wrapper .pagination li {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  color: #9a9a9a;
  text-align: center;
  font-size: 0.66em;
  font-weight: 400;
}

.pagination_wrapper .pagination li:focus {
  outline: none;
}

.pagination_wrapper .pagination li.active {
  background-color: #11256d;
  color: white;
}

.pagination_wrapper .pagination li.previous,
li.next {
  border: none;
}

/* **************** DataInstances Tabs ******************/
.data_instance_root .nav-tabs {
  border: none;
}
.data_instance_root .nav-tabs .nav-link {
  background-color: #eaeaea;
  margin-right: 9px;
  border-radius: 4px;
  padding: 0;
  color: #7a7a7a;
}

.data_instance_root .nav-tabs .nav-link.active {
  background-color: #11256d;
  color: #ffffff;
}

/* **************** DataCatalog Tabs ******************/
.data_catalog_tabs .nav-tabs {
  border: none;
  margin-top: 20px;
}
.data_catalog_tabs .nav-tabs .nav-link {
  width: 45px;
  height: 6px;
  background-color: #eaeaea;
  margin-right: 9px;
  border-radius: 1px;
  padding: 0;
  color: #7a7a7a;
}

.data_catalog_tabs .nav-tabs .nav-link.active {
  background-color: #00cc66;
  color: #ffffff;
}

/* **************** WorkflowManagement Tabs ******************/
.workflow_management_tabs .nav-tabs {
  border: none;
  font-weight: 400;
  font-size: 15px;
  color: #7a7a7a;
}
.workflow_management_tabs .nav-tabs .nav-link {
  margin-right: 9px;
  border-radius: 4px;
  padding: 12px 30px;
  background-color: #ffffff;
}

.workflow_management_tabs .nav-tabs .nav-link.active {
  font-weight: 400;
  font-size: 15px;
  background-color: #11256d;
  color: #ffffff;
}

/* **************** WorkflowManagement stats Tabs ******************/
.workflow_management_stats_tabs .nav-tabs {
  border: none;
  font-weight: 400;
  font-size: 15px;
  color: #7a7a7a;
}
.workflow_management_stats_tabs .nav-tabs .nav-link {
  margin-right: 9px;
  border-radius: 4px;
  padding: 8px 14px;
  background-color: #eaeaea;
  color: #7a7a7a;
}

.workflow_management_stats_tabs .nav-tabs .nav-link.active {
  font-weight: 400;
  font-size: 15px;
  background-color: #11256d;
  color: #ffffff;
}

/* **************** support_page_tabs  Tabs ******************/
.support_page_tabs .nav-tabs {
  border: none;
  font-weight: 400;
  font-size: 15px;
  color: #7a7a7a;
}
.support_page_tabs .nav-tabs .nav-link {
  margin-right: 9px;
  border-radius: 4px;
  padding: 8px 14px;
  background-color: #eaeaea;
  color: #2c2c2c;
}

.support_page_tabs .nav-tabs .nav-link.active {
  font-weight: 400;
  font-size: 15px;
  background-color: #11256d;
  color: #ffffff;
}

/* *********** react-split-pane styling ************* */
.Resizer {
  background: #000;
  opacity: 0.2;
  z-index: 1;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
}

.Resizer:hover {
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}

.Resizer.horizontal {
  height: 11px;
  margin: -5px 0;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: 100%;
}

/* .Resizer.horizontal:hover {
  border-top: 5px solid rgba(0, 0, 0, 0.5);
  border-bottom: 5px solid rgba(0, 0, 0, 0.5);
} */

.Resizer.vertical {
  width: 11px;
  margin: 0 -5px;
  border-left: 5px solid rgba(255, 255, 255, 0);
  border-right: 5px solid rgba(255, 255, 255, 0);
  cursor: col-resize;
}

.Resizer.vertical:hover {
  border-left: 5px solid rgba(0, 0, 0, 0.5);
  border-right: 5px solid rgba(0, 0, 0, 0.5);
}
.Resizer.disabled {
  cursor: not-allowed;
}
.Resizer.disabled:hover {
  border-color: transparent;
}

/* Ant Design Calendar */
.advanced-search-calendar .ant-picker-panel-container {
  box-shadow: 0px 0px 12px rgba(122, 122, 122, 0.15);
  border-radius: 6px;
}
.advanced_search_date_picker {
  box-shadow: 0px 0px 12px rgba(122, 122, 122, 0.15);
  border-radius: 6px;
}

mark {
  background-color: #1ba94c !important;
  padding: 0px !important;
  color: #ffffff;
}

/* Upload Document Modal PDF */
.upload-document-modal-pdf > div:first-child {
  height: 450px !important;
}
.upload-document-modal-pdf > div:first-child > canvas {
  width: 100% !important;
  height: 450px !important;
  margin: 0px !important;
}
.upload-document-modal-pdf > div:first-child > .react-pdf__Page__textContent {
  display: none;
}

