.popup_overlay {
    position: fixed;
    width: 100%;
    height: calc(100vh - 62px);
    background: #0000007a;
    z-index: 10000;
    bottom: 0;
}
.popup_container {
    position: fixed;
    margin: auto;
    bottom: 0;
    right: 0;
    width: 400px;
    height: calc(100vh - 62px);
    background: #ffffff;
    filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.239));
    z-index: 10001;
}