.root {
  display: flex;
  width: 100vw;
  height: 100vh;
}
.left_part {
  display: flex;
  justify-content: start;
  align-items: center;
  width: 35%;
  height: 100%;

  background-color: #30065a;
}
.left_part > img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.right_part {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 62%;
  background-color: #fafafa;
}

.heading {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 34px;
}
.label_container {
  display: flex;
  font-size: 15px;
}
.forgot_password {
  margin-left: auto;
  color: #11256d;
}
.input_container {
  margin-bottom: 30px;
}
.input {
  width: 486px;
  background-color: #eaeaea;
}
.input > input {
  width: 100%;
  outline: 0;
  border: 0;
  padding: 13px 24px;
  background-color: #eaeaea;
}
.label_container {
}
.captcha_container {
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
}
.sign_in_btn {
  color: #fff;
  background-color: #11256d;
  text-align: center;
  border-radius: 6px;
  padding: 13px 24px;
}
