.root {
  display: flex;
  height: 100%;
  padding-right: 286px;
  background-color: #fafafa;
  margin-left: 30px;
}

.left {
  align-self: flex-start;
  margin-right: 20px;
}
.right {
  width: 100%
}
.relative {
  position: relative;
}
.back_icon {
  position: absolute;
  left: -40px;
  top: 2px;
  cursor: pointer;
}

.title_container {
  width: fit-content;
  line-height: 1.2;
  margin-bottom: 24px;
  cursor: pointer;
}
.heading {
  color: #c4c4c4;
  font-size: 24px;
  font-weight: 500;
}

.search_title {
  color: #2c2c2c;
  font-size: 24px;
  font-weight: 500;
}

.react_pagination {
  display: flex;
  justify-content: center;
}

.dropdown {
  display: inline-block;
  margin-right: 10px;
}

.filter_wrapper {
  display: inline-block;
}
.sort_wrapper {
  display: inline-block;
}
.dropdown_wrapper {
  border-top: 1px solid #eaeaea;
  border-bottom: 1px solid #eaeaea;
  padding: 12px 0;
}
.dropdown {
  position: relative;
  margin-right: 40px;
}