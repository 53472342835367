.projects_list {
  padding-bottom: 120px;
}

.projects_list_header {
  display: flex;
  padding: 15px 0;
  font-weight: 400;
  line-height: 1.2;
  font-size: 16px;
  color: #7a7a7a;
  border-bottom: 1px solid #eaeaea;
}
.projects_list_header > span:first-child {
  display: flex;
  align-items: center;
  width: 60%;
}
.projects_list_header > span:nth-child(2) {
  width: 20%;
}
.projects_list_header > span:last-child {
  flex-grow: 1;
  text-align: right;
}
.projects_list_header > span:first-child > span:first-child {
  margin-right: 20px;
}

.list_container {
  display: flex;
  padding: 22px 0;
  font-size: 16px;
  border-bottom: 1px solid #eaeaea;
}

.name_and_icon_container {
  display: flex;
  align-items: center;
  width: 60%;
  padding-right: 40px;
  cursor: pointer;
}

.project_name {
  font-weight: 500;
  color: #2c2c2c;
  margin-left: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.status_container {
  display: flex;
  align-items: center;
  width: 20%;
  color: #9a9a9a;
  font-weight: 400;
}
.status {
  margin-left: 15px;
}
.date_container {
  /* display: flex; */
  align-items: center;
  width: 40%;
  color: #9a9a9a;
  font-weight: 400;
  text-align: right;
}
.arrow_up {
  width: 21px;
  margin-left: 4px;
  cursor: pointer;
  transition: all 0.4s ease;
}
.arrow_down {
  transform: rotateZ(180deg);
}
