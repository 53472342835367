.root {
  display: flex;
  flex-direction: column;
}

.top {
  display: flex;
  align-items: center;
  width: 100%;
  height: 54px;
}

.left {
  flex: 1 1 73%;
  display: flex;
  align-items: center;
  height: 54px;
  background-color: white;
  padding: 10px 14px;
  border-radius: 74px 0 0 74px;
  border-right: 1px solid #eaeaea;
}

.left ::placeholder {
  color: #c4c4c4;
  margin-left: 8px;
}

.right {
  flex: 1 1 27%;
  height: 54px;
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 0 74px 74px 0;
  padding: 3px;
}

.bottom_container {
  display: flex;
  width: 100%;
  border-top: 1px solid #eaeaea;
  border-bottom: 1px solid #eaeaea;
  margin-top: 20px;
}

.bottom_left {
  width: 73%;
  flex: 1 1 73%;
  display: flex;
  font-weight: 400;
  font-size: 18px;
  color: #7a7a7a;
  margin-right: 2px;
  padding: 10px;
  border-right: 1px solid #eaeaea;
}
.search_result_title {
  margin-left: 17px;
}
.search_keyword {
  width: 60%;
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 5px;
}

.bottom_right {
  width: 27%;
  flex: 1 1 27%;
  display: flex;
  align-items: center;
  padding: 10px 0;
}

.advanced_search {
  display: flex;
  width: 100%;
  cursor: pointer;
}
.advanced_search_text {
  font-weight: 400;
  font-size: 18px;
  color: #7a7a7a;
  padding: 4px;
  margin-left: 20px;
}
.advanced_search_icon {
  margin-left: auto;
  align-self: center;
  margin-right: 10px;
}
input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

.option {
  padding: 50px;
}

.source_text {
  margin-right: auto;
  margin-left: 24px;
  font-weight: 700;
  font-size: 12px;
  color: #2c2c2c;
}

.source_icon {
  width: 22px;
  height: 8px;
}
