.recent_doc_table{
    width: 100%;
}
.recent_doc_row_label th label{
    font-size: 11px;
    color: #BDBEC5;
}
.recent_doc_list_container{
    overflow: auto;
}
.recent_doc_list_container table {
    border-collapse:separate; 
    border-spacing: 0 1.5em;
  }
  .recent_doc_table thead{
      line-height: 0.4;
  }

  .recent_doc_table thead tr th:last-child { text-align: right; }
.recent_doc_table td:last-child { text-align: right; }

.recent_doc_table td span{
    font-size: 11px;
    font-weight: 500;
}


  
 table thead tr th {
    background-color: #ffffff;
    position: sticky !important;
    z-index: 100;
    top: 0;
  }
  .doc_name{
      cursor: pointer;
      font-family: "Roboto", sans-serif;
  }
  
  .recent_doc_table td .doc_status {
    font-size: 9px;
    background-color: #ccd5f7;
    height: fit-content;
    padding: 6px 24px;
    border-radius: 20px;
    font-weight: 500;
    color: #11256d;

}
.doc_name_row{
    width:50%;
     word-break:break-all;
    line-height: 1;
    cursor: pointer;

}
