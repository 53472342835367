.bottom_wrapper{
    
    margin-top: 82px;
}
.btn_gap{
    margin-right: 16px;
}
.bottom_content{
    display:flex;
    justify-content:space-between;
}
.bottom_content .search_from_radios span{
    margin-right: 18px;
    
}
.bottom_content .search_from_radios span label{
    color: #3B4856;
    font-size: 14px;
    cursor: pointer;
}