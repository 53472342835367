.data_catalog_all_folders_table {
  width: 100%;
}

.data_catalog_all_folders_table td {
  padding: 22px 10px;
  border-bottom: 1px solid #eaeaea;
}
.data_catalog_all_folders_table th {
  padding: 15px 10px;
  border-bottom: 1px solid #eaeaea;
  text-align: left;
  background-color: #fafafa !important;
  color: #7a7a7a;
  font-weight: 400;
}
.data_catalog_all_folders_table thead tr th:last-child {
  text-align: center;
}
.fixed_row {
  border-bottom: 2px solid #c4c4c4;
}
.data_catalog_all_folders_table td:last-child {
  text-align: center;
}
.all_folders_row_label {
  padding: 0px;
  font-size: 18px;
}
.folder_name {
  color: #2c2c2c;
  font-weight: 500;
  font-size: 18px;
  cursor: pointer;
}
.folder_name i {
  margin-right: 8px;
}
.arrow_up {
  width: 21px;
  margin-left: 15px;
  margin-bottom: 5px;
  cursor: pointer;
  transition: all 0.4s ease;
}
.arrow_down {
  transform: rotateZ(180deg);
}
.view_all_folders {
  font-weight: 400;
  font-size: 18px;
  color: #7a7a7a;
  margin: 20px 10px;
  cursor: pointer;
}
.confidence_score {
  width: 16px;
  height: 16px;
  background: green;
  display: inline-block;
  border-radius: 50%;
  line-height: 1;
  margin-right: 10px;
}
.modified {
  color: #9a9a9a;
  font-weight: 400;
  font-size: 18px;
}
.number {
  color: #9a9a9a;
  font-weight: 400;
  font-size: 18px;
}

.size {
  color: #9a9a9a;
  font-weight: 400;
  font-size: 18px;
}
