.row_gap {
  margin-top: 20px;
}
.buttons_wrapper {
  text-align: right;
  margin-top: 82px;
}
.btn_gap {
  margin-right: 16px;
}
textarea {
  resize: none;
}
.browse_btn {
  background-color: #ffffff;
  font-size: 10px;
  color: #144192;
  font-weight: 600;
  font-size: 12px;
  box-shadow: none !important;
  border-radius: 4px;
  width: 100px;
  border: 1.5px solid #144192;
}
.textarea{
  margin-left: 15px;
  margin-right: 15px;
}
