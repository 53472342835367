.doc_wrapper {
    margin-top: 62px;
}
.doc_control_container {
    margin-bottom: 3em;
    padding: 1.2em 1em;
    background-color: #d3dbf8;
    color: #708bc3;
    font-weight: 500;
    font-size: 13px;
    position: fixed;
    height: 51px;
    width: 70%;
    z-index: 4;
    left: 65px;
}
.doc_control_section {
    display: flex;
    justify-content: space-between;
}
.icon_style {
    padding-right: 1em;
    cursor: pointer;
    font-size: larger;
    margin-top: 2px;
}
.doc_render_wrapper {
    text-align: center;
    padding-top: 100px;
    /* overflow: auto; */
}


.AgreeLeft .AgreeBox figcaption {
    display: flex;
    position: relative;
    min-height: 55px;
}
.AgreeLeft .AgreeBox figcaption article {
    background-color: #fff;
    width: 80%;
    padding: 30px 70px 80px 50px;
    overflow: inherit;
    text-align: left;

}

.AgreeLeft .AgreeBox figcaption article span {
    font-size: 14px;
}
