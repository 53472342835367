.data_catalog_container {
  background-color: #fafafa;
  /* margin-top: 10px; */
}
.top_container {
  display: flex;
  justify-content: space-between;
}
.data_catalog_heading {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  color: #2c2c2c;
}
.data_catalog_title {
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 10px;
  margin-top: -5px;
}
.data_catalog_subtitle {
  font-size: 36px;
  font-weight: 500;
  line-height: 1.2;
}

.top_container_cards_section {
  width: 100%;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  margin-bottom: 30px;
  /* border: 1px solid red; */
}
.top_container_cards_section i {
  font-size: 40px;
}
.card_wrapper {
  display: flex;
  margin-left: auto;
}

.new_project_card {
  display: flex;
  align-items: center;
  width: 360px;
  height: 122px;
  margin-left: 15px;
  padding: 15px;
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(122, 122, 122, 0.15);
  border-radius: 6px;
}
/* .new_project_card_body {
  padding: 1em;
} */

/* .top_container_content {
  display: flex;
  padding: 15px;
  align-items: center;
  border: 1px solid red;;
} */
.folder_icon_container {
  display: flex;
  background: #f7f7f7;
  width: 92px;
  height: 92px;
  border-radius: 4px;
}
.folder_icon_container img {
  margin: 12px;
}
.connector_icon_container {
  display: flex;
  background: #f7f7f7;
  width: 92px;
  height: 92px;
  border-radius: 4px;
}
.connector_icon_container img {
  padding: 10px;
}
.text_plus_icon_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.plus_icon {
  cursor: pointer;
}

.all_folder_and_searches_container {
  display: flex;
}
.all_folders_container {
  width: 60%;
  /* flex: 1 1 60%; */
  margin-right: 30px;
}
.recent_searches_container {
  width: 40%;
  /* flex: 1 1 40%; */
}

.top_container_card_info {
  flex-grow: 1;
  color: #7a7a7a;
  margin-left: 13px;
  padding: 5px 5px 5px 0;
}
.top_container_title {
  font-size: 12px;
}
.top_container_count {
  font-size: 18px;
  font-weight: 700;
  border-bottom: 1px solid #7a7a7a;
  padding-bottom: 6px;
  margin-bottom: 6px;
  cursor: pointer;
}
.top_container_action {
  font-size: 18px;
}
.card_container {
  display: flex;
  justify-content: space-between;
  /* height: 350px; */
  /* margin-bottom: 80px;
  margin-top: 18px; */
  /* border: 1px solid red; */
  padding-bottom: 30px;
  margin-top: 20px;
  border-bottom: 1px solid #eaeaea;
}
.recent_folders_heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}
.title {
  font-size: 24px;
  font-weight: 500;
  color: #2c2c2c;
}
.heading_icon {
  margin-left: 8px;
  width: 20px;
}
.recent_queries_card {
  height: 358px;
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(122, 122, 122, 0.15);
  border-radius: 6px;
}
.recent_queries_card_body {
  height: 100%;
}
.data_catalog_tabs {
  margin-right: 12px;
}
.recent_folders_view_all {
  font-size: 18px;
  font-weight: 400;
  color: #7a7a7a;
  cursor: pointer;
  margin-right: 15px;
}
.recent_folders_card {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 358px;
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(122, 122, 122, 0.15);
  border-radius: 6px;
  padding: 10px 30px;
}
.recent_folders_card::-webkit-scrollbar-thumb {
  background-color: #11256d;
}

.recent_document_container {
  margin: 16px 0px;
}
.tab_title {
  width: 45px;
  height: 6px;
  border-radius: 1px;
}

.recent_documents_container {
  margin: 22px 0 22px 0;
  border-bottom: 1px solid #eaeaea;
}

.recent_documents_header_container {
  display: flex;
  align-items: center;
}

.recent_documents_title {
  display: inline-block;
  font-size: 24px;
  font-weight: 500;
  margin-right: 10px;
  color: #2c2c2c;
}

.react_pagination {
  display: flex;
  margin-left: auto;
}
.recent_document_container {
  margin: 16px 0px;
}
.recent_document_icon {
  width: 16px;
  margin-left: 6px;
}
.recent_document_section {
  margin-top: 22px;
  margin-bottom: 28px;
}
.all_folder_title_icon {
  width: 20px;
  margin-left: 6px;
}
.recent_search_title_icon {
  width: 24px;
  margin-left: 6px;
}
.recent_searches_title_container {
  padding: 0 0px 22px 0;
}
.all_folders_title_container {
  padding: 0 0px 22px 0;
  border-bottom: 1px solid #eaeaea;
}

.recent_search_text {
  color: #9a9a9a;
}
.recent_search_container {
}

.search_bar_and_masterlist {
  display: flex;
  width: 100%;
}
.search_bar_container {
  width: 80%;
  padding-bottom: 30px;
  margin-right: 15px;
  border-bottom: 1px solid #eaeaea;
}
.master_list {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20%;
  height: 54px;
  border-radius: 6px;
  background-color: #ffffff;
  font-size: 18px;
  padding: 13px 18px;
  cursor: pointer;
}
.master_list > div {
  height: fit-content;
  margin-left: 24px;
}
.recent_document_icon {
  font-size: 16px;
}
.left_card {
  width: 60%;
  margin-right: 30px;
}
.right_card {
  width: 40%;
}
.add_source_modal_btn {
  cursor: pointer;
}
