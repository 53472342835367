.root {
  display: flex;
  width: 100%;
  height: 316px;
  background-color: white;
  margin-top: 15px;
  padding: 0 24px;
  animation: open 0.3s ease-in-out;
}

@keyframes open {
  from {
    height: 0px;
  }
  to {
    height: 316px;
  }
}

/* Left Part */
.left {
  height: inherit;
  flex: 1 1 60%;
  display: flex;
}

.document_type {
  flex: 1 1 40%;
  display: flex;
  flex-direction: column;
  margin-right: 60px;
}

.top {
  flex: 1 1 85%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  font-size: 15px;
  border-bottom: 1px solid #eaeaea;
}
.top::-webkit-scrollbar-thumb {
  background-color: #11256d;
}
.type_container {
  display: flex;
  align-items: center;
  padding: 2px 20px 2px 10px;
  color: #9a9a9a;
  cursor: pointer;
}
.heading {
  background-color: #11256d;
  color: white;
}

.tag {
}
.icon_container {
  margin-left: auto;
}

/* Top Bottom  */
.bottom {
  flex: 1 1 15%;
  display: flex;
  margin-top: 18px;
}
.switch_container {
  margin-right: 12px;
}
.bottom_text {
  font-weight: 400;
  font-size: 15px;
  color: #c4c4c4;
}
.documents_list {
  height: inherit;
  flex: 1 1 60%;
  display: flex;
}
.documents_list::-webkit-scrollbar-thumb {
  background-color: #11256d;
}

.checkbox_container {
  flex: 1 1 90%;
  overflow-y: auto;
}
.checkbox_container::-webkit-scrollbar-thumb {
  background-color: #11256d;
}

.alphabets_list {
  display: flex;
  flex-direction: column;
  height: 316px;
  line-height: 1.5;
}
.alphabets {
  font-size: 9px;
  font-weight: 400;
  cursor: pointer;
}

/* Right Part */
.right {
  flex: 1 1 40%;
  display: flex;
  flex-direction: column;
  padding-left: 60px;
}
.right_top {
  /* flex: 1 1 40%; */
  height: 230px;
  display: flex;
}
.start_date {
  flex: 1 1 50%;
  display: flex;
  flex-direction: column;
  margin-right: 45px;
}
.date_text {
  font-weight: 400;
  font-size: 15px;
  color: #9a9a9a;
  margin-bottom: 2px;
}
.calendar_toggler {
  display: flex;
  background-color: #fafafa;
}
.calendar_container {
  background-color: #fafafa;
}
.calendar_container input[type='date']::-webkit-calendar-picker-indicator {
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: none;
}

.calendar_container input[type='date'] {
  width: 100%;
  padding: 6px 15px;
  border: none;
  background-color: inherit;
  font-weight: 400;
  color: #c4c4c4;
  font-size: 15px;
}

.end_date {
  flex: 1 1 50%;
  display: flex;
  flex-direction: column;
  padding-top: 0;
}

.right_bottom {
  /* flex: 1 1 10%; */
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.clear_all_btn {
  height: fit-content;
  background-color: #fafafa;
  border-radius: 4px;
  font-weight: 400;
  font-size: 15px;
  color: #c4c4c4;
  padding: 6px 15px;
  margin-right: 20px;
  cursor: pointer;
}
.advanced_search_btn {
  padding: 6px 15px;
  background-color: #11256d;
  border-radius: 4px;
  font-weight: 400;
  font-size: 15px;
  color: #ffffff;
  cursor: pointer;
}

/****************** Toggle Switch *********************/

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 35px;
  height: 19px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #eaeaea;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: #9a9a9a;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #11256d;
}

input:focus + .slider {
  box-shadow: 0 0 1px #11256d;
}

input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
  background-color: #fff;
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
