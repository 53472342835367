.data_catalog_all_folders_container {
  background-color: #fafafa;
  margin-left: 30px;
}
.back_icon {
  position: absolute;
  left: -40px;
  top: 2px;
  cursor: pointer;
}
.data_catalog_all_folders_top_section {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.folder_path {
  font-size: 24px;
  font-weight: 500;
  color: #c4c4c4;
}
.folder_name {
  font-size: 24px;
  font-weight: 500;
}
.left_section {
  width: 50%;
  line-height: 1.2;
  position: relative;
}
.right_section {
  display: flex;
  width: 50%;
  justify-content: flex-end;
  height: fit-content;
  flex-flow: wrap;

}
.right_section_card {
  width: 45%;
  background: #ffffff;
  margin-left: 12px;
  /* display: flex; */
  border-radius: 6px;
  padding: 12px 18px;
  margin-bottom: 8px;
}
.card_content {
  display: flex;
  align-items: center;
}
.card_text_content {
  width: 100%;
}
.card_bottom_section {
  display: flex;
  justify-content: space-between;
  font-size: 9px;
  color: #9a9a9a;
}
.title_search_bar_container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-right: 16px;
}
.title_container {
  font-size: 24px;
  font-weight: 500;
  color: #2c2c2c;
}
.title {
  margin-right: 10px;
}
.search_bar_section {
  width: 45%;
}
.search_bar_container {
  flex: 1 1 70%;
  display: flex;
  align-items: center;
  margin-right: 2px;
  background-color: white;
  padding: 8px;
  border-radius: 74px;
}
.search_bar_container input {
  margin-left: 10px;
}
.search_bar_container ::placeholder {
  color: #c4c4c4;
  font-size: 18px;
  font-weight: 400;
}
.search_box {
  flex-grow: 1;
  border: none;
  color: #000000;
  font-size: 18px;
  font-weight: 400;
}

.search_icon {
  cursor: pointer;
}

.icon {
  width: 24px;
  height: 24px;
  margin-right: 12px;
}
.name {
  color: #2c2c2c;
  font-size: 15px;
  font-weight: 400;
}

.recent_document_container {
  margin-top: 20px;
  margin-bottom: 30px;
}

.all_folder_list_container {
  padding-bottom: 50px;
}
