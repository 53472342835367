.root {
    width: 100%;
    display: flex;
    background-color: inherit;
  }
  .search_box {
    flex-grow: 1;
    background-color: inherit;
    border: none;
  }
  ::-webkit-input-placeholder {
    color: #c4c4c4;
  }
  
  .search_icon {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  
  