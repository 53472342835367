.upload_document_container{
    text-align: center;

}
.upload_document_text{
    font-size: 1.5em;
    margin-top: 28px;
    margin-bottom: 0px;
    color:#11256D;
    font-weight: 600;
}
.upload_document_format_text{
    font-size: 12px;
}
.upload_document_submit_section{
text-align: center;
margin-top: 20px;
}
.upload_button{
    padding: 3px 24px;
    font-weight: 500;
}