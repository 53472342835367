.all_folders_tree_container {
  background-color: #fafafa;
  margin-left: 30px;
}
.all_folders_tree_top_section {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.left_section {
  width: fit-content;
  line-height: 1.2;
  position: relative;
  cursor: pointer;
}

.back_icon {
  position: absolute;
  left: -40px;
  top: 2px;
  cursor: pointer;
}

.folder_path {
  /* font-size: 1.5em; */
  font-size: 24px;
  font-weight: 500;
  color: #c4c4c4;
}
.folder_name {
  /* font-size: 1.5em; */
  font-size: 24px;
  font-weight: 500;
  color: #2c2c2c;
}
.recent_document_container {
  margin-top: 30px;
  margin-bottom: 20px;
}
.title_search_bar_container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  font-size: 18px;
  color: #000000;
}
.title {
  /* font-size: 1.33em; */
  font-size: 24px;
  font-weight: 500;
  color: #2c2c2c;
}

.search_bar_section {
  width: 45%;
}
.search_bar_container {
  flex: 1 1 70%;
  display: flex;
  align-items: center;
  margin-right: 2px;
  background-color: white;
  padding: 10px 10px 10px 24px;
  border-radius: 74px;
}
.search_bar_container ::placeholder {
  color: #c4c4c4;
}

.search_box {
  flex-grow: 1;
  border: none;
}

.search_icon {
  cursor: pointer;
}
.icon {
  width: 24px;
  height: 24px;
  margin-right: 12px;
}

.all_folders_tree_recent_folders_container {
  padding-bottom: 30px;
  border-bottom: 1px solid #eaeaea;
}

.tree_folders_container {
  display: flex;
}
.tree_view_section {
  width: 40%;
  margin-right: 20px;
}
.table_view_section {
  width: 60%;
}
.tree_view_title_container {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  border-bottom: 1px solid #eaeaea;
}
.tree_view_title {
  color: #7a7a7a;
  padding: 24px 0px;
  font-size: 18px;
  border-bottom: 1px solid #eaeaea;
}
.folder_icon {
  margin-left: 6px;
}
