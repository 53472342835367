.infoBar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /*background: #2979FF;*/
    border-radius: 4px 4px 0 0;
    height: 60px;
    width: 100%;
}

.leftInnerContainer {
    flex: 0.5;
    display: flex;
    align-items: center;
    /* margin-left: 5%; */
    /* color: white; */
}

.rightInnerContainer {
    display: flex;
    flex: 0.5;
    justify-content: flex-end;
    margin-right: 5%;
}

.onlineIcon {
    margin-right: 5%;
}

.user {
    padding-left: 5px;
    padding-right: 5px;
    width: 39px;
    border-radius: 500px;
}

.activeLink {
    background-color: #000000;
}
.user:hover {
    cursor: pointer;
}

.all_users_text{
    color: #9A9A9A;
    font-size: 9px;
    width: 24px;
    height: 24px;
    background:  #EAEAEA;
    border-radius: 50%;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    cursor: pointer;
}