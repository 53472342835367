body {
  background-color: #f5f7fd;
}

.subheading {
  top: 128px;
  left: 113px;
  width: 276px;
  height: 14px;
  font-size: 12px;
  font-weight: 500;
  line-height: 0.9;
  letter-spacing: 0px;
  color: #3b4856;
  opacity: 1;
}

.badge1 {
  position: relative;
  font-size: 24px;
  margin-top: 8px;
  /* margin-right: 15px; */
  color: #bdc1c6;
  cursor: pointer;
}

.badge1[data-badge]:after {
  content: attr(data-badge);
  position: absolute;
  top: -4px;
  right: -4px;
  font-size: 0.7em;
  background: #e63e3c;
  color: white;
  width: 8px;
  height: 8px;
  text-align: center;
  line-height: 18px;
  border-radius: 50%;
}

.popup_overlay_notification {
  position: fixed;
  width: 100%;
  height: 100vh;
  background: #0000007a;
  z-index: 10000;
  bottom: 0;
}

.popup_container_notification {
  position: fixed;
  margin: auto;
  top: 4;
  width: 259px;
  height: 361px;
  background: #ffffff;
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.239));
  z-index: 10001;
  top: 11px;
  right: 151px;
  border-radius: 8px;
  background-color: #f5f7fd;
}

.popup_container_notification_heading {
  justify-content: space-between;
  top: 24px;
  left: 814px;
  width: 87px;
  height: 14px;
  color: #3b4856;
  text-align: left;
  opacity: 1;
  font-size: 12px;
  margin-top: 12px;
  margin-left: 12px;
}

.popup_container_notification_heading_sub {
  white-space: nowrap;
  color: #144192;
  margin-left: 100px;
  cursor: pointer;
}

.popup_content_notification {
  font-size: 14px;
  color: #3b4856;
  font-weight: bold;
  margin-left: 20px;
  margin-top: 14px;
  cursor: pointer;
}

.signout {
  margin-top: 16px;
}

.card_body {
  top: 169px;
  left: 113px;
  /* width: 1118px; */
  height: 476px;
  overflow: scroll;
}

.card {
  margin-top: 27px;
  margin-left: 113px;
  margin-right: 40px;
}

.notification {
  top: 189px;
  left: 148px;
  height: 16px;
  color: #3b4856;
  margin-left: 50px;
}

.notification_card {
  margin-bottom: 25px;
  display: block;
}

.notification_heading {
  color: #3b4856;
  top: 189px;
  /* left: 148px; */
  height: 16px;
  letter-spacing: 0px;
  font-weight: bold;
  opacity: 1;
  /* text-align: left; */
  margin-bottom: 9px;
  font-size: 12px;
}

.notification_data {
  /* display: flex; */
  color: #3b4856;
  opacity: 0.68;
  top: 214px;
  left: 148px;
  height: 35px;
  font-size: 12px;
  /* justify-content: space-between; */
  font-family: "Roboto", sans-serif;
}

.noNotification {
  font-family: "Roboto", sans-serif;
  text-align: center;
  margin-top: 200px;
}

.notificationDate {
  /* justify-content: space-between; */
  text-align: right;
  /* color: #c0c0c0; */
  font-size: 12px;
  top: 126px;
  height: 10px;
  /* margin-left: 500px; */
  opacity: 1;
  font-family: "Roboto", sans-serif;
}
.markAllBtn {
  background-color: #144192;
  color: #ffffff;
  font-weight: 600;
  font-size: 12px;
  box-shadow: none !important;
  border-radius: 4px;
  width: 160px;
  padding-bottom: 12px;
  margin-left: 700px;
}

.markAllBtn:hover {
  color: #fff !important;
}

.markAsRead {
  color: #144192;
  font-weight: 600;
  font-size: 12px;
  box-shadow: none !important;
  border-radius: 4px;
  padding-top: 12px;
  padding-bottom: 12px;
  padding: 3px !important;
}

.markAsRead:hover {
  color: #144192 !important;
}

.notifications_container {
  margin-left: 24px;
  overflow: auto;
  /* height: calc(100vh - 300px); */
}
