.section_wrapper {
    background-color: #ffffff;
    padding: 12px 20px;
}
.two_column_row {
    display: flex;
    justify-content: space-between;
}
.doc_name {
    line-height: 1.3;
}
.heading {
    font-size: 11px;
    font-weight: 500;
    color: #bdbec5;
}
.heading_value {
    font-size: 12px;
    font-weight: 500;
    color: #3b4856;
}
.uploaded_by_row {
    line-height: 1.3;
    margin-top: 8px;
}
.doc_status {
    font-size: 11px;
    background-color: #ccd5f7;
    height: fit-content;
    padding: 4px 20px;
    border-radius: 20px;
    font-weight: 500;
    color: #11256d;
}
hr {
    margin-top: 8px;
    margin-bottom: 8px;
}

/* .circle {position: relative; border: 2px solid #999; border-radius: 100%; width: 10px;height: 10px; line-height: 50px; text-align: center; margin-top: 50px; background-color: #fff; z-index: 2;}
.circle:first-child {margin-top: 0;} */
/* .circle:before {position: absolute; border: 1px solid #999; width: 0; height: 50px; display: block; content: ''; left: 50%; z-index: 1; top: -54px; margin-left: -1px;} */
/* .circle:first-child:before {display: none;} */

/* .circle {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    border: 2px solid;
    position: relative;
    border-color: #889EB7;
  }
  
  .circle:before {
    content: "";
    display: block;
    position: absolute;
    z-index: 1;
    top: 100%;
    left: 50%;
    border: 1px solid gray;
    border-width: 0 0 0 1px;
    width: 1px;
    height: 100px;
  }
  .circle:last-child:before {display: none;} */
.file_journey_container {
    padding: 4px 20px 10px 20px;
}
.title_section {
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 12px;
}
.file_journey_title {
    color: #3b4856;
}
.open_doc_title {
    float: right;
    font-size: 12px;
    color: #144192;
    cursor: pointer;
}
.file_journey_section {
    margin-bottom: 20px;
}
.status_journey {
    font-size: 12px;
    color: #3b4856;
    font-weight: bold;
    display: flex; 
    justify-content: space-between;
}
.status_changed_by {
    font-size: 12px;
    color: #3b4856;
}
.date {
    font-size: 10px;
    color: #3b4856;
}
