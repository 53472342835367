.signup_form_container{
    margin-top: 60px;
    margin-left: 140px;
}
.signup_main_text{
    font-size: 1.5em;
    font-weight: 600;
    margin-bottom: 4px;
}
.signup_sub_text{
    margin-bottom: 0px;
}
.singup_tnc{
    font-size: 0.8em;
}
.signup_checkbox{
    margin-top: 0.5em;
    cursor: pointer;
}
.signup_form_captcha_container{
    margin:30px 0px ;
}
.signup_form_submit_container {
    text-align: center;
    margin-bottom: 10px;
}