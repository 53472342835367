.nav_icon_title {
    font-size: 9px;
    word-break: break-word;
    font-family: "Roboto";
    margin-top: 8px;
    font-weight: 500;
}
.bottom_nav_icon_container {
    position: fixed;
    bottom: 76px
}

.bottom_nav_icon_container_second {
    position: fixed;
    bottom: 10px
}
