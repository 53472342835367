.data_catalog_all_folders_table {
  width: 100%;
}

.data_catalog_all_folders_table th {
  border-bottom: 1px solid #eaeaea;
  text-align: left;
  padding: 16px 10px;
  background-color: #fafafa !important;
  color: #7a7a7a;
  font-weight: 400;
  font-size: 18px;
}
.data_catalog_all_folders_table td {
  padding: 16px 10px;
  border-bottom: 1px solid #eaeaea;
}
.all_folders_row_label {
  padding: 0px;
}
.folder_details {
  font-size: 18px;
  cursor: pointer;
}
.folder_name {
  color: #2c2c2c;
  font-weight: 500;
}
.folder_name i {
  margin-right: 8px;
}
.arrow_up {
  width: 21px;
  margin-left: 4px;
  cursor: pointer;
  transition: all 0.4s ease;
}
.arrow_down {
  transform: rotateZ(180deg);
}
.view_all_folders {
  color: #7a7a7a;
  margin: 10px 0px;
  cursor: pointer;
}
.grey_document {
  width: 12px;
  margin-right: 16px;
}
.confidence_score {
  width: 16px;
  height: 16px;
  background: #00cc66;
  display: inline-block;
  border-radius: 50%;
  line-height: 1;
  margin-right: 10px;
}
.modified {
  color: #9a9a9a;
}
.number {
  color: #9a9a9a;
}

/* For Tooltip */
.tooltip {
  position: relative;
  display: inline-block;
  /* border-bottom: 1px dotted black; */
  cursor: pointer;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: fit-content;
  background-color: #eaeaea;
  color: #000000;
  text-align: center;
  border-radius: 6px;
  padding: 15px;
  font-size: 10px;

  /* Position the tooltip */
  position: absolute;
  top: 30px;
  left: 0;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
