.messageBox {
    background: #F3F3F3;
    /*border-radius: 20px;*/
    border-radius: 4px 4px 0px 4px;
    padding: 0px 15px;
    color: white;
    display: inline-block;
    max-width: 80%;
}

.messageText {
    width: 100%;
    letter-spacing: 0;
    /* text-align: center; */
    font-size: 12px;
    word-wrap: break-word;
    margin: 8px 0px;
}

.messageText img {
    vertical-align: middle;
}

.chat_person_image{
    vertical-align: middle;
    border-style: none;
    /* border: 1px solid #FAFAFA; */
    border-radius: 500px;
    margin-right: 8px;
    width: 39px !important;
    height: 35px !important;
}

.messageContainer {
    display: flex;
    justify-content: flex-end;
    /* padding: 0 5%; */
    margin-top: 3px;
    margin-bottom: 12px;
}

.sentText {
    display: flex;
    align-items: center;
    color: #9A9A9A;
    letter-spacing: 0.3px;
    font-size: 9px;
}

.pl-10 {
    padding-left: 10px;
    justify-content: flex-start;
}

.pr-10 {
    padding-right: 10px;
    justify-content: flex-end;
}

.justifyStart {
    justify-content: flex-start;
}

.justifyEnd {
    justify-content: flex-end !important;
    text-align: right;
    margin-right: 8px;
}

.colorDark {
    color: #2C2C2C !important;
}

.colorWhite {
    color: #FFFFFF
}

.backgroundWhite {
    background: #EAEAEA;
}

.backgroundDark {
    background:  rgba(17, 37, 109, 0.5);
    border-radius: 4px 4px 4px 0px;
}
