.uploaded_doc_content_wrapper {
  display: flex;
  justify-content: space-between;
}
.uploaded_doc_section {
  width: 74%;
  background-color: #f5f7fd;
}
.response_section {
  width: 95%;
  background-color: #fff;
  position: fixed;
  margin-top: 512px;
  z-index: 5;
  margin-left: 65px;
  height: 225px;
}

.cross_btn {
  text-align: right;
}

.doc_preview_section {
  height: 100vh;
  position: fixed;
  right: 0;
  width: 27%;
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.239));
  margin-top: 62.6px;
  z-index: 5;
}

.prepare_response {
  height: 100vh;
  position: fixed;
  right: 0;
  width: 95%;
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.239));
  margin-top: 62.6px;
  z-index: 5;
}

.Resizer {
  margin-top: 30%;
  background: #000;
  opacity: 0.2;
  z-index: 1;
  height: 100%;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
}

.Resizer:hover {
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}

.Resizer.horizontal {
  height: 11px;
  margin: -5px 0;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: 100%;
}

.Resizer.horizontal:hover {
  border-top: 5px solid rgba(0, 0, 0, 0.5);
  border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
  width: 11px;
  margin: 0 -5px;
  border-left: 5px solid rgba(255, 255, 255, 0);
  border-right: 5px solid rgba(255, 255, 255, 0);
  cursor: col-resize;
}

.Resizer.vertical:hover {
  border-left: 5px solid rgba(0, 0, 0, 0.5);
  border-right: 5px solid rgba(0, 0, 0, 0.5);
}
.Resizer.disabled {
  cursor: not-allowed;
}
.Resizer.disabled:hover {
  border-color: transparent;
}
