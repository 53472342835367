.graph_action_btn_container{
    position: relative;
     bottom: 30px;
     margin: 0px 12px;
}
.graph_action_btn_section{
    display: flex;
     justify-content: space-between;
}
.graph_bottom_title{
    font-size: 18px;
    color: #3B4856;
    font-weight: bold;
}
.graph_bottom_subtitle span{
    font-size: 12px;
    color: #3B4856;
    margin: 0px 8px;
    cursor: pointer;
}
.graph_bottom_subtitle i{
    font-size: 10px;
}