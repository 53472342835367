.wrapper {
  display: flex;
  flex-direction: column;
}

.root {
  display: flex;
  width: 71%;
  background-color: #fafafa;
  margin-top: 30px;
}

.left {
  display: flex;
  flex-direction: column;
  margin-right: 30px;
  width: 100%;
}

.right {
  flex: 1 1 27%;
  display: flex;
  flex-direction: column;
}

.right_bottom {
  margin-top: 20px;
  overflow-y: auto;
}

.title {
  font-weight: 500;
  font-size: 24px;
  color: #2c2c2c;
  margin-bottom: 30px;
}

.subheading {
  font-weight: 400;
  font-size: 18px;
  color: #7a7a7a;
  padding: 12px 0;
}

.heading {
  display: inline-block;
}

.page_end_text {
  font-weight: 400;
  font-size: 18px;
  color: #7a7a7a;
  margin-top: 24px;
  margin-bottom: 30px;
}
.page_end_text > span {
  cursor: pointer;
}

.filter_wrapper {
  display: inline-block;
}
.sort_wrapper {
  display: inline-block;
}
.dropdown_wrapper {
  border-top: 1px solid #eaeaea;
  border-bottom: 1px solid #eaeaea;
  padding: 10px 0;
}
.dropdown {
  position: relative;
  margin-right: 40px;
}

/* For Heading */
.heading__root {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
}

.heading__heading {
  display: inherit;
  font-weight: 500;
  font-size: 24px;
  color: #2c2c2c;
  margin-right: 10px;
  line-height: 1.2;
}

/* For Related Folders */
.related_folder__root {
  margin-top: 32px;
  overflow-y: auto;
  background-color: #fafafa;
}

.related_folder__folder {
  display: flex;
  width: 100%;
  height: 72px;
  margin-bottom: 10px;
  align-items: center;
  background-color: white;
  padding: 20px 10px;
  border-radius: 6px;
}

.related_folder__name {
  font-weight: 400;
  font-size: 18px;
  color: #2c2c2c;
}

.related_folder__icon {
  margin: 0 40px 0 20px;
}

/* For Keywords */
div[class|='keyword'] {
  display: inline-block;
  cursor: pointer;
  margin: 2px;
  color: #9a9a9a;
}

div[class*='keyword-0'] {
  /* color: #1ba94c; */
}

.keyword__root {
  flex-grow: 0.2;
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  font-size: 18px;
}

/* For Related Searches */
.related_searches__root {
  flex-grow: 1;
}

.related_searches__wrapper {
  margin-top: 24px;
  border-bottom: 1px solid #eaeaea;
}
.related_searches__container {
  display: flex;
  background-color: #fafafa;
  margin-bottom: 20px;
}

.related_searches__left_container {
  width: 60%;
  flex: 1 0 60%;
  padding-right: 17%;
}

.related_searches__right_container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.related_searches__share_link {
  position: relative;
  text-align: end;
  margin-bottom: 10px;
}

/* For Body */
.body__root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.body__heading {
  font-weight: 400;
  font-size: 15px;
  color: #1BA94C;
  cursor: pointer;
  text-transform: capitalize;
}

.body__subheading {
  font-weight: 400;
  font-size: 18px;
  color: #2c2c2c;
  margin-bottom: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.body__description {
  font-weight: 400;
  font-size: 15px;
  color: #9a9a9a;
  margin-bottom: 9px;

  /* restricting text to 2 lines */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.body__date {
  margin-top: auto;
  font-size: 9px;
  font-weight: 400;
  color: #9a9a9a;
}

/* For Graphic Container */
.graphic_container__root {
  justify-self: flex-start;
  display: flex;
  flex-direction: column;
  width: 249px;
  height: fit-content;
}

.graphic_container__confidence {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: white;
  border-radius: 4px;
  color: #9a9a9a;
  font-weight: 400;
  font-size: 9px;
  padding: 6px;
  padding-bottom: 0px;
  margin-bottom: 5px;
}

.confidence_container {
  width: 100%;
  border-radius: 15px;
  margin-top: 8px;
}
.confidence {
  background-color: #00cc66;
  height: 2px;
  border-radius: 15px;
}

.confidence_value_container {
  display: flex;
  color: #9a9a9a;
}
.confidence_value {
  margin-left: auto;
}

.graphic_container__results_and_sources {
  display: flex;
  flex-grow: 1;
  width: 100%;
}

.graphic_container__results {
  height: fit-content;
  flex-grow: 1;
  margin: 4px 2px 0 0;
  background-color: white;
  border-radius: 4px;
  padding: 6px;
}

.graphic_container__sources {
  height: fit-content;
  flex-grow: 1;
  margin: 4px 0 0 2px;
  background-color: white;
  border-radius: 4px;
  padding: 6px;
}

/* For Graphic */
.graphic__root {
  flex-grow: 1;
  display: flex;
  align-items: center;
  padding: 1px;
  font-weight: 400;
}

.graphic__title {
  height: fit-content;
  font-size: 9px;
  color: #9a9a9a;
}

.graphic__count {
  font-size: 15px;
  color: #9a9a9a;
  height: fit-content;
}

.graphic__icon_container {
  margin: 0 10px 0 2px;
  background: #f7f7f7;
}

.graphic__icon {
  width: 30px;
  height: 30px;
  padding: 5px;
}
.avatars {
  display: inline-flex;
  padding: 2px;
  margin-left: -8px;
}

.avatar {
  position: relative;
  width: 24px;
  height: 24px;
  border: 1px solid #fff;
  border-radius: 50%;
  overflow: hidden;
}

.avatar:not(:last-child) {
  margin-right: -8px;
}

.avatar img {
  width: 24px;
  height: 24px;
}
