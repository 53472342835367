.doc_info_container {
    padding: 20px;
}
.two_column_row {
    display: flex;
    justify-content: space-between;
}
.doc_name {
    line-height: 1.3;
}
.heading {
    font-size: 11px;
    font-weight: 500;
    color: #bdbec5;
}
.heading_value {
    font-size: 12px;
    font-weight: 500;
    color: #3b4856;
}
.uploaded_by_row {
    line-height: 1.3;
    margin-top: 8px;
}
.doc_status {
    font-size: 9px;
    background-color: #ccd5f7;
    height: fit-content;
    padding: 4px 20px;
    border-radius: 20px;
    font-weight: 500;
    color: #11256d;
}

.tags_row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
}
.tags_heading{
    font-size: 12px;
    font-weight: 500;
    color: #3B4856;
}
.tag {
    border: 1px solid;
    padding: 4px 12px;
    border-radius: 6px;
    margin: 6px 12px 6px 12px;
    width: fit-content;
    font-size: 13px;
    display: inline-block;
    font-size: 12px;
    color: #3b4856;
    opacity: 0.6;
    cursor: pointer;
}

.tag_search {
    text-align: right;
}
.tag_search input {
    overflow: visible;
    border-radius: 20px;
    width: 80%;
    background-color: #f5f7fd;
    border: none;
    outline: none;
    font-size: 12px;
    padding: 4px 12px;
}
.tag_container{
    margin-left: -12px;
}
.doc_info_container hr{
    margin-left: -20px;
    margin-right: -20px;
    margin-top: 12px !important;
    margin-bottom: 12px !important;
}