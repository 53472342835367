.clustered_paragraphs_details_container {
  height: 80vh;
  background-color: #ffffff;
  /* overflow: auto; */
  overflow: overlay;
}

.response_section_container {
  height: 53vh;
  background-color: #ffffff;
  /* overflow: auto; */
  margin-left: 900px;
  overflow: overlay;
}
.bottom_container {
  background: #ffffff;
}
.bottom_container_section {
  margin: 5px 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 11vh;
  background: #ffffff;
}

.response_section {
  margin: 5px 0px;
  display: flex;
  height: 37vh;
  background: #ffffff;
}

.bottom_container .bottom_container_section button {
  width: 360px;
}
.btn_gap {
  margin-right: 8px;
}

.response_body {
  font-family: "Roboto", sans-serif;
  padding: 15px;
  width: 100%;
  height: 185px;
  color: #144192;
  opacity: 1;
  border: 1px solid #3b4856 !;
}

.cross_btn {
  text-align: right;
  font-size: 20px;
  width: 99%;
}
.cross_btn:hover {
  cursor: pointer;
}

.response_save {
  width: 250px !important;
}

.response_clear {
  width: 250px !important;
  background-color: #ffffff !important;
  color: #144192 !important;
  border: 1px solid #144192;
}
.response_clear:hover {
  background-color: #ffffff !important;
  color: #144192 !important;
  border: 1px solid #144192;
}

.feedback_container i {
  margin-top: 5px;
  margin-right: 12px;
  cursor: pointer;
  color: #3b4856 !important;

}
.feedback_container span {
  font-size: 12px;
  color: #3b4856 !important;
}