.data_instance_root {
  margin-bottom: 24px;
}
.heading {
  display: inline-block;
  font-weight: 500;
  font-size: 24px;
  color: #2c2c2c;
  margin: 0 10px 20px 0;
}
.tabs_container {
  margin-bottom: 15px;
}

.tab_title_root {
  display: flex;
  align-items: center;
  width: 187px;
  height: 50px;
  justify-content: space-around;
  padding: 10px;
  border-radius: 4px;
  color: inherit;
}

.text_container {
  margin-right: 10px;
}
.tab_title {
  font-weight: 400;
  font-size: 15px;
}

.tab_subtitle {
  font-weight: 400;
  font-size: 9px;
}

.filters {
  display: flex;
  justify-content: space-between;
  margin: 12px 0;
}

.set_1,
.set_2 {
  display: flex;
}
.set_2 {
  justify-content: flex-end;
}

.dropdown {
  display: flex;
  margin-right: 15px;
}

.no_result_text {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 300px;
}
